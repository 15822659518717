import React, { Component } from 'react';
import { RouteComponentProps } from '@reach/router';
import CompanyRegistrationForm from '../../components/company/CompanyRegistrationForm';
import Loading from '../../common/loading';
import Posted from '../../components/company/CompanyPostedMessage';
import setRequestUrl from '../../common/utilits/setRequestUrl';
import setRequestParams from '../../common/utilits/setRequestParams';
import sendRequest from '../../common/utilits/sendRequest';

interface IComponentProps extends RouteComponentProps {
  token: string
}

interface IComponentState {
  isPosted: boolean,
  isLoading: boolean,
  isError: boolean,
  errMsg: string
}

class CompanyRegistrationPage extends Component<IComponentProps, IComponentState> {

  readonly state: IComponentState = {
    isPosted: false,
    isLoading: false,
    isError: false,
    errMsg: ''
  }

  async createCompany(composedData:object) {
    const { token } = this.props;

    const requestURL = setRequestUrl('wp-json/api/v1/company/', 'create');
    const requestParams = setRequestParams('POST', token, composedData);
    
    this.setState({ isLoading: true });
    
    const content = await sendRequest(requestURL, requestParams);
    if (content.errMsg) {
      this.setState({
        errMsg: content.errMsg,
        isError: true,
        isPosted: false,
        isLoading: false
      })
    } else {
      this.setState({
        isPosted: true,
        isLoading: false
      })
    }
  }

  render() {
    const { isLoading, isPosted, isError, errMsg } = this.state;

    if(isLoading)
      return (<Loading isActive={isLoading} />)

    if(isPosted)
      return (<Posted />)

    if(isError)
      return (<div>{errMsg}</div>)

    return (
      <CompanyRegistrationForm createCompany={this.createCompany.bind(this)}/>
    )
  }
}

export default CompanyRegistrationPage;
