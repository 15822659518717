import React from 'react';
import Modal from 'react-modal';

interface IComponentProps {
  isActive: boolean
  textLoading?: string
}

const Loading: React.FC<IComponentProps> = ({ isActive, textLoading }) => (
  <div>
    <Modal
      isOpen={isActive}
      closeTimeoutMS={0}
      ariaHideApp={true}
      overlayClassName="ReactModal__Overlay--loading"
      className="ReactModal__Content--loading"
    >
      <button type="button" className="loading-spinner" disabled></button>
    </Modal>
    <div className='loading-text'>{textLoading}</div>
  </div>
);

export default Loading;
