import React, { Component } from 'react';
import { RouteComponentProps } from '@reach/router';
import CompanyListItem from '../components/company/CompanyListItem';
import Loading from '../common/loading';
import NoResult from '../components/products/utils/NoResult';
import Pagintation from '../components/products/Pagination';
import { IStoreState, ICompanyItem } from '../common/types';

interface IComponentProps extends RouteComponentProps {
  isLoaded: boolean,
  companiesList: Array<ICompanyItem>,
  totalPages: number,
  page: string,
  user: IStoreState['user'],
  setParentState: any,
  getPage:(pageNumber:number) => void,
  getPosts: (filterParams:any) => any,
  filterParams?: Array<string>,
}

class CompaniesList extends Component <IComponentProps> {

  componentDidMount() {
    const { getPage, page } = this.props;
    const pageId = parseInt(page, 10);
    getPage(pageId);
  }

  componentWillReceiveProps(nextProps:IComponentProps) {
    if (nextProps.page !== this.props.page) {
      const { getPage, page } = nextProps;
      const pageId = parseInt(page, 10);
      getPage(pageId);
    }
  }

  render() {
    const { companiesList, page, totalPages, isLoaded } = this.props;
    const pageId = parseInt(page, 10);
    const companies = companiesList ? companiesList.map(company => (
      <CompanyListItem
        key={company.id}
        company={company}
      />
    )) : <NoResult location={this.props.location} />;

    if (!isLoaded)
      return (<Loading isActive={!isLoaded} />);

    return (
      <div className="products-wrapper column">
        <div className="products-wrapper">
          <div className="product-list">
            {/* {companiesList ?
              <Fragment>
                 <div className="sort-panel company">

                 </div>
              </Fragment>
              : null
            } */}
            {companies}
            {companiesList
              ? <Pagintation pageId={pageId} totalPages={totalPages}/>
              : null
            }
          </div>
        </div>
      </div>
    )
  }
};

export default CompaniesList;
