export const defaultTranslate = "Произошла ошибка на сервере";

export interface IServerErrorTranslation {
  error: string;
  translate: ((par: IServerError) => string) | string;
}

export interface IServerError extends Record<string, unknown> {
  error: string;
}

export function parseServerError(
  serverError: IServerError,
  translateErrorList: IServerErrorTranslation[]
): string {
  const translateError = translateErrorList.find(
    (err) => err.error === serverError.error
  );

  if (translateError) {
    return typeof translateError.translate === "string"
      ? translateError.translate
      : translateError.translate(serverError);
  }

  return defaultTranslate;
}

export function translateServerError(
  result: Promise<any>,
  translations: IServerErrorTranslation[]
): Promise<string | undefined> {
  return result
    .then((content) =>
      content.error ? parseServerError(content, translations) : undefined
    )
    .catch((content) => parseServerError(content, translations));
}
