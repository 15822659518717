import React from 'react';
import closeButtonIcon from 'assets/icons/close-icon.png';

interface IComponentProps {
  imageItem: {
    src: string,
    main: boolean
  };
  id: number;
  setMainImage: (event: React.MouseEvent<HTMLDivElement>) => void,
  deleteImage: (event: React.MouseEvent<HTMLDivElement>) => void
}

const ImageItem: React.FC<IComponentProps> = ({ id, imageItem, setMainImage, deleteImage }) => (
  <div
    className="item img-item"
    id={`${id}`}
    main-img={imageItem.main}
  >
    <img id={`${id}`} src={closeButtonIcon} alt="remove" onClick={deleteImage} className="img-remove-btn" />
    <img id={`${id}`} src={imageItem.src} alt="product" onClick={setMainImage} />
  </div>
);

export default ImageItem;
