import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateUser, getUser } from '../../redux/actions/actions';
import { RouteComponentProps, Router, Redirect } from '@reach/router';
import { Helmet } from 'react-helmet';
import NotFound from '../../common/NotFound';
import NavigationMenu from '../../components/dashboard/profile/NavigationMenu';
import Ads from './Ads';
import Profile from './Profile';
import Vacancies from './Vacancies';
import CreateAdvert from './CreateAdvert';
import CreateVacancy from './CreateVacancy';
import ChangePasswordForm from '../../components/dashboard/profile/ChangePasswordForm';
import ChangeEmailForm from '../../components/dashboard/profile/ChangeEmailForm';
import ChangePhoneNumberForm from '../../components/dashboard/profile/ChangePhoneNumberForm';
import CompanyMainPage from '../company/CompanyMainPage';
import { IStoreState, IUserDetails, IUser } from '../../common/types';
import '../../assets/styles/dashboard/dashboard.scss';

interface IComponentProps extends RouteComponentProps {
  user: IStoreState['user'],
  updateUser: (userDetails: IUserDetails) => any
  getUser: (user: IUser) => any
}

class Dashboard extends Component<IComponentProps, {}> {
  static checkActiveCategory(pathname: string) {
    if (pathname.includes('/ads/1')) return 'Мои объявления';
    if (pathname.includes('/company-registration')) return 'Регистрация компании';
    if (pathname.includes('/company-edit')) return 'Редактирование данных компании';
    if (pathname.includes('/create-advert')) return '';
    if (pathname.includes('/create-vacancy')) return 'Размещение вакансии';
    return 'Личный кабинет';
  }

  static checkIsNavMenuVisible(pathname: string) {
    if (
      pathname.includes('dashboard/ads/company-posts')
      || pathname.includes('dashboard/ads/self')
    ) return pathname.split('/').length < 6;
    return pathname !== '/dashboard/create-advert'
      && pathname !== '/dashboard/create-vacancy'
      && pathname !== '/dashboard/company/company-registration'
      && pathname !== '/dashboard/company/company-edit'
      && pathname.split('/').length < 5;
  }

  updateUser(user: IStoreState['user']) {
    const { getUser } = this.props;
    getUser(user);
  };

  render() {
    const { user, updateUser } = this.props;
    const hasCompany = !!user.company;
    const pathname = this.props.location ? this.props.location.pathname : '';
    const title = Dashboard.checkActiveCategory(pathname);
    const isNavigationMenuVisible = Dashboard.checkIsNavMenuVisible(pathname);

    return (
      <div className="dashboard-wrapper">
        <Helmet>
          <title>Ударник | Личный кабинет</title>
        </Helmet>
        {pathname.split('/').length < 5 ?
          <h2>{title}</h2>
          : null}
        <div className="dashboard-container">
          {isNavigationMenuVisible ? <NavigationMenu /> : null}
          <Router className="dashboard-items" primary={false} >
            <Profile path="/" user={user} updateUser={updateUser} />
            <Ads path="ads/*" user={user} />
            {user.company ? <Vacancies path="company-vacancies/*" user={user} /> : null}
            {user.company ? <CreateVacancy path="create-vacancy" token={user.token} /> : null}
            <CompanyMainPage path="company/*" user={user} getUser={this.updateUser.bind(this)} />
            <CreateAdvert path="create-advert" user={user} />
            <ChangePasswordForm path="change-pass" token={user.token} email={user.email} id={user.id} />
            <ChangeEmailForm path="change-email" />
            <ChangePhoneNumberForm key="change-phone" path="change-phone-number" user={user} useCurrentNumber={false} getUser={this.updateUser.bind(this)} />
            <ChangePhoneNumberForm key="validate-phone" path="validate-phone-number" user={user} useCurrentNumber={true} getUser={this.updateUser.bind(this)} />
            <Redirect from='ads' to={hasCompany ? '/dashboard/ads/self/1' : '/dashboard/ads/1'} noThrow />
            {hasCompany ? <Redirect from='ads/1' to='/dashboard/ads/self/1' noThrow /> : null}
            <NotFound default />
          </Router>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: IStoreState) => ({
  user: state.user,
});

const mapDispatchToProps = {
  updateUser,
  getUser
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
