import React, { Component } from 'react';
import { RouteComponentProps } from '@reach/router';
import VacancyItem from '../components/vacancies/VacancyItem';
import VacanciesSort from '../components/vacancies/VacanciesSort';
import Loading from '../common/loading';
import NoResult from '../components/products/utils/NoResult';
import Pagintation from '../components/products/Pagination';
import setRequestParams from '../common/utilits/setRequestParams';
import { IStoreState, IVacancyItem } from '../common/types';

interface IComponentProps extends RouteComponentProps {
  isLoaded: boolean,
  vacanciesList: Array<IVacancyItem>,
  totalPages: number,
  page: string,
  user: IStoreState['user'],
  setParentState: any,
  getPage:(pageNumber:number) => void,
  getPosts: (filterParams:any) => any,
  filterParams?: Array<string>,
  apiURL?: string
}

interface IComponentState {
  sortedBy: 'date' | 'price',
  order: 'DESC' | 'ASC'
}

class VacanciesList extends Component <IComponentProps> {

  readonly state: IComponentState = {
    sortedBy: 'date',
    order: 'DESC'
  }

  static checkVisibilityForLocation(location: any):boolean {
    if (location && location.pathname.includes("dashboard/ads")) return false;
    return true
  }

  sendRequest(requestURL:string, requestParams:object):void {
    const { setParentState } = this.props;
    fetch(requestURL, requestParams)
      .then(response => response.json())
      .then(content => setParentState({
        vacanciesList: content.data,
        page: content.page,
        perPage: content.perPage,
        totalPages: content.totalPages,
        requestParams,
        isLoaded: true
      }))
      .catch(err => console.log(err));
  }

  setSortParams(sortedBy: IComponentState['sortedBy'], order: IComponentState['order']):string {
    const { apiURL, filterParams } = this.props;
    if (apiURL && filterParams) {
      const concatedFilterParams = filterParams.reduce((acc, value) => acc.concat(value), `&page=1&orderby=${sortedBy}&order=${order}`);
      const requestURL = apiURL.concat(concatedFilterParams)
      return requestURL;
    } 
    else return `${process.env.REACT_APP_API_URL}wp-json/api/v1/vacancy/list?size=10&page=1`
  }

  sortVacancies(sortedBy: IComponentState['sortedBy'], order: IComponentState['order']) {
    const { setParentState, apiURL, filterParams, user } = this.props;
    this.setState({ sortedBy, order });
    const requestURL = this.setSortParams(sortedBy, order); 
    const requestParams = apiURL && filterParams ? setRequestParams('GET') : setRequestParams('GET', user.token);
    setParentState({ isLoaded: false, sortedBy, order });
    this.sendRequest(requestURL, requestParams);
  }

  componentDidMount() {
    const { getPage, page } = this.props;
    const pageId = parseInt(page, 10);
    getPage(pageId);
  }

  componentWillReceiveProps(nextProps:IComponentProps) {
    if (nextProps.page !== this.props.page) {
      const { getPage, page } = nextProps;
      const pageId = parseInt(page, 10);
      getPage(pageId);
    }
  }

  render() {
    const { vacanciesList, page, totalPages, isLoaded } = this.props;
    const pageId = parseInt(page, 10);
    const vacancies = vacanciesList ? vacanciesList.map(vacancy => (
      <VacancyItem
        key={vacancy.id}
        vacancy={vacancy}
        deleteVacancy={() => {}}
        draftVacancy={() => {}}
        publishVacancy={() => {}}
      />
    )) : <NoResult location={this.props.location} />;

    if(!isLoaded)
      return (<Loading isActive={!isLoaded} />)

    return (
      <div className="products-wrapper column">
        <div className="products-wrapper">
          <div className="product-list">
            {vacanciesList
              ? <VacanciesSort
                  setSortParams={this.sortVacancies.bind(this)}
                  sortedBy={this.state.sortedBy}
                  order={this.state.order}
                />
              : null
            }
            {vacancies}
            {vacanciesList
              ? <Pagintation pageId={pageId} totalPages={totalPages}/>
              : null
            }
          </div>
        </div>
      </div>
    )
  }
};

export default VacanciesList;
