import React from 'react';
import noPhoto from '../../../assets/img/nophoto.jpg';
import ImageGallery from 'react-image-gallery';

interface IComponentProps {
  full: string,
  short: string,
  exchange: boolean,
  images: any[],
  category: [string],
  vendor: string,
  model: string,
  proposed_vendor: string,
  proposed_model: string,
  sn: string,
  year: string,
  hours: string,
  condition: string
}

const LeftInfoColumn: React.FC<IComponentProps> = ({
  full, category, vendor, model, sn, year, hours, condition, images, exchange, short,
  proposed_model, proposed_vendor
}) => {
  const sliderImages = images.map((item) => ({
    original: item.url,
    thumbnail: item.url,
    originalAlt: item.id,
    thumbnailAlt: item.id
  }));

  const noPhotoFallback = [{
    original: noPhoto,
    thumbnail: noPhoto,
  }];

  const contentToHtml = (text: string) => text.split(/\n(?!\n)/)
    .filter(item => item !== "")
    .map((paragraph: string) => <p key={paragraph}><span>{paragraph}</span></p>);

  const [type] = category ? category : [''];
  return (
    <div className="left-column">
      <div className="slider-wrapper">
        <ImageGallery
          items={sliderImages.length === 0 ? noPhotoFallback : sliderImages}
          thumbnailPosition={'right'}
          showPlayButton={false}
          defaultImage={noPhoto}
          slideDuration={350}
        />
      </div>
      <div className="state">
        <h3>Общие параметры</h3>
        <div className="blocks">
          <ul className="block">
            <li className="block-item">
              <p className="type">Тип</p>
              <p className="value">{type}</p>
            </li>
            <li className="block-item">
              <p className="type">Производитель</p>
              { proposed_vendor ? <p className="value">{vendor} ({proposed_vendor})</p> : <p className="value">{vendor}</p>}
            </li>
            <li className="block-item">
              <p className="type">Модель</p>
              { proposed_model ? <p className="value">{model} ({proposed_model})</p> : <p className="value">{model}</p>}
            </li>
            {/* <li className="block-item">
              <p className="type">Страна производства</p>
              <p className="value"></p>
            </li> */}
          </ul>
          <ul className="block">
            <li className="block-item">
              <p className="type">Серийный номер</p>
              <p className="value">{sn.length !== 0 ? sn : 'ASD323V'}</p>
            </li>
            <li className="block-item">
              <p className="type">Год выпуска</p>
              <p className="value">{year}</p>
            </li>
            <li className="block-item">
              <p className="type">Моточасы</p>
              <p className="value">{hours.length !== 0 ? hours : 0}</p>
            </li>
            <li className="block-item">
              <p className="type">Общее состояние</p>
              <p className="value">{condition} / 5</p>
            </li>
          </ul>
        </div>
      </div>
      <div className="description" itemProp="description">
        <h3>Описание</h3>
        {contentToHtml(full)}
        {exchange ?
          <p className="description-exchange">
            Варианты обмена: {short}
          </p>
          : null}
      </div>
      {/* <div className="condition">
        <div className="block-wrapper left">
          <h3>Шины</h3>
          <div className="blocks">
            <ul className="block">
              <li className="block-item">
                <p className="type">Количество колес</p>
                <p className="value">4 передних + 4 задних</p>
              </li>
              <li className="block-item">
                <p className="type">Передние покрышки</p>
                <p className="value">710*26,5</p>
              </li>
              <li className="block-item">
                <p className="type">Задние покрышки</p>
                <p className="value">710*26,5</p>
              </li>
              <li className="block-item">
                <p className="type">Состояние</p>
                <p className="value">90% 90% 90% 90% 90% 90% 90% 90%</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="block-wrapper right">
          <h3>Комплектация</h3>
          <div className="blocks">
            <ul className="block">
              <li className="block-item">
                <p className="type">Харвестерная головка</p>
                <p className="value">F456</p>
              </li>
              <li className="block-item">
                <p className="type">Манипулятор</p>
                <p className="value">F456B</p>
              </li>
            </ul>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default LeftInfoColumn;
