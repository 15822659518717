import React from 'react';
import { Link } from '@reach/router';
import formatDate from '../../../common/utilits/formatDate';
import { IVacancyItem } from '../../../common/types';

interface IComponentProps {
  title: string,
  city: string,
  price: IVacancyItem['price'],
  id: number,
  companyId: number,
  companyName: string,
  published: string
}

const displayPrice = (price: IVacancyItem['price']) => {
  if (price.min && !price.max) return `От ${price.min.byn} руб.`;
  if (price.max && !price.min) return `До ${price.max.byn} руб.`;
  if (price.min && price.max) return `От ${price.min.byn} - до ${price.max.byn} руб.`;
}

const VacancyDetailHeader: React.FC<IComponentProps> = ({ title, city, id, published, companyId, companyName, price }) => {
  const formatedDate = formatDate(published);
  const salary = displayPrice(price);
  return (
    <div className="vacancy-detail-header">
      <h2 className="title">{title}</h2>
      <h2 className="salary">{salary}</h2>
      <div className="credentials">
        <div className="link-block">
          <p className="location-city">{city}</p>
          <Link to={`../../../../companies/1/${companyId}/company-posts/1`}>{companyName}</Link>
        </div>
        <div className="state">
          <p>Вакансия #{id}</p>
          <p>Опубликовано {formatedDate}</p> 
        </div>
      </div>
    </div>
  )
}

export default VacancyDetailHeader;
