import React from 'react';
import { Link } from '@reach/router';
import getConditionState from '../../../common/utilits/getConditionState';
import noAvatar from '../../../assets/img/avatar.png';
import tradeIndicator from '../../../assets/icons/Orange-ellipse.png'
import locationMarker from '../../../assets/icons/place-marker-icon.png';
import getPriceToDisplay from '../../../common/utilits/getPriceToDisplay';
import setCalculatedPrice from '../../../common/utilits/setCalculatedPrice';
import { TPreferredCurrency, IExchangeRates, TAuthor, ICompanyItem } from '../../../common/types';

interface IComponentProps {
  price: {
    byn: string,
    currency: string,
    value: string,
    include_tax: boolean,
    exchange: boolean,
    optional_price: boolean
  },
  preferredCurrency: TPreferredCurrency,
  exchangeRates?: IExchangeRates,
  year: string,
  location: {
    city: string,
    country: string
  },
  hours: string,
  condition: string,
  author: TAuthor & ICompanyItem,
  isNumberShown: boolean,
  contactNumber: string,
  isUserMatch: boolean,
  showContactNumber: () => void,
  deleteAction: () => void
}

const RightInfoColumn: React.FC<IComponentProps> = ({
  price, year, hours, location, author, condition, exchangeRates,
  isNumberShown, showContactNumber, contactNumber, preferredCurrency,
  isUserMatch, deleteAction
}) => {
  const {
    exchange, include_tax
  } = price;
  const {
    city, country
  } = location;
  const taxesText = include_tax ? 'Цена с учетом НДС' : 'Цена без учета НДС';
  const conditionState = getConditionState(condition);
  const calculatedPrice = exchangeRates
    ? setCalculatedPrice('BYN', price.byn, exchangeRates)
    : { byn: '0', usd: '0', eur: '0', rub: '0' };
  const priceToDisplay = exchangeRates
    ? getPriceToDisplay(preferredCurrency, calculatedPrice, price.optional_price)
    : 'Подсчет...';
  return (
    <div className="right-column">
      <div className="price-info" itemProp="offers" itemScope itemType="http://schema.org/Offer">
        <meta itemProp="priceCurrency" content={preferredCurrency || "BYN"} />
        <meta itemProp="price" content={priceToDisplay} />
        <div className="price">
          <p className="preferred">{priceToDisplay}</p>
          {price.optional_price || <div className="price__pop-up">
            <p style={{ display: preferredCurrency === 'BYN' ? 'none' : undefined }} >~ {getPriceToDisplay('BYN', calculatedPrice, price.optional_price)}</p>
            <p style={{ display: preferredCurrency === 'USD' ? 'none' : undefined }} >~ {getPriceToDisplay('USD', calculatedPrice, price.optional_price)}</p>
            <p style={{ display: preferredCurrency === 'EUR' ? 'none' : undefined }} >~ {getPriceToDisplay('EUR', calculatedPrice, price.optional_price)}</p>
            <p style={{ display: preferredCurrency === 'RUB' ? 'none' : undefined }} >~ {getPriceToDisplay('RUB', calculatedPrice, price.optional_price)}</p>
          </div>}
        </div>
        <p>{taxesText}</p>
        {exchange ?
          <div className="trade-block">
            <img src={tradeIndicator} alt="trade" />
            <p>Возможен обмен</p>
          </div>
          : null}
      </div>
      <div className="state-info">
        {conditionState ?
          <div className="state-item">
            <div className="left-item img">
              <img src={conditionState.image} alt="stars" />
            </div>
            <p className="right-item">{conditionState.text}</p>
          </div>
          : null}
        <div className="state-item">
          <p className="left-item">Год выпуска</p>
          <p className="right-item">{year}</p>
        </div>
        <div className="state-item">
          <p className="left-item">Моточасы</p>
          <p className="right-item">{hours.length !== 0 ? hours : 0}</p>
        </div>
        {country || city ?
          <div className="location">
            <img src={locationMarker} alt="place marker" />
            <p>{country ? `${country},` : null} {city}</p>
          </div>
          : null}
      </div>
      <div className="contacts">
        {author.hasOwnProperty('user_details') && author.hasOwnProperty('user_avatar')
          ? <UserIsOwnerBlock author={author} />
          : <CompanyIsOwnerBlock author={author} />
        }
        {isUserMatch
          ? <UserMatchesActionComponent deleteAction={deleteAction} />
          : <UserNotMatchesActionComponent
            isNumberShown={isNumberShown}
            contactNumber={contactNumber}
            showContactNumber={showContactNumber}
          />
        }
      </div>
    </div>
  );
}

interface ICompanyIsOwnerBlockProps {
  author: ICompanyItem
}

const CompanyIsOwnerBlock: React.FC<ICompanyIsOwnerBlockProps> = ({
  author: { company_logo, title, id }
}) => {
  return (
    <Link to={`../../../companies/1/${id}/company-posts/1`} className="owner company">
      <div className="avatar company">
        <img src={company_logo ? company_logo : noAvatar} alt="avatar" />
      </div>
      <p className="title">{title}</p>
    </Link>
  )
}

interface IUserIsOwnerBlockProps {
  author: TAuthor
}

const UserIsOwnerBlock: React.FC<IUserIsOwnerBlockProps> = ({
  author: { user_details, user_avatar }
}) => (
  <div className="owner">
    <div className="avatar">
      <img src={user_avatar !== '' && user_avatar ? user_avatar : noAvatar} alt="avatar" />
    </div>
    <p>{user_details.first_name} {user_details.last_name}</p>
  </div>
)

interface INotMatchesProps {
  isNumberShown: boolean,
  contactNumber: string,
  showContactNumber: () => void
}

const UserNotMatchesActionComponent: React.FC<INotMatchesProps> = ({
  isNumberShown, contactNumber, showContactNumber,
}): any => {
  if (isNumberShown)
    return [
      <div className="tel-link" key="tel-link">
        <a href={`tel:+${contactNumber}`}>
          {`+${contactNumber}`}
        </a>
      </div>
    ];
  else
    return [
      <button
        type="button"
        className="action-button"
        onClick={() => showContactNumber()}
        key="show-contact-number-button"
      >
        Позвонить
      </button>
    ];
};

interface IMatchesProps {
  deleteAction: () => void
}

const UserMatchesActionComponent: React.FC<IMatchesProps> = ({ deleteAction }): any => [
  <Link
    to="./edit"
    className="action-button link"
    key="edit-advert-button"
  >
    Редактировать
  </Link>
  ,
  <button
    type="button"
    className="action-button delete"
    onClick={deleteAction}
    key="delete-advert-button"
  >
    Удалить объявление
  </button>
];

export default RightInfoColumn;
