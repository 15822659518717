import React from 'react';

interface IComponentProps {
  errMsg: string,
  resetLogic: () => void
}

const EmptyFieldsMessage: React.FC<IComponentProps> = ({ errMsg, resetLogic }) => (
  <div className="emptyFields">
    <p style={{ color: 'red' }}>{errMsg}</p>
    <button
      type="button"
      onClick={resetLogic}
    >
      Исправить
    </button>
  </div>
);

export default EmptyFieldsMessage;
