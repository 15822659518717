import React, { Component } from 'react';
import { RouteComponentProps } from '@reach/router';
import { connect } from 'react-redux';
import VacancyItem from '../../vacancies/VacancyItem';
import Loading from '../../../common/loading';
import NoResult from '../../products/utils/NoResult';
import Pagintation from '../../products/Pagination';
import VacanciesSort from '../../vacancies/VacanciesSort';
import sendRequest from '../../../common/utilits/sendRequest';
import setRequestUrl from '../../../common/utilits/setRequestUrl';
import setRequestParams from '../../../common/utilits/setRequestParams';
import getExchangeRates from '../../../common/utilits/getExchangeRates';
import { updateUserPreferredCurrency } from '../../../redux/actions/actions';
import { IVacancyItem, IStoreState, TPreferredCurrency, IActionPreferredCurrency, IExchangeRates } from '../../../common/types';

interface IComponentProps extends RouteComponentProps {
  user: IStoreState['user'],
  vacancyId: string,
  companyId: string,
  preferredCurrency: TPreferredCurrency,
  updateUserPreferredCurrency: (preferredCurrency: TPreferredCurrency) => IActionPreferredCurrency
}

interface IComponentState {
  isLoaded: boolean,
  vacanciesList: Array<IVacancyItem>,
  totalPages: number,
  perPage: number,
  page: number,
  apiURL: string,
  sortedBy: 'date' | 'price',
  order: 'DESC' | 'ASC',
  displayPrice: TPreferredCurrency,
  exchangeRates?: IExchangeRates
}

class CompanyVacanciesList extends Component <IComponentProps, IComponentState> {
  readonly state: IComponentState = {
    vacanciesList: [],
    page: 1,
    perPage: 10,
    totalPages: 10,
    apiURL: `${process.env.REACT_APP_API_URL}wp-json/api/v1/vacancies/list?size=10`,
    isLoaded: false,
    sortedBy: 'date',
    order: 'DESC',
    displayPrice: 'BYN'
  }

  handleDisplayPriceChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const { updateUserPreferredCurrency } = this.props;
    const displayPrice:any = event.currentTarget.value;
    updateUserPreferredCurrency(displayPrice);
    this.setState({ displayPrice });
  }

  setSortParams(sortedBy: IComponentState['sortedBy'], order: IComponentState['order']):string {
    const { companyId } = this.props;
    return `${process.env.REACT_APP_API_URL}wp-json/api/v1/vacancy/list?size=10&page=1&company_id=${companyId}&orderby=${sortedBy}&order=${order}`
  }

  async sortVacancies(sortedBy: IComponentState['sortedBy'], order: IComponentState['order']) {
    const { user } = this.props;
    const requestURL = this.setSortParams(sortedBy, order); 
    const requestParams = user ? setRequestParams('GET', user.token) : setRequestParams('GET');
    this.setState({ isLoaded: false, sortedBy, order });

    const content = await sendRequest(requestURL, requestParams);
    this.setState({
      vacanciesList: content.data,
      page: content.page,
      perPage: content.perPage,
      totalPages: content.totalPages,
      isLoaded: true
    });
  }

  async getPosts(pageId:number = 1) {
    const { user, companyId } = this.props;
    const { perPage } = this.state;

    const requestURL = setRequestUrl('wp-json/api/v1/vacancy/','list',`size=${perPage}&page=${pageId}&company_id=${companyId}`);
    const requestParams = user ? setRequestParams('GET', user.token) : setRequestParams('GET');

    this.setState({ isLoaded: false });
    
    const content = await sendRequest(requestURL, requestParams);
    this.setState({
      vacanciesList: content.data,
      page: content.page,
      perPage: content.perPage,
      totalPages: content.totalPages,
      isLoaded: true
    })
  }

  getPage(pageNumber:number) {
    this.getPosts(pageNumber);
  }

  componentDidMount() {
    const { vacancyId, preferredCurrency } = this.props;
    this.setState({ displayPrice: preferredCurrency });
    const pageId = parseInt(vacancyId, 10);
    this.getPage(pageId);
    getExchangeRates().then((exchangeRates) => this.setState({ exchangeRates }));
  }

  componentWillReceiveProps(nextProps:IComponentProps) {
    if (nextProps.vacancyId !== this.props.vacancyId) {
      const { vacancyId } = nextProps;
      const pageId = parseInt(vacancyId, 10);
      this.getPage(pageId);
    }
    getExchangeRates().then((exchangeRates) => this.setState({ exchangeRates }));
  }

  async deleteVacancy(id: number) {
    const requestURL = setRequestUrl('wp-json/api/v1/vacancy/', 'delete', `id=${id}`);
    const requestParams = setRequestParams('DELETE', this.props.user.token, {});

    await sendRequest(requestURL, requestParams);

    this.getPosts(this.state.page);
  }

  async publishVacancy(id:number) {
    const requestURL = setRequestUrl('wp-json/api/v1/vacancy/', 'update', `id=${id}`);
    const requestParams = setRequestParams('PATCH', this.props.user.token, {status:'publish'});

    await sendRequest(requestURL, requestParams);

    this.getPosts(this.state.page);
  }

  async draftVacancy(id:number) {
    const requestURL = setRequestUrl('wp-json/api/v1/vacancy/', 'update', `id=${id}`);
    const requestParams = setRequestParams('PATCH', this.props.user.token, {status:'draft'});

    await sendRequest(requestURL, requestParams);

    this.getPosts(this.state.page);
  }

  render() {
    const { vacancyId, user } = this.props;
    const { vacanciesList, totalPages, isLoaded } = this.state;
    const pageId = parseInt(vacancyId, 10);
    const adverts = vacanciesList ? vacanciesList.map(vacancy => (
      <VacancyItem
        key={vacancy.id}
        vacancy={vacancy}
        fromCompany={true}
        user = {user}
        deleteVacancy={this.deleteVacancy.bind(this)}
        draftVacancy={this.draftVacancy.bind(this)}
        publishVacancy={this.publishVacancy.bind(this)}
      />
    )) : <NoResult location={this.props.location} />;

    if(!isLoaded)
      return (<Loading isActive={!isLoaded} />)

    return (
      <div className="products-wrapper column">
        <div className="products-wrapper">
          <div className="product-list">
            {vacanciesList
              ? <VacanciesSort
                  setSortParams={this.sortVacancies.bind(this)}
                  sortedBy={this.state.sortedBy}
                  order={this.state.order}
                />
              : null
            }
            {adverts}
            {vacanciesList
              ? <Pagintation pageId={pageId} totalPages={totalPages}/>
              : null
            }
          </div>
        </div>
      </div>
    )
  }
};

const mapStateToProps = (state:IStoreState) => ({
  preferredCurrency: state.preferredCurrency,
  user: state.user
});

const mapDispatchToProps = {
  updateUserPreferredCurrency
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyVacanciesList);
