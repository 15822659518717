import React, { Component } from 'react';
import { RouteComponentProps } from '@reach/router';
import { connect } from 'react-redux';
import ProductItem from '../../products/ProductItem';
import Loading from '../../../common/loading';
import NoResult from '../../products/utils/NoResult';
import Pagintation from '../../products/Pagination';
import ProductsSort from '../../products/ProductsSort';
import sendRequest from '../../../common/utilits/sendRequest';
import setRequestUrl from '../../../common/utilits/setRequestUrl';
import setRequestParams from '../../../common/utilits/setRequestParams';
import getExchangeRates from '../../../common/utilits/getExchangeRates';
import { updateUserPreferredCurrency } from '../../../redux/actions/actions';
import { IAdvertItem, IStoreState, TPreferredCurrency, IActionPreferredCurrency, IExchangeRates } from '../../../common/types';

interface IComponentProps extends RouteComponentProps {
  user: IStoreState['user'],
  productId: string,
  companyId: string,
  preferredCurrency: TPreferredCurrency,
  updateUserPreferredCurrency: (preferredCurrency: TPreferredCurrency) => IActionPreferredCurrency
}

interface IComponentState {
  isLoaded: boolean,
  advertList: Array<IAdvertItem>,
  totalPages: number,
  perPage: number,
  page: number,
  apiURL: string,
  sortedBy: 'date' | 'hours' | 'year' | 'price',
  order: 'DESC' | 'ASC',
  displayPrice: TPreferredCurrency,
  exchangeRates?: IExchangeRates
}

class CompanyProductsList extends Component <IComponentProps, IComponentState> {
  readonly state: IComponentState = {
    advertList: [],
    page: 1,
    perPage: 10,
    totalPages: 10,
    apiURL: `${process.env.REACT_APP_API_URL}wp-json/api/v1/post/list?size=10`,
    isLoaded: false,
    sortedBy: 'date',
    order: 'DESC',
    displayPrice: 'BYN'
  }

  handleDisplayPriceChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const { updateUserPreferredCurrency } = this.props;
    const displayPrice:any = event.currentTarget.value;
    updateUserPreferredCurrency(displayPrice);
    this.setState({ displayPrice });
  }

  setSortParams(sortedBy: IComponentState['sortedBy'], order: IComponentState['order']):string {
    const { companyId } = this.props;
    return `${process.env.REACT_APP_API_URL}wp-json/api/v1/post/list?size=10&page=1&userId=${companyId}&orderby=${sortedBy}&order=${order}`
  }

  async sortAdverts(sortedBy: IComponentState['sortedBy'], order: IComponentState['order']) {
    const { user } = this.props;
    const requestURL = this.setSortParams(sortedBy, order); 
    const requestParams = user ? setRequestParams('GET', user.token) : setRequestParams('GET');
    this.setState({ isLoaded: false, sortedBy, order });

    const content = await sendRequest(requestURL, requestParams);
    this.setState({
      advertList: content.data,
      page: content.page,
      perPage: content.perPage,
      totalPages: content.totalPages,
      isLoaded: true
    });
  }

  async getPosts(pageId:number = 1) {
    const { user, companyId } = this.props;
    const { perPage, sortedBy, order } = this.state;

    const requestURL = setRequestUrl('wp-json/api/v1/post/','list',`size=${perPage}&page=${pageId}&userId=${companyId}&orderby=${sortedBy}&order=${order}`);
    const requestParams = user ? setRequestParams('GET', user.token) : setRequestParams('GET');

    this.setState({ isLoaded: false });
    
    const content = await sendRequest(requestURL, requestParams);
    this.setState({
      advertList: content.data,
      page: content.page,
      perPage: content.perPage,
      totalPages: content.totalPages,
      isLoaded: true
    })
  }

  getPage(pageNumber:number) {
    this.getPosts(pageNumber);
  }

  componentDidMount() {
    const { productId, preferredCurrency } = this.props;
    this.setState({ displayPrice: preferredCurrency });
    const pageId = parseInt(productId, 10);
    this.getPage(pageId);
    getExchangeRates().then((exchangeRates) => this.setState({ exchangeRates }));
  }

  componentWillReceiveProps(nextProps:IComponentProps) {
    if (nextProps.productId !== this.props.productId) {
      const { productId } = nextProps;
      const pageId = parseInt(productId, 10);
      this.getPage(pageId);
    }
    getExchangeRates().then((exchangeRates) => this.setState({ exchangeRates }));
  }

  async deleteAdvert(id: number) {
    const requestURL = setRequestUrl('wp-json/api/v1/post/', 'delete', `id=${id}`);
    const requestParams = setRequestParams('DELETE', this.props.user.token, {});

    await sendRequest(requestURL, requestParams);

    this.getPosts(this.state.page);
  }

  async publishAdvert(id:number) {
    const requestURL = setRequestUrl('wp-json/api/v1/post/', 'update', `id=${id}`);
    const requestParams = setRequestParams('PATCH', this.props.user.token, {status:'publish'});

    await sendRequest(requestURL, requestParams);

    this.getPosts(this.state.page);
  }

  async draftAdvert(id:number) {
    const requestURL = setRequestUrl('wp-json/api/v1/post/', 'update', `id=${id}`);
    const requestParams = setRequestParams('PATCH', this.props.user.token, {status:'draft'});

    await sendRequest(requestURL, requestParams);

    this.getPosts(this.state.page);
  }

  render() {
    const { productId } = this.props;
    const { advertList, totalPages, isLoaded } = this.state;
    const pageId = parseInt(productId, 10);
    const adverts = advertList ? advertList.map(advert => (
      <ProductItem
        key={advert.id}
        advert={advert}
        fromCompany={true}
        displayPrice={this.state.displayPrice}
        exchangeRates={this.state.exchangeRates}
        deleteAdvert={this.deleteAdvert.bind(this)}
        publishAdvert={this.publishAdvert.bind(this)}
        draftAdvert={this.draftAdvert.bind(this)}
      />
    )) : <NoResult location={this.props.location} />;

    if(!isLoaded)
      return (<Loading isActive={!isLoaded} />)

    return (
      <div className="products-wrapper column">
        <div className="products-wrapper">
          <div className="product-list">
            {advertList
              ? <ProductsSort
                  displayPrice={this.state.displayPrice}
                  setSortParams={this.sortAdverts.bind(this)}
                  handleDisplayPriceChange={this.handleDisplayPriceChange.bind(this)}
                  sortedBy={this.state.sortedBy}
                  order={this.state.order}
                />
              : null
            }
            {adverts}
            {advertList
              ? <Pagintation pageId={pageId} totalPages={totalPages}/>
              : null
            }
          </div>
        </div>
      </div>
    )
  }
};

const mapStateToProps = (state:IStoreState) => ({
  preferredCurrency: state.preferredCurrency,
  user: state.user
});

const mapDispatchToProps = {
  updateUserPreferredCurrency
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProductsList);
