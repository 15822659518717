import React from 'react'
import { IWorkDays } from '../../../common/types';

interface IComponentProps {
  workDays: IWorkDays
  setWorkDays: any
}

const WorkDaysCollection: React.FC<IComponentProps> = ({workDays, setWorkDays}) => (
  <div className="work-days-container column">
    <div className="work-days-container">
      <button
        type="button" 
        className={workDays.monday ? "work-day-button active" : "work-day-button"}
        onClick={() => setWorkDays({ ...workDays, monday: !workDays.monday})}
      >
        Пн
      </button>
      <button
        type="button" 
        className={workDays.tuesday ? "work-day-button active" : "work-day-button"}
        onClick={() => setWorkDays({ ...workDays, tuesday: !workDays.tuesday})}
      >
        Вт
      </button>
      <button
        type="button" 
        className={workDays.wednesday ? "work-day-button active" : "work-day-button"}
        onClick={() => setWorkDays({ ...workDays, wednesday: !workDays.wednesday})}
      >
        Ср
      </button>
      <button
        type="button" 
        className={workDays.thursday ? "work-day-button active" : "work-day-button"}
        onClick={() => setWorkDays({ ...workDays, thursday: !workDays.thursday})}
      >
        Чт
      </button>
      <button
        type="button" 
        className={workDays.friday ? "work-day-button active" : "work-day-button"}
        onClick={() => setWorkDays({ ...workDays, friday: !workDays.friday})}
      >
        Пт
      </button>
      <button
        type="button" 
        className={workDays.saturday ? "work-day-button active" : "work-day-button"}
        onClick={() => setWorkDays({ ...workDays, saturday: !workDays.saturday})}
      >
        Сб
      </button>
      <button
        type="button" 
        className={workDays.sunday ? "work-day-button active" : "work-day-button"}
        onClick={() => setWorkDays({ ...workDays, sunday: !workDays.sunday})}
      >
        Вс
      </button>
    </div>
  </div>
);

export default WorkDaysCollection;
