import React from 'react';
import { Link } from '@reach/router';

interface IComponentProps {
  id?: string,
  pageNumber: number,
  isCurrent: boolean
}

const PaginationItem: React.FC<IComponentProps> = ({ pageNumber, isCurrent, id }) => (
  <Link
    to={`../${pageNumber}`}
    className="pagination-item"
    id={id ? id : ''}
    style={{ backgroundColor: isCurrent ? '#faab00' : '' }}
    onClick={() => {window.scrollTo({ top: 0 })}}
  >
    {pageNumber}
  </Link>
);

export default PaginationItem;
