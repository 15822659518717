import React, { useRef } from 'react';

interface IComponentProps {
  abortReset: () => void,
  toggle: (isActive: boolean) => void,
  isActive: boolean
}

const ResetInfoPopUp:React.FC<IComponentProps> = ({ abortReset, toggle, isActive }) => {
  const modalRef = useRef(null);
  const handleDisable = (event: React.MouseEvent<HTMLDivElement>) => {
    if (modalRef.current === event.target) toggle(!isActive);
  }
  return (
    <div className="modal login" onClick={handleDisable} ref={modalRef}>
      <div 
        className="pop-up reset"
      >
        <div className="content-wrapper">
          <h3>Ссылка на страницу восстановления пароля отправлена на Вашу почту</h3>
          <div className="buttons-container">
            <button type="button" onClick={abortReset} className="action-button"> Закрыть </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetInfoPopUp;
