import React, { useState } from 'react';
import Modal from 'react-modal';
import { RouteComponentProps, navigate } from '@reach/router';
import setRequestUrl from '../../../common/utilits/setRequestUrl';
import setRequestParams from '../../../common/utilits/setRequestParams';
import sendRequest from '../../../common/utilits/sendRequest';
import acceptImage from '../../../assets/img/accept-round.png';

interface IComponentProps extends RouteComponentProps {
    token: string,
    email: string,
    id: number
}

const ChangePasswordForm: React.FC<IComponentProps> = ({ id, token, email }) => {
    const [errMsg, setErrMsg] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatedNewPassword, setRepeatedNewPassword] = useState('');
    const [isPasswordSuccessfullyUpdated, setIsPasswordSuccessfullyUpdated] = useState(false);

    const isPasswordsMatch = newPassword === repeatedNewPassword;
    const isNewPasswordCorrect = newPassword.length > 4;

    const showErrMsg = () => {
        if (!isNewPasswordCorrect) setErrMsg('Ненадежный пароль!');
        if (!isPasswordsMatch) setErrMsg('Пароли не совпадают!');
    };

    const checkOldPassword = async () => {
        setErrMsg('');
        const payload = {
            login: email,
            password: oldPassword
        }
        const requestURL = setRequestUrl('wp-json/api/v1/user/', 'login');
        const requestParams = setRequestParams('POST', undefined, payload);
        const content = await sendRequest(requestURL, requestParams);

        if (content.errMsg) {
            setErrMsg('Ввёден неверный текущий пароль!');
        } else {
            updatePassword();
        }
    }

    const updatePassword = async () => {
        if (isPasswordsMatch && isNewPasswordCorrect) {
            const payload = {
                password: newPassword,
            };
            const requestURL = setRequestUrl('wp-json/api/v1/user/', 'update', `id=${id}`);
            const requestParams = setRequestParams('POST', token, payload);
            await sendRequest(requestURL, requestParams);
            setIsPasswordSuccessfullyUpdated(true);
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setErrMsg('');
        const value: string = event.currentTarget.value;
        const fieldName: string = event.currentTarget.name;
        if (fieldName === 'oldPassword') {
            setOldPassword(value);
            return;
        }
        if (fieldName === 'newPassword') {
            setNewPassword(value);
            return;
        }
        else setRepeatedNewPassword(value);
    }

    return (
        <form className="change-user-data-container">
            <h3>Изменение пароля</h3>

            <div className="data-block baseline">
                <label htmlFor="oldPassword">Текущий пароль</label>
                <input
                    className="person-input"
                    type="password"
                    value={oldPassword}
                    name="oldPassword"
                    onChange={handleChange}
                />

            </div>

            <div className="data-block baseline">
                <label htmlFor="newPassword">Новый пароль</label>
                <input
                    className={isNewPasswordCorrect || newPassword === '' ? "person-input" : "person-input invalid"}
                    type="password"
                    value={newPassword}
                    name="newPassword"
                    onChange={handleChange}
                />
                {isNewPasswordCorrect || newPassword === '' ? null : <p className="form-invalid-indicator">Пароль ненадежный!</p>}
            </div>

            <div className="data-block baseline">
                <label htmlFor="repeatedNewPassword">Повторите новый пароль</label>
                <input
                    className={isPasswordsMatch || repeatedNewPassword === '' ? "person-input" : "person-input invalid"}
                    type="password"
                    value={repeatedNewPassword}
                    name="repeatedNewPassword"
                    onChange={handleChange}
                />
                {isPasswordsMatch || repeatedNewPassword === '' ? null : <p className="form-invalid-indicator">Пароли не совпадают!</p>}
            </div>

            <p style={{ color: 'red', margin: '0.5rem 0' }}>{errMsg}</p>

            <div className="button-container">
                <button
                    type="button"
                    className="action-button profile"
                    onClick={isPasswordsMatch && isNewPasswordCorrect ? checkOldPassword : showErrMsg}
                >
                    Изменить
        </button>
            </div>
            <Modal
                onAfterOpen={() => setTimeout(() => { setIsPasswordSuccessfullyUpdated(false); navigate('../dashboard') }, 2000)}
                isOpen={isPasswordSuccessfullyUpdated}
                overlayClassName="ReactModal__Overlay--notification"
                className="ReactModal__Content--notification"
            >
                <div className="content">
                    <p style={{ color: 'green', margin: '0.5rem 0' }}>Пароль успешно обновлён!</p>
                    <img src={acceptImage} alt="accepted" />
                </div>
            </Modal>
        </form>
    );
}

export default ChangePasswordForm;
