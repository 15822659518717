import React from 'react';
import noLogo from '../../../assets/img/nophoto-profile.png';
import WorkDaysBlock from '../workTime/WorkDaysDisplayBlock';
import { ICompanyItem } from '../../../common/types';

interface IComponentProps {
  unn: ICompanyItem['unn'],
  urName: ICompanyItem['urName'],
  urAddress: ICompanyItem['urAddress'],
  contacts: ICompanyItem['contacts'],
  location: ICompanyItem['location'],
  work_days: ICompanyItem['work_days'],
  company_logo: ICompanyItem['company_logo']
}

const CompanyDetailLeftColumn: React.FC<IComponentProps> = ({
  unn, urName, urAddress, contacts, location, work_days, company_logo
}) => {
  return (
    <div className="company-detail-left-column">
      <div className="logo-container">
        <img src={company_logo ? company_logo : noLogo} alt="logo" />
      </div>
      <h3>{urName}</h3>
      <p className="contacts">{location.country}, г.{location.city}, {location.address}</p>
      {contacts.site 
        ? <a href={`http://${contacts.site}`} rel="noopener noreferrer" target="_blank" className="contacts site">{contacts.site}</a> 
        : null}
      <div className="contacts">
        {contacts.email ? <a href={`mailto:${contacts.email}`}>{contacts.email}</a> : null}
        <a href={`tel:${contacts.phone}`}>{contacts.phone}</a>
      </div>
      <WorkDaysBlock work_days={work_days} />
      <div className="credentials">
        <p>{urName}</p>
        <p>УНП: {unn}</p>
        <p>{urAddress}</p>
      </div>
    </div>
  )
}

export default CompanyDetailLeftColumn;
