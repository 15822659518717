import React, { useState, useRef } from 'react';
import WorkTimeForm from './workTime/WorkTimeForm';
import { IWorkDays, IWorkTime } from '../../common/types';
import noLogo from '../../assets/img/nophoto-profile.png';
import { ICompanyItem } from '../../common/types';

interface IComponentProps {
  payload?: ICompanyItem,
  createCompany: (composedData: object) => void
}

const CompanyRegistrationForm: React.FC<IComponentProps> = ({ createCompany, payload }) => {
  const inputImageRef = useRef<HTMLInputElement>(null);

  const [validity, setValidity] = useState({
    isCompanyNameValid: true,
    isCompanyDescriptionValid: true,
    isCompanyCountryValid: true,
    isCompanyPhoneNumberValid: true,
    isCompanyUrNameValid: true,
    isCompanyUNPValid: true,
    isCompanyUrAddressValid: true
  });

  const [formState, setFormState] = useState({
    companyName: payload ? payload.title : '',
    companyDescription: payload ? payload.description : '',
    companyLocation: {
      country: payload ? payload.location.country : '',
      city: payload ? payload.location.city : '',
      address: payload ? payload.location.address : ''
    },
    companyPhoneNumber: payload ? payload.contacts.phone : '',
    companyEmail: payload ? payload.contacts.email : '',
    companyWebSite: payload ? payload.contacts.site : '',
    companyUrName: payload ? payload.urName : '',
    companyUNP: payload ? payload.unn : '',
    companyUrAddress: payload ? payload.urAddress: ''
  });

  const [workDays, setWorkDays] = useState<IWorkDays>({
    monday: payload ? !!payload.work_days.monday : true,
    tuesday: payload ? !!payload.work_days.tuesday : true,
    wednesday: payload ? !!payload.work_days.wednesday : true,
    thursday: payload ? !!payload.work_days.thursday : true,
    friday: payload ? !!payload.work_days.friday : true,
    saturday: payload ? !!payload.work_days.saturday : false,
    sunday: payload ? !!payload.work_days.sunday : false
  })

  const [workTime, setWorkTime] = useState<IWorkTime>({
    monday: {
      from: payload ? payload.work_days.monday.split(' - ')[0] : '9:00',
      to: payload ? payload.work_days.monday.split(' - ')[1] : '18:00'
    },
    tuesday: {
      from: payload ? payload.work_days.tuesday.split(' - ')[0] : '9:00',
      to: payload ? payload.work_days.tuesday.split(' - ')[1] : '18:00'
    },
    wednesday: {
      from: payload ? payload.work_days.wednesday.split(' - ')[0] : '9:00',
      to: payload ? payload.work_days.wednesday.split(' - ')[1] : '18:00'
    },
    thursday: {
      from: payload ? payload.work_days.thursday.split(' - ')[0] : '9:00',
      to: payload ? payload.work_days.thursday.split(' - ')[1] : '18:00'
    },
    friday: {
      from: payload ? payload.work_days.friday.split(' - ')[0] : '9:00',
      to: payload ? payload.work_days.friday.split(' - ')[1] : '18:00'
    },
    saturday: {
      from: payload ? payload.work_days.saturday.split(' - ')[0] : '9:00',
      to: payload ? payload.work_days.saturday.split(' - ')[1] : '18:00'
    },
    sunday: {
      from: payload ? payload.work_days.sunday.split(' - ')[0] : '9:00',
      to: payload ? payload.work_days.sunday.split(' - ')[1] : '18:00'
    }
  })

  const [companyLogo, setCompanyLogo]:any = useState(payload ? payload.company_logo : null);

  const checkValidity = () => {
    if (formState.companyName === '' || formState.companyDescription === '' || formState.companyPhoneNumber === '' 
      || formState.companyUrAddress === '' || formState.companyUrName === ''
      || formState.companyUNP === '' || formState.companyLocation.country === ''
    ) {
      setValidity({
        isCompanyNameValid: formState.companyName !== '',
        isCompanyDescriptionValid: formState.companyDescription !== '',
        isCompanyCountryValid: formState.companyLocation.country !== '',
        isCompanyPhoneNumberValid: formState.companyPhoneNumber !== '',
        isCompanyUrNameValid: formState.companyUrName !== '',
        isCompanyUNPValid: formState.companyUNP !== '',
        isCompanyUrAddressValid: formState.companyUrAddress !== ''
      });
      window.scrollTo(0 , 0);
    } else createCompany(composeData());
  }

  const composeData = () => ({
    title: formState.companyName,
    description: formState.companyDescription,
    location: formState.companyLocation,
    contacts: {
      phone: formState.companyPhoneNumber,
      site: formState.companyWebSite,
      email: formState.companyEmail
    },
    urAddress: formState.companyUrAddress,
    unn: formState.companyUNP,
    urName: formState.companyUrName,
    work_days: {
      monday: workDays.monday ? `${workTime.monday.from} - ${workTime.monday.to}` : '',
      tuesday: workDays.tuesday ? `${workTime.tuesday.from} - ${workTime.tuesday.to}`: '',
      wednesday: workDays.wednesday ? `${workTime.wednesday.from} - ${workTime.wednesday.to}`: '',
      thursday: workDays.thursday ? `${workTime.thursday.from} - ${workTime.thursday.to}`: '',
      friday: workDays.friday ? `${workTime.friday.from} - ${workTime.friday.to}`: '',
      saturday: workDays.saturday ? `${workTime.saturday.from} - ${workTime.saturday.to}`: '',
      sunday: workDays.sunday ? `${workTime.sunday.from} - ${workTime.sunday.to}`: ''
    },
    company_logo: companyLogo
  })

  const handleImagesClick = () => {
    if (inputImageRef && inputImageRef.current) {
      inputImageRef.current.click();
    }
  }

  const handleImagesChange = () => {
    if (inputImageRef.current && inputImageRef.current.files) {
      const file = inputImageRef.current.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Encoded = reader.result;
        setCompanyLogo(base64Encoded);
      }
      if (file) reader.readAsDataURL(file);
    }
  }

  return (
    <form className="company-registration-container">
      <div className="payload-container">

        <div className="input-container label-top">
          <label htmlFor="companyLogo" className="logo-label">Логотип</label>
          <div className="logo-section">
            <div className="logo-container">
              <img src={companyLogo ? companyLogo : noLogo} alt="logo"/>
            </div>
            <div className="action-container logo-mobile">
              <div
                className="action-button profile"
                onClick={handleImagesClick}
                onChange={handleImagesChange}
              >
                <p>Загрузить логотип</p>
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  style={{ display: 'none' }}
                  ref={inputImageRef}
                />
              </div>
              <p>Фото должно быть в формате JPG или PNG и занимать не больше 10 МБ.</p>
            </div>
          </div>
        </div>

        <div className="input-container">
          <label htmlFor="companyName">Название*</label>
          <input
            name="companyName"
            type="text"
            className={validity.isCompanyNameValid ? "product-input" : "product-input invalid"}
            value={formState.companyName}
            onChange={(event) => setFormState({ ...formState, companyName: event.currentTarget.value })}
            onBlur={() => setValidity({...validity, isCompanyNameValid: formState.companyName !== ''})}
          />
          { validity.isCompanyNameValid ? 
            <p className="company-name-description">Это название, которое будут видеть пользователи. Юридическое название необходимо ввести ниже.</p> 
            : <p className="form-invalid-indicator">Необходимо указать название компании!</p> 
          }
        </div>

        <div className="input-container textarea">
          <label htmlFor="companyDescription">Описание</label>
          <textarea
            className={validity.isCompanyDescriptionValid ? "product-input" : "product-input invalid"}
            name="companyDescription"
            value={formState.companyDescription}
            onChange={(event) => setFormState({ ...formState, companyDescription: event.currentTarget.value })}
            onBlur={() => setValidity({...validity, isCompanyDescriptionValid: formState.companyDescription !== ''})}
            rows={10}
            cols={45}
          ></textarea>
          { validity.isCompanyDescriptionValid ? null : <p className="form-invalid-indicator">Необходимо описание!</p> }
        </div>

      </div>

      <div className="payload-container">
        <h2>Контактные данные</h2>

        <div className="input-container label-top condition">
          <label htmlFor="address">Адрес</label>
          <div className="address-container">
            <div className="address-form">
              <input
                type="text"
                className={validity.isCompanyCountryValid ? "product-input left" : "product-input invalid left"}
                value={formState.companyLocation.country}
                name="country"
                placeholder="Страна"
                onChange={(event) => setFormState({ ...formState, companyLocation:{ ...formState.companyLocation, country: event.currentTarget.value }})}
                onBlur={() => setValidity({...validity, isCompanyCountryValid: formState.companyLocation.country !== ''})}
              />
              <input
                className={validity.isCompanyCountryValid ? "product-input right" : "product-input invalid right"}
                name="city"
                type="text"
                placeholder="Город"
                value={formState.companyLocation.city}
                onChange={(event) => setFormState({ ...formState, companyLocation:{ ...formState.companyLocation, city: event.currentTarget.value }})}
              />
            </div>
            <input
              type="text"
              className={validity.isCompanyCountryValid ? "product-input bottom" : "product-input invalid bottom"}
              value={formState.companyLocation.address}
              name="address"
              placeholder="Улица, дом, копус и т.д."
              onChange={(event) => setFormState({ ...formState, companyLocation:{ ...formState.companyLocation, address: event.currentTarget.value }})}
            />
          </div>  
          { validity.isCompanyCountryValid ? null : <p className="form-invalid-indicator">Необходимо указать страну!</p> }
        </div>

        <div className="input-container mobile-condition">
          <label htmlFor="country">Страна</label>
          <input
            type="text"
            className={validity.isCompanyCountryValid ? "product-input" : "product-input invalid"}
            value={formState.companyLocation.country}
            name="country"
            onChange={(event) => setFormState({ ...formState, companyLocation:{ ...formState.companyLocation, country: event.currentTarget.value }})}
            onBlur={() => setValidity({...validity, isCompanyCountryValid: formState.companyLocation.country !== ''})}
          />
          { validity.isCompanyCountryValid ? null : <p className="form-invalid-indicator">Необходимо указать страну!</p> }
        </div>

        <div className="input-container mobile-condition">
          <label htmlFor="city">Город</label>
          <input
            className={validity.isCompanyCountryValid ? "product-input" : "product-input invalid"}
            name="city"
            type="text"
            value={formState.companyLocation.city}
            onChange={(event) => setFormState({ ...formState, companyLocation:{ ...formState.companyLocation, city: event.currentTarget.value }})}
          />
        </div>

        <div className="input-container mobile-condition">
          <label htmlFor="address">Адрес</label>
          <input
            type="text"
            className={validity.isCompanyCountryValid ? "product-input" : "product-input invalid"}
            value={formState.companyLocation.address}
            name="address"
            placeholder="Улица, дом, копус и т.д."
            onChange={(event) => setFormState({ ...formState, companyLocation:{ ...formState.companyLocation, address: event.currentTarget.value }})}
          />
        </div>

        <div className="input-container">
          <label htmlFor="companyPhoneNumber">Телефон</label>
          <input
            className={validity.isCompanyPhoneNumberValid ? "product-input" : "product-input invalid"}
            type="text"
            value={formState.companyPhoneNumber}
            onChange={(event) => setFormState({ ...formState, companyPhoneNumber: event.currentTarget.value })}
            onBlur={() => setValidity({...validity, isCompanyPhoneNumberValid: formState.companyPhoneNumber !== ''})}
            name="companyPhoneNumber"
          />
          { validity.isCompanyPhoneNumberValid ? null : <p className="form-invalid-indicator">Необходимо указать номер телефона!</p> }
        </div>

        <div className="input-container">
          <label htmlFor="companyWebSite">Сайт</label>
          <input
            type="text"
            className="product-input"
            value={formState.companyWebSite}
            name="companyWebSite"
            onChange={(event) => setFormState({ ...formState, companyWebSite: event.currentTarget.value })}
          />
        </div>

        <div className="input-container">
          <label htmlFor="companyEmail">E-mail</label>
          <input
            type="text"
            className="product-input"
            value={formState.companyEmail}
            name="companyEmail"
            onChange={(event) => setFormState({ ...formState, companyEmail: event.currentTarget.value })}
          />
        </div>
        
      </div>

      <div className="payload-container photos">
        <h2>Юридические данные</h2>

        <div className="input-container">
          <label htmlFor="companyUrName">Юридическое наименование</label>
          <input
            className={validity.isCompanyUrNameValid ? "product-input" : "product-input invalid"}
            type="text"
            value={formState.companyUrName}
            onChange={(event) => setFormState({ ...formState, companyUrName: event.currentTarget.value })}
            onBlur={() => setValidity({...validity, isCompanyUrNameValid: formState.companyUrName !== ''})}
            name="companyUrName"
          />
          { validity.isCompanyUrNameValid ? null : <p className="form-invalid-indicator">Необходимо указать юридическое наименование!</p> }
        </div>

        <div className="input-container">
          <label htmlFor="companyUNP">УНП</label>
          <input
            className={validity.isCompanyUNPValid ? "product-input" : "product-input invalid"}
            type="text"
            value={formState.companyUNP}
            onChange={(event) => setFormState({ ...formState, companyUNP: event.currentTarget.value })}
            onBlur={() => setValidity({...validity, isCompanyUNPValid: formState.companyUNP !== ''})}
            name="companyUNP"
          />
          { validity.isCompanyUNPValid ? null : <p className="form-invalid-indicator">Необходимо указать УНП!</p> }
        </div>

        <div className="input-container">
          <label htmlFor="companyUrAddress">Юридический адрес</label>
          <input
            className={validity.isCompanyUrAddressValid ? "product-input" : "product-input invalid"}
            type="text"
            value={formState.companyUrAddress}
            onChange={(event) => setFormState({ ...formState, companyUrAddress: event.currentTarget.value })}
            onBlur={() => setValidity({...validity, isCompanyUrAddressValid: formState.companyUrAddress !== ''})}
            name="companyUrAddress"
          />
          { validity.isCompanyUrAddressValid ? null : <p className="form-invalid-indicator">Необходимо указать юридический адрес!</p> }
        </div>
      </div>

      <WorkTimeForm 
        workDays={workDays}  
        setWorkDays={setWorkDays}
        workTime={workTime}
        setWorkTime={setWorkTime}
      />

      <div className="submit-container">
        <button
          type="button"
          className="action-button"
          onClick={checkValidity}
        >
          { payload ? 'Редактировать данные' : 'Зарегистрировать компанию' } 
        </button>
      </div>
    </form>
  );
}

export default CompanyRegistrationForm;
