import React, { Component } from 'react';
import LogInPopUpController from './pop-ups/LogInPopUpController';
import setRequestUrl from '../../../common/utilits/setRequestUrl';
import setRequestParams from '../../../common/utilits/setRequestParams';
import sendRequest from '../../../common/utilits/sendRequest';
import { ENTER_CHAR_CODE } from '../../../common/consts';
import { IUser } from '../../../common/types';

interface IComponentProps {
  isActive: boolean,
  isInTrigger?: boolean,
  toggle: (isActive:boolean) => void,
  switchActive: (isActive:boolean) => void,
  updateUser: (user:IUser) => any
}

interface IComponentState {
  validity: {
    isUserLoginValid: boolean,
    isUserPasswordValid: boolean,
    isUserEmailValid: boolean
  }
  userLogin: string,
  userPassword: string,
  userEmail: string,
  errMsg: string | undefined
}

class LogIn extends Component<IComponentProps, IComponentState> {

  constructor(props: Readonly<IComponentProps>) {
    super(props);
    this.state = {
      validity: {
        isUserEmailValid: false,
        isUserPasswordValid: false,
        isUserLoginValid: false
      },
      userLogin: '',
      userPassword: '',
      userEmail: '',
      errMsg: undefined
    }
    this.logIn = this.logIn.bind(this);
    this.handleKeyDownLogIn = this.handleKeyDownLogIn.bind(this);
  }

  handleClick: React.ReactEventHandler<HTMLDivElement> = (e) => {
    const { isActive, toggle } = this.props;
    toggle(!isActive);
  };

  handleKeyDownLogIn(event: any) {
    if (event.charCode === ENTER_CHAR_CODE) this.logIn();
  }

  handleUserLoginChange(event: React.ChangeEvent<HTMLInputElement>) {
    const userLogin: string = event.currentTarget.value;
    const mobileReg = /^[\d ()+]+$/;
    const emailReg = /^[A-Z0-9._%+-]+@[A-Z0-9-]+\.[A-Z]{2,4}$/i;
    const isValid = (mobileReg.test(userLogin) && userLogin.slice(1).indexOf("+") === -1 && userLogin.indexOf(" ") === -1) || emailReg.test(userLogin);
    this.setState({
      validity: { ...this.state.validity, isUserLoginValid: isValid },
      userLogin,
      errMsg: undefined
    });
  };

  handlePasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
    const userPassword: string = event.currentTarget.value;
    const isValid = userPassword.length > 4;
    this.setState({
      validity: { ...this.state.validity, isUserPasswordValid: isValid },
      userPassword,
      errMsg: undefined
    });
  };

  handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    const userEmail: string = event.currentTarget.value;
    const reg = /^[A-Z0-9._%+-]+@[A-Z0-9-]+\.[A-Z]{2,4}$/i;
    const isValid = reg.test(userEmail);
    event.currentTarget.style.borderColor = isValid ? '' : 'red';
    this.setState({
      validity: { ...this.state.validity, isUserEmailValid: isValid },
      userEmail,
      errMsg: undefined
    });
  };

  async resetPassword() {
    const payload = {
      email: `${this.state.userEmail}`
    }
    const { validity: { isUserEmailValid } } = this.state;

    if (!isUserEmailValid) {
      this.setState({
        errMsg: 'Неправильная почта'
      });
      return;
    }
    
    const requestURL = setRequestUrl('wp-json/api/v1/user/','forgot');
    const requestParams = setRequestParams('POST', undefined, payload);
    const content = await sendRequest(requestURL, requestParams);

    if (content === null) return;
    if (content.errMsg) {
      this.decodeErrorMessage(content.errMsg);
    } else {
      this.setState({
        userLogin: '',
        userPassword: '',
        userEmail: ''
      });
    }
  }

  async logIn() {
    const { updateUser } = this.props; 
    const { 
      validity: { isUserPasswordValid, isUserLoginValid },
      userLogin,
      userPassword
    } = this.state;

    const payload = {
      login: userLogin.slice(0, 1) === "+" ? userLogin.slice(1) : userLogin,
      password: userPassword
    }

    if(!isUserLoginValid) {
      this.setState({
        errMsg: 'Неправильно введен номер или E-mail'
      });
      return;
    }

    if(!isUserPasswordValid) {
      this.setState({
        errMsg: 'Неправильно введен пароль'
      });
      return;
    }

    const requestURL = setRequestUrl('wp-json/api/v1/user/','login');
    const requestParams = setRequestParams('POST', undefined, payload);
    const content = await sendRequest(requestURL, requestParams);

    if (content.errMsg) {
      this.decodeErrorMessage(content.errMsg);
    } else {
      this.setState({
        userLogin: '',
        userPassword: ''
      });
      updateUser(content);
    }
  };

  decodeErrorMessage( errMsg: string ) {
    const div = document.createElement("div");
    div.innerHTML = errMsg;
    const parsedText = div.textContent || div.innerText || "";
    this.setState({ errMsg: parsedText });
  }

  componentWillReceiveProps(nextProps:IComponentProps) {
    const { isActive } = nextProps;
    if (isActive) document.addEventListener("keypress", this.handleKeyDownLogIn);
    else document.removeEventListener("keypress", this.handleKeyDownLogIn);
  }

  componentWillUnmount() {
    document.removeEventListener("keypress", this.handleKeyDownLogIn);
  }

  render() {
    const popUpProps = {
      userEmail: this.state.userEmail,
      userPassword: this.state.userPassword,
      userLogin: this.state.userLogin,
      errMsg: this.state.errMsg,
      handleUserLoginChange: this.handleUserLoginChange.bind(this),
      handlePasswordChange: this.handlePasswordChange.bind(this),
      handleEmailChange: this.handleEmailChange.bind(this),
      resetPassword: this.resetPassword.bind(this),
      logIn: this.logIn,
      toggle: this.props.toggle,
      switchActive: this.props.switchActive,
      isActive: this.props.isActive
    }
    const { isActive, isInTrigger } = this.props;
    return (
      <div className="auth-button-wrapper">
        <p 
          className="auth-button"
          onClick={this.handleClick}
          style={{ 
            color: isActive ? '#036d9e' : undefined,
            display: isInTrigger ? 'none' : undefined
          }}
        >
          Войти
        </p>
        {isActive ? <LogInPopUpController {...popUpProps} /> : null }
      </div>
    );
  }
}

export default LogIn;
