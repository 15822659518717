import React from 'react';
import noPhoto from '../../../assets/img/nophoto.jpg';

interface IComponentProps {
  company_logo?: string,
  desctiption: string,
  title: string
}

const CompanyHeader: React.FC<IComponentProps> = ({ company_logo, desctiption, title }) => (
  <div className="company-dashboard-wrapper__header">
    <div className="text-container">
      <h2 className="company-title">{title}</h2>
      <p className="company-description">{desctiption}</p>
    </div>
    <div className="logo-container">
      <img src={company_logo ? company_logo : noPhoto} alt="logo"/>
    </div>
  </div>
);

export default CompanyHeader;
