import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps } from "@reach/router";
import AuthorizationContainer, {
  WrappedAuthorizationContainerTrigger,
} from "./AuthorizationContainer";
import AuthorizedUser from "../components/header/authorization/AuthorizedUser";
import { DisabledPhoneRoute, IStoreState } from "../common/types";
import mobileMenuButton from "../assets/img/mobile-menu-button.png";
import targetBlankIcon from "../assets/icons/target-blank-icon.png";
import "../assets/styles/header.scss";
import store from "redux/store";
import { alertPhoneVerified } from "redux/actions/actions";

interface IComponentProps {
  logo: string;
  user: IStoreState["user"];
  location: RouteComponentProps["location"];
}

interface IComponentState {
  isOpen: boolean;
}

type LinkProperty = "none" | "auto";

class Header extends Component<IComponentProps, IComponentState> {
  readonly state: IComponentState = {
    isOpen: false,
  };

  constructor(props: IComponentProps) {
    super(props);
    this.closeMenuCallback = this.closeMenuCallback.bind(this);
  }

  closeMenuCallback(event: Event) {
    const path = event.composedPath().slice(0, -3);
    const menu = document.getElementById("mobile-left-nav");
    if (!(menu && path.includes(menu))) {
      Header.toggleLinks("auto");
      this.setState({ isOpen: false });
      document.removeEventListener("click", this.closeMenuCallback);
    }
  }

  closeMenuWithoutPathCheck() {
    Header.toggleLinks("auto");
    this.setState({ isOpen: false });
    document.removeEventListener("click", this.closeMenuCallback);
  }

  menuButtonHandler() {
    const { isOpen } = this.state;
    if (!isOpen) {
      Header.toggleLinks("none");
      document.addEventListener("click", this.closeMenuCallback);
    }
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  static toggleLinks(property: LinkProperty) {
    const productLinks: any = document.getElementsByClassName("product");
    for (let item of productLinks) {
      item.style.pointerEvents = property;
    }
  }

  render() {
    const { user, location, logo } = this.props;
    const { isOpen } = this.state;
    const isAuthorized = user ? true : false;
    const hasCompany = isAuthorized && !!user.company;
    return (
      <header className="header">
        <LeftNav />
        <MobileLeftNav
          isOpen={isOpen}
          setClose={this.closeMenuWithoutPathCheck.bind(this)}
          location={location}
          isAuthorized={isAuthorized}
          hasCompany={hasCompany}
          phoneVerified={user ? user.phone_verified : false}
          phone={user ? user.phone : ""}
        />
        <div className="mobile-menu-button-container">
          <img
            src={mobileMenuButton}
            alt="mobile-menu-button"
            className="mobile-menu-button"
            onClick={this.menuButtonHandler.bind(this)}
          />
        </div>
        <Link to="/posts/1" className="logo-container">
          <img src={logo} alt="logo" />
        </Link>
        {isAuthorized ? (
          <AuthorizedUser user={user} />
        ) : (
          <AuthorizationContainer />
        )}
        <SubHeader
          location={location}
          isAuthorized={isAuthorized}
          phoneVerified={user ? user.phone_verified : false}
          phone={user ? user.phone : ""}
        />
      </header>
    );
  }
}

interface ISubHeaderProps {
  location: RouteComponentProps["location"];
  isAuthorized: boolean;
  phoneVerified: boolean;
  phone: string;
}

const SubHeader: React.FC<ISubHeaderProps> = ({
  location,
  isAuthorized,
  phoneVerified,
  phone,
}) => {
  const isPostsActive = location ? location.pathname.includes("/posts") : false;
  const isCompaniesActive = location
    ? location.pathname.includes("companies")
    : false;
  const isVacanciesActive = location
    ? location.pathname.includes("/vacancies")
    : false;
  return (
    <div className="sub-header">
      <ul className="nav">
        <Link
          to="posts/1"
          className={isPostsActive ? "nav-item active" : "nav-item"}
        >
          {" "}
          Техника{" "}
        </Link>
        <Link
          to="companies/1"
          className={isCompaniesActive ? "nav-item active" : "nav-item"}
        >
          {" "}
          Компании{" "}
        </Link>
        <Link
          to="vacancies/1"
          className={isVacanciesActive ? "nav-item active" : "nav-item"}
        >
          {" "}
          Вакансии{" "}
        </Link>
      </ul>
      {isAuthorized ? (
        <button
          className="action-button link action-button--sub-header"
          onClick={() => {
            store.dispatch(alertPhoneVerified(DisabledPhoneRoute.CreateAdvert));
          }}
        >
          Подать объявление
        </button>
      ) : (
        <WrappedAuthorizationContainerTrigger />
      )}
    </div>
  );
};

interface IMobileLeftNavProps {
  isOpen: boolean;
  setClose: () => void;
  location: RouteComponentProps["location"];
  isAuthorized: boolean;
  hasCompany: boolean;
  phoneVerified: boolean;
  phone: string;
}

const MobileLeftNav: React.FC<IMobileLeftNavProps> = ({
  isOpen,
  setClose,
  location,
  isAuthorized,
  hasCompany,
}) => {
  const isPostsActive = location ? location.pathname.includes("/posts") : false;
  const isCompaniesActive = location
    ? location.pathname.includes("companies")
    : false;
  const isVacanciesActive = location
    ? location.pathname.includes("vacancies")
    : false;
  return (
    <div
      className={isOpen ? "mobile-nav-container open" : "mobile-nav-container"}
      id="mobile-left-nav"
    >
      <ul className="nav dashboard">
        <Link
          to="posts/1"
          onClick={setClose}
          className={isPostsActive ? "nav-item active" : "nav-item"}
        >
          {" "}
          Каталог{" "}
        </Link>
        <Link
          to="companies/1"
          onClick={setClose}
          className={isCompaniesActive ? "nav-item active" : "nav-item"}
        >
          {" "}
          Компании{" "}
        </Link>
        <Link
          to="vacancies/1"
          onClick={setClose}
          className={isVacanciesActive ? "nav-item active" : "nav-item"}
        >
          {" "}
          Вакансии{" "}
        </Link>
      </ul>
      {isAuthorized ? (
        <button
          className="action-button link action-button--sub-header"
          onClick={() => {
            store.dispatch(alertPhoneVerified(DisabledPhoneRoute.CreateAdvert));
          }}
        >
          Подать объявление
        </button>
      ) : (
        <WrappedAuthorizationContainerTrigger />
      )}
      {hasCompany ? (
        <button
          className="action-button profile light link"
          onClick={() => {
            setClose();
            store.dispatch(
              alertPhoneVerified(DisabledPhoneRoute.CreateVacancy)
            );
          }}
          style={{ marginTop: "0rem", fontSize: "1rem" }}
        >
          Разместить вакансию
        </button>
      ) : null}
      <ul className="nav">
        <li className="nav-item">
          <a href="https://company.udarnik.by"> О КОМПАНИИ </a>
        </li>
        <li className="nav-item">
          <a href="https://company.udarnik.by/news"> НОВОСТИ </a>
        </li>
        <li className="nav-item">
          <a
            href="https://megazip.by/"
            className="nav-item"
            target="_blank"
            rel="noopener noreferrer"
          >
            ЗАПЧАСТИ
            <img src={targetBlankIcon} alt="megazip" />
          </a>
        </li>
        <li className="nav-item">
          <a href="https://company.udarnik.by/kontakty"> КОНТАКТЫ </a>
        </li>
      </ul>
    </div>
  );
};

const LeftNav: React.FC = () => (
  <div className="nav-container">
    <ul className="nav">
      <li className="nav-item">
        <a href="https://company.udarnik.by"> О КОМПАНИИ </a>
      </li>
      <li className="nav-item">
        <a href="https://company.udarnik.by/news"> НОВОСТИ </a>
      </li>
      <li className="nav-item">
        <a
          href="https://megazip.by/"
          className="nav-item"
          target="_blank"
          rel="noopener noreferrer"
        >
          ЗАПЧАСТИ
          <img src={targetBlankIcon} alt="megazip" />
        </a>
      </li>
      <li className="nav-item">
        <a href="https://company.udarnik.by/kontakty"> КОНТАКТЫ </a>
      </li>
    </ul>
  </div>
);

const mapStateToProps = (state: IStoreState) => ({
  user: state.user,
});

export default connect(mapStateToProps)(Header);
