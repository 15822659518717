import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUser } from '../redux/actions/actions';
import LogIn from '../components/header/authorization/LogIn';
import Registration from '../components/header/authorization/Registration';
import mobileAuthButton from '../assets/img/Authorization-button.png';
import { IStoreState } from '../common/types';

interface IComponentState {
  logInIsActive: boolean,
  registrationIsActive: boolean,
}

interface IComponentProps {
  getUser: (user:IStoreState['user']) => void;
  user: IStoreState['user']
}

class AuthorizationContainer extends Component<IComponentProps, IComponentState> {
  readonly state: IComponentState = {
    logInIsActive: false,
    registrationIsActive: false,
  };

  toggleLogIn(isActive: boolean) {
    this.setState({
      logInIsActive: isActive,
      registrationIsActive: false
    });
  };

  toggleRegistration(isActive: boolean) {
    this.setState({
      logInIsActive: false,
      registrationIsActive: isActive
    });
  };

  updateUser(user:IStoreState['user']) {
    const { getUser } = this.props;
    getUser(user);
  };

  render() {
    const { logInIsActive, registrationIsActive } = this.state;
    return (
      <div className="auth-container">
        <div className="mobile-menu-button-container">
          <img
            src={mobileAuthButton}
            alt="mobile-menu-button"
            className="mobile-menu-button"
            onClick={() => this.toggleLogIn.call(this, !logInIsActive)}
          />
        </div>
        <LogIn
          isActive={logInIsActive}
          toggle={this.toggleLogIn.bind(this)}
          switchActive={this.toggleRegistration.bind(this)}
          updateUser={this.updateUser.bind(this)}
        />
        <span className="divider">или</span>
        <Registration
          isActive={registrationIsActive}
          toggle={this.toggleRegistration.bind(this)}
          switchActive={this.toggleLogIn.bind(this)}
          updateUser={this.updateUser.bind(this)}
        />
      </div>
    );
  }
}

export class AuthorizationContainerTrigger extends AuthorizationContainer {
  render() {
    const { logInIsActive, registrationIsActive } = this.state;
    return (
      <div className="auth-container trigger">
        <button 
          type="button"
          className="action-button link action-button--sub-header"
          onClick={() => this.toggleLogIn.call(this, !logInIsActive)}
        >
          Подать объявление
        </button>
        <LogIn
          isActive={logInIsActive}
          isInTrigger={true}
          toggle={this.toggleLogIn.bind(this)}
          switchActive={this.toggleRegistration.bind(this)}
          updateUser={this.updateUser.bind(this)}
        />
        <Registration
          isActive={registrationIsActive}
          isInTrigger={true}
          toggle={this.toggleRegistration.bind(this)}
          switchActive={this.toggleLogIn.bind(this)}
          updateUser={this.updateUser.bind(this)}
        />
      </div>
    ) 
  }
}

const mapDispatchToProps = {
  getUser,
};

const mapStateToProps = (state:IStoreState) => ({
  user: state.user,
});

export const WrappedAuthorizationContainerTrigger = connect(mapStateToProps, mapDispatchToProps)(AuthorizationContainerTrigger);

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizationContainer);
