import React from 'react';
import { Link } from '@reach/router';
import { useDispatch, connect } from 'react-redux'
import { exitUser } from '../../redux/actions/actions';
import personIcon from '../../assets/icons/person-icon-dashboard.png';
import exitIcon from '../../assets/icons/exit-icon.png';
import advertsIcon from '../../assets/icons/posts-icon.png';
import companyIcon from '../../assets/icons/company-icon.png';
import vacanciesIcon from '../../assets/icons/vacancies-icon.svg';
import { IStoreState } from '../../common/types';

interface IComponentProps {
  user: IStoreState['user']
}

const UserNavigation:React.FC<IComponentProps> = ({ user }) => {
  const dispatch = useDispatch();
  const handleExit = () => (dispatch(exitUser()));
  return (
    <nav className="nav">
      <Link to="/dashboard/" className="nav-item">
        <img src={personIcon} alt="person icon"/>
        Личный кабинет
      </Link>
      <Link to="/dashboard/company" className="nav-item">
        <img src={companyIcon} alt="company icon"/>
        Данные компании
      </Link>
      <Link to="/dashboard/ads/1" className="nav-item">
        <img src={advertsIcon} alt="posts icon"/>
        Объявления
      </Link>
      {user && user.company ? 
        <Link to="/dashboard/company-vacancies/1" className="nav-item">
          <img src={vacanciesIcon} alt="vacancies icon"/>
          Вакансии
        </Link>
        : null
      }
      <Link to="/posts/1" className="nav-item" onClick={handleExit}>
        <img src={exitIcon} alt="exit icon"/>
        Выйти
      </Link>
    </nav>
  )
};

const mapStateToProps = (state:IStoreState) => ({
  user: state.user
})

export default connect(mapStateToProps)(UserNavigation);
