/**
 * Set request URL.
 * @constructor
 * @param {string} requestBody - Request body (e.g. 'wp-json/api/v1/post/').
 * @param {string} requestType - Request type (e.g. create, list, etc.).
 * @param {string} queryString - Query string (e.g. 'size=10&page=1&orderby=price&order=ASC').
 */
export default function setRequestUrl(requestBody:string, requestType:string, queryString?:string):string {
  if (queryString) return `${process.env.REACT_APP_API_URL}${requestBody}${requestType}?${queryString}`;
  else return `${process.env.REACT_APP_API_URL}${requestBody}${requestType}`;
}
