import React from 'react';
import noPhoto from '../../../assets/img/nophoto-profile.png';
import { IUser } from '../../../common/types';

interface IStaffItem {
  isCompanyAdmin: boolean,
  isUserAdmin: boolean,
  employee: IUser | any,
  removeEmployee: (employeeId: number) => void
}

const StaffItem: React.FC<IStaffItem> = ({ employee: {
  phone, user_avatar, user_details: { first_name, last_name }, id
}, removeEmployee, isUserAdmin, isCompanyAdmin }) => {
  return (
    <div className="item">
      <div className="item-card">
        <img src={user_avatar ? user_avatar : noPhoto} alt="avatar"/>
        <div className="text-container">
        { isCompanyAdmin ? <div className="admin">Администратор</div> : null}
          <p>{first_name} {last_name}</p>
          
          <a href={`tel:+${phone}`}>+{phone}</a>
        </div>
      </div>
      { isUserAdmin ?
        <div className="actions-container">
          <p 
            className="decline"
            onClick={() => removeEmployee(id)}
          >
            Удалить из компании
          </p>
        </div>
      : null }
    </div>
  )
}

export default StaffItem;
