import React, { Component } from 'react';
import sortArrowDown from '../../assets/img/sort-arrow-down.png';
import '../../assets/styles/products/filter.scss';

interface IComponentProps {
  sortedBy: 'date' | 'hours' | 'year' | 'price',
  order: 'DESC' | 'ASC',
  displayPrice: 'BYN' | 'RUB' | 'USD' | 'EUR' ,
  handleDisplayPriceChange: (event: React.ChangeEvent<HTMLSelectElement>) => void,
  setSortParams: (sortedBy: IComponentProps['sortedBy'], order: IComponentProps['order']) => void
}

class ProductsSort extends Component<IComponentProps, {}> {
  handleClick(event: React.MouseEvent<HTMLDivElement>, sortedBy: IComponentProps['sortedBy'] ) {
    const { setSortParams, order } = this.props;
    if (event.currentTarget.classList.value.includes('active')) { 
      if (order === 'DESC') setSortParams(sortedBy, 'ASC');
      else setSortParams(sortedBy, 'DESC');
    }
    else setSortParams(sortedBy, 'DESC');
  }

  handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const [
      sortedBy,
      order
    ]:any = event.currentTarget.value.split(' ');
    this.props.setSortParams(sortedBy, order);
  }

  render() {
    const { sortedBy, order, displayPrice, handleDisplayPriceChange } = this.props;
    return (
      <div className="sort-panel">
        <select 
          name="displayPrice"
          className="filter-item price-selector"
          onChange={handleDisplayPriceChange}
          value={displayPrice}
        >
          <option value="BYN">BYN</option>
          <option value="RUB">RUB</option>
          <option value="USD">USD</option>
          <option value="EUR">EUR</option>
        </select>
        <MobileSortPanel 
          value={`${sortedBy} ${order}`}
          handleChange={this.handleChange.bind(this)}
        />
        <div className="sort-collection">
          <p>Сортировать по:</p>
          <div 
            role="button"
            className={ 
              sortedBy !== 'price' ? "sort-button"
              : order === 'ASC' ? "sort-button active asc" : "sort-button active"
            } 
            onClick={(event) => this.handleClick.call(this, event, 'price')}
          >
            <p>цене</p>
            <img src={sortArrowDown} alt="sort orientation"/>
          </div>
          <div 
            role="button"
            className={ 
              sortedBy !== 'date' ? "sort-button"
              : order === 'ASC' ? "sort-button active asc" : "sort-button active"
            } 
            onClick={(event) => this.handleClick.call(this, event, 'date')}
          >
            <p>дате</p>
            <img src={sortArrowDown} alt="sort orientation"/>
          </div>
          <div 
            role="button"
            className={ 
              sortedBy !== 'hours' ? "sort-button"
              : order === 'ASC' ? "sort-button active asc" : "sort-button active"
            } 
            onClick={(event) => this.handleClick.call(this, event, 'hours')}
          >
            <p>моточасам</p>
            <img src={sortArrowDown} alt="sort orientation"/>
          </div>
          <div 
            role="button"
            className={ 
              sortedBy !== 'year' ? "sort-button"
              : order === 'ASC' ? "sort-button active asc" : "sort-button active"
            } 
            onClick={(event) => this.handleClick.call(this, event, 'year')}
          >
            <p>году выпуска</p>
            <img src={sortArrowDown} alt="sort orientation"/>
          </div>
        </div>
      </div>
    )
  }
}

interface IMobileSortPanel {
  value: string,
  handleChange: (event: React.ChangeEvent<HTMLSelectElement>) => void,
}

const MobileSortPanel: React.FC<IMobileSortPanel> = ({ handleChange, value }) => (
  <div className="mobile-sort-item">
    <select 
      name="sort"
      value={value}
      className="filter-item"
      onChange={handleChange}
    >
      <option value="price ASC">По цене: сначала дешевые</option>
      <option value="price DESC">По цене: сначала дорогие</option>
      <option value="date DESC">По дате подачи: сначала новые</option>
      <option value="date ASC">По дате подачи: сначала старые</option>
      <option value="hours DESC">По моточасам: сначала больше</option>
      <option value="hours ASC">По моточасам: сначала меньше</option>
      <option value="year DESC">По году выпуска: сначала новые</option>
      <option value="year ASC">По году выпуска: сначала старые</option>
    </select>
  </div>
);

export default ProductsSort;
