import React from 'react';

interface IComponentProps {
  title: string,
  description: string
}

const VacancyDetailHeader: React.FC<IComponentProps> = ({ title, description }) => {
  return (
    <div className="company-detail-header">
      <h2 className="title">{title}</h2>
      <p>{description}</p>
    </div>
  )
}

export default VacancyDetailHeader;
