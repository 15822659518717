import React from 'react';
import { Link } from '@reach/router';

interface IComponentProps {
  pathname: string
}

const CompanyNavDashboard: React.FC<IComponentProps> = ({ pathname }) => (
  <ul className="company-dashboard-wrapper__nav">
    <li className={pathname.includes('company/info') ? "active" : undefined}>
      <Link to="info">Информация</Link>
    </li>
    <li className={pathname.includes('company/staff') ? "active" : undefined}>
      <Link to="staff">Сотрудники</Link>
    </li>
  </ul>
);

export default CompanyNavDashboard

export const CompanyNav: React.FC<IComponentProps> = ({ pathname }) => (
  <ul className="company-dashboard-wrapper__nav">
    <li className={pathname.includes('company-posts') ? "active" : undefined}>
      <Link to="company-posts/1">Объявления</Link>
    </li>
    <li className={pathname.includes('company-vacancies') ? "active" : undefined}>
      <Link to="company-vacancies/1">Вакансии</Link>
    </li> 
  </ul>
);
