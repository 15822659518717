import React, { Component } from 'react';
import { Link } from '@reach/router';
import sendRequest from '../../../common/utilits/sendRequest';
import setRequestUrl from '../../../common/utilits/setRequestUrl';
import setRequestParams from '../../../common/utilits/setRequestParams';
import { IUser } from '../../../common/types';

import Deleted from '../../../components/products/utils/Deleted';

interface IComponentProps {
    path: string,
    vacancyId: string,
    user: IUser
}

interface IComponentState {
    isDeleted: boolean,
}

class DeletingVacansy extends Component<IComponentProps> {

    readonly state: IComponentState = {
        isDeleted: false,
    }

    async deleteVacancy() {
        const { user, vacancyId } = this.props;
        if (user) {
            const requestURL = setRequestUrl('wp-json/api/v1/vacancy/', 'delete', `id=${vacancyId}`);
            const requestParams = setRequestParams('DELETE', user.token);
            await sendRequest(requestURL, requestParams);
            this.setState({ isDeleted: true })
        }
    }


    render() {
        const { isDeleted } = this.state;

        if (isDeleted)
            return (<Deleted fromCompanyList={true} />);

        return (
            <div className="deleting">
                <p>Вы уверены, что хотите удалить объявление?</p>
                <div className="button-container">
                    <Link
                        to={`../../`}
                        className="action-button delete"
                    >
                        ОТМЕНА 
                    </Link>
                    <button
                        type="button"
                        className="action-button secondary"
                        onClick={this.deleteVacancy.bind(this)}
                    >
                        УДАЛИТЬ
                    </button>
                </div>
            </div>
        )
    }

};

export default DeletingVacansy;
