import React from 'react';
import { Link } from '@reach/router';

interface IComponentProps {
    isEdit?: boolean,
    fromCompanyList?: boolean
}

const CompanyPostedMessage: React.FC<IComponentProps> = ({ isEdit, fromCompanyList }) => (
    <div className="loaded">
        <p>{isEdit ? 'Вакансия успешно отредактирована' : 'Ваша вакансия успешно отправлена на модерацию'}</p>
        <Link to={fromCompanyList ? "../../../../" : "../"}>
            Вернуться в личный кабинет
        </Link>
    </div>
);

export default CompanyPostedMessage;
