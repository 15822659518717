import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Router, Redirect } from '@reach/router';
import ProductsList from '../../containers/ProductsList';
import ProductDetail from '../../pages/products/ProductDetail';
import ProductsFilter from '../../components/products/ProductsFilter';
import EditAdvert from '../dashboard/EditAdvert';
import { IStoreState, IAdvertItem } from '../../common/types';
import setRequestParams from '../../common/utilits/setRequestParams';
import mobileFilterButton from '../../assets/img/mobile-filter-button.png';
import '../../assets/styles/products/products.scss';
import { CreateOffer } from 'pages/dashboard/CreateOffer';
import Helmet from 'react-helmet';
import { ym } from 'common/utilits/yandex-metrix';

interface IComponentProps extends RouteComponentProps {
  user: IStoreState['user'],
}

interface IComponentState {
  advertList: Array<IAdvertItem>,
  page: number,
  perPage: number,
  totalPages: number,
  apiURL: string,
  filterParams: Array<string>,
  sortedBy: 'date' | 'hours' | 'year' | 'price',
  order: 'DESC' | 'ASC',
  isLoaded: boolean,
  isFilterOpen: boolean
}

class Products extends Component<IComponentProps, IComponentState> {
  readonly state: IComponentState = {
    isLoaded: false,
    isFilterOpen: false,
    advertList: [],
    page: 1,
    perPage: 10,
    totalPages: 1,
    apiURL: `${process.env.REACT_APP_API_URL}wp-json/api/v1/post/list?size=10`,
    filterParams: [],
    sortedBy: 'date',
    order: 'DESC'
  };

  setIsFilterOpen() {
    this.setState((prevState) => ({ isFilterOpen: !prevState.isFilterOpen }));
  }

  sendRequest(requestURL: string, requestParams: object): void {
    fetch(requestURL, requestParams)
      .then(response => response.json())
      .then(content => this.setState({
        advertList: content.data,
        page: content.page,
        perPage: content.perPage,
        totalPages: content.totalPages,
        isLoaded: true
      }))
      .catch(err => console.log(err));
  }

  setFilterParams(filterParams: Array<string>, pageId: number = 1): string {
    const { apiURL, sortedBy, order } = this.state;
    const concatedFilterParams: string = filterParams[0] === 'cache'
      ? this.state.filterParams.reduce((acc, value) => acc.concat(value), `&page=${pageId}&orderby=${sortedBy}&order=${order}`)
      : filterParams.reduce((acc, value) => acc.concat(value), `&page=${pageId}&orderby=${sortedBy}&order=${order}`);
    const requestURL = apiURL.concat(concatedFilterParams)
    if (filterParams[0] !== 'cache') this.setState({ filterParams });
    return requestURL;
  }

  getPosts(filterParams: Array<string> = [], pageId: number = 1, method: string = 'GET') {
    const requestURL = this.setFilterParams(filterParams, pageId);
    const requestParams = setRequestParams(method);
    this.setState({ isLoaded: false });
    this.sendRequest(requestURL, requestParams);
  }

  getPage(pageNumber: number) {
    this.getPosts(['cache'], pageNumber);
  }

  render() {
    const { user, location } = this.props;
    const { page, isLoaded, isFilterOpen } = this.state;
    const isAuthorized = user ? true : false;
    const token = user ? user.token : undefined;
    const pathname = location ? location.pathname : '';
    return (
      <div className="main-wrapper">
        <Helmet
          script={[{
            type: 'text/javascript',
            innerHTML: ym()
          }]} >
          <title>Ударник | Объявления</title>
          <meta
            name="description"
            content="Предложения по продажи подержанной и новой лесозаготовительной техники (харвестеры, форвардеры)."
          />
        </Helmet>
        {isLoaded && pathname.split('/').length === 3 ?
          <div className="mobile-filter-button-container" onClick={this.setIsFilterOpen.bind(this)}>
            <p>Фильтры</p>
            <img
              src={mobileFilterButton}
              alt="filter button"
            />
          </div>
          : null}
        <ProductsFilter
          location={location}
          sendFilterParams={this.getPosts.bind(this)}
          setIsFilterOpen={this.setIsFilterOpen.bind(this)}
          isFilterOpen={isFilterOpen}
          token={token}
        />
        <Router style={{ width: '100%' }}>
          <ProductsList
            path='/:page'
            page=':page'
            advertList={this.state.advertList}
            setParentState={this.setState.bind(this)}
            user={user}
            apiURL={this.state.apiURL}
            filterParams={this.state.filterParams}
            totalPages={this.state.totalPages}
            getPosts={this.getPosts.bind(this)}
            getPage={this.getPage.bind(this)}
            isLoaded={isLoaded}
            expired={() => { }}
          />
          <ProductDetail
            path='/:page/:productId'
            productId=':productId'
            isAuthorized={isAuthorized}
            user={user}
          />
          <EditAdvert
            path='/:page/:productId/edit'
            productId=':productId'
            user={user}
          />
          <CreateOffer path='/:page/:productId/create-offer' productId=':productId' user={user} />
          <Redirect from='/' to={`posts/${page}`} noThrow />
        </Router>
      </div>
    )
  }
}

const mapStateToProps = (state: IStoreState) => ({
  user: state.user,
});

export default connect(mapStateToProps)(Products);
