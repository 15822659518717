import React from 'react'
import { RouteComponentProps } from '@reach/router';
import formatDate from '../../../common/utilits/formatDate';
// import arrowIcon from '../../../assets/icons/arrow-up-icon.png';

interface IComponentProps extends RouteComponentProps {
  id: number,
  published: string,
  model: string,
  vendor: string,
  category: [string]
}

const ProductDetailHeader: React.FC<IComponentProps> = ({
  id, published, model, vendor, category
}) => {
  const categoryString = category ? category : ['Форвардер'];
  const datePublished = published ? formatDate(published) : '';
  return (
    <div className="product-detail-header">
      {/* <div className="navigation">
        <Link to="../">
          <img src={arrowIcon} alt="go back"/>
          Вернуться к списку
        </Link>
      </div> */}
      <div className="info">
        <h2 itemProp="name" >{categoryString} {vendor} {model}</h2>
        <div className="info">
          <p>{datePublished}</p>
          <p>№{id}</p>
        </div>
      </div>
    </div>
  )
}

export default ProductDetailHeader;
