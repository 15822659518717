const toDataURL = (url:string) => fetch(url)
  .then(response => response.blob())
  .then(blob => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  }))


export default async function convertToBase64(images: {id: number, main: number, url: string}[]) {
  const convertedImages:{ main: number, data: any }[] = [];
  if (images[0]) {
    for (const item of images) {
      const dataUrl= await toDataURL(item.url);
      convertedImages.push({
        main: item.main,
        data: dataUrl
      });
    }
    return convertedImages;
  }
  return [];
}
