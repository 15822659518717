import React from 'react'
import Modal from 'react-modal'

export enum ThemeType{
  Warning ="warning",
  Primary ="primary",
  Danger ="danger"
}

interface IModalProps {
  isOpen: boolean,
  text: string,
  btnText: string,
  typeConfirm?: ThemeType
  close: () => void,
  onConfirm: () => void
}

const ModalConfirm: React.FC<IModalProps> = ({ isOpen, close, onConfirm, text, btnText, typeConfirm }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      className="modal-confirm"
      overlayClassName="modal-confirm-overlay"
    >
      <div className="content">
        <p>{text}</p>
        <div className="button-container">
          <div className="button button-cancel" onClick={close}><p>Отмена</p></div> <div onClick={onConfirm} className={`button button-confirm-${typeConfirm || ThemeType.Danger}`}><p>{btnText}</p></div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalConfirm;
