import React, { Component } from 'react';
import { Link, RouteComponentProps } from '@reach/router';
import { connect } from 'react-redux';
import NotFound from '../../common/NotFound';
import Loading from '../../common/loading';
import Deleting from '../../components/products/utils/Deleting';
import Deleted from '../../components/products/utils/Deleted';
import ProductDetailHeader from '../../components/products/product-detail/ProductDetailHeader';
import GetPhoneNumberModal from '../../components/products/product-detail/GetPhoneNumberModal';
import RightColumn from '../../components/products/product-detail/RightInfoColumn';
import LeftColumn from '../../components/products/product-detail/LeftInfoColumn';
import setRequestUrl from '../../common/utilits/setRequestUrl';
import setRequestParams from '../../common/utilits/setRequestParams';
import sendRequest from '../../common/utilits/sendRequest';
import getExchangeRates from '../../common/utilits/getExchangeRates';
import { IStoreState, IAdvertItem, TPreferredCurrency, IExchangeRates } from '../../common/types';
import '../../assets/styles/products/products-detail.scss';

export interface ISingleAdvert extends IAdvertItem {
  contacts: {
    phone: string
  }
}

interface IComponentProps extends RouteComponentProps {
  productId: string,
  isAuthorized: boolean,
  preferredCurrency: TPreferredCurrency,
  user: IStoreState['user']
}

interface IComponentState {
  isLoaded: boolean,
  isDeleting: boolean,
  isDeleted: boolean,
  isNotFound: boolean,
  isNumberShown: boolean,
  isModalActive: boolean,
  phoneNumber: string,
  customerName: string,
  isPhoneNumberValid: boolean,
  isNameValid: boolean,
  advert: ISingleAdvert,
  exchangeRates?: IExchangeRates
}

class ProductDetail extends Component<IComponentProps, IComponentState> {

  readonly state: IComponentState = {
    isLoaded: false,
    isDeleting: false,
    isDeleted: false,
    isNotFound: false,
    isModalActive: false,
    isNumberShown: false,
    phoneNumber: '',
    customerName: '',
    isPhoneNumberValid: false,
    isNameValid: false,
    advert: {
      author: {
        user_details: {
          first_name: '',
          last_name: '',
          country: '',
          city: '',
        },
        user_avatar: ''
      },
      images: undefined,
      category: [''],
      full: '',
      id: 1,
      contacts: {
        phone: ''
      },
      location: {
        country: '',
        city: '',
      },
      model: '',
      price: {
        byn: '',
        currency: '',
        value: '',
        exchange: false,
        include_tax: false,
        optional_price: false
      },
      hours: '',
      condition: '',
      sn: '',
      model_vendor: ['', ''],
      published: '',
      modified: '',
      short: '',
      title: '',
      vendor: '',
      proposed_vendor: '',
      proposed_model: '',
      year: '',
      fromCompany: false,
      company_id: '',
      post_status: undefined
    }
  }

  handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value: string = event.currentTarget.value;
    this.setState({
      isNameValid: true,
      customerName: value
    });
  };

  handlePhoneNumberChange(event: React.ChangeEvent<HTMLInputElement>) {
    const phoneNumber: string = event.currentTarget.value;
    const reg = /^\d{1,}$/;
    const isValid = phoneNumber.length >= 12 && reg.test(phoneNumber);
    event.currentTarget.style.borderColor = isValid ? 'green' : 'red';
    this.setState({
      isPhoneNumberValid: isValid,
      phoneNumber
    });
  };

  static matchUser(user: IStoreState['user'], isAuthorized: boolean, advert: IAdvertItem) {
    if (!isAuthorized || !advert.contacts) return false;

    if (advert.author.unn && user.company) {
      if (+advert.author.id === user.company && user.company_admin)
        return true;
    }

    return advert.contacts.phone === user.phone;
  }

  setIsDeleting() {
    this.setState((prevState) => ({ isDeleting: !prevState.isDeleting }));
  }

  toggleModal() {
    this.setState((prevState) => ({ isModalActive: !prevState.isModalActive }));
  }

  async sendData(phone: string, name: string) {
    const payload = {
      advert: `${this.props.productId}`,
      phone: `${phone}`,
      name: `${name}`
    }

    const requestURL = setRequestUrl('wp-json/api/v1/post/', 'sendUserData');
    const requestParams = setRequestParams('POST', undefined, payload);
    const content = await sendRequest(requestURL, requestParams);

    this.toggleModal();
    if (!content || content.errMsg)
      return
    this.setState(prevState => ({
      ...prevState,
      advert: {
        ...prevState.advert,
        contacts: {
          phone: content
        }
      }
    }))
    this.showContactNumber();
  }

  async getAdvert(requestURL: string, requestParams: object) {
    const content = await sendRequest(requestURL, requestParams);
    if (content.errMsg) this.setState({ isNotFound: true, isLoaded: true });
    else this.setState({ advert: content, isLoaded: true })
  }

  async deleteAdvert() {
    const { user, productId, isAuthorized } = this.props;
    const { advert } = this.state;
    const isUserMatch = ProductDetail.matchUser(user, isAuthorized, advert);
    if (isUserMatch) {
      const requestURL = setRequestUrl('wp-json/api/v1/post/', 'delete', `id=${productId}`);
      const requestParams = setRequestParams('DELETE', user.token);
      await sendRequest(requestURL, requestParams);
      this.setState({ isDeleting: false, isDeleted: true })
    }
  }

  showContactNumber() {
    if (this.state.advert.contacts) {
      this.setState((prevState) => ({ isNumberShown: !prevState.isNumberShown }));
    } else {
      this.setState({ isModalActive: true });
    }
  }

  componentDidMount() {
    const { user, productId, isAuthorized } = this.props;
    const requestURL = setRequestUrl('wp-json/api/v1/post/', 'single', `id=${productId}`);
    const requestParams = isAuthorized ? setRequestParams('GET', user.token) : setRequestParams('GET');
    this.getAdvert(requestURL, requestParams);
    getExchangeRates().then((exchangeRates) => this.setState({ exchangeRates }));
  }

  componentWillReceiveProps(nextProps: IComponentProps) {
    const { user, productId, isAuthorized } = nextProps;
    const requestURL = setRequestUrl('wp-json/api/v1/post/', 'single', `id=${productId}`);
    const requestParams = isAuthorized ? setRequestParams('GET', user.token) : setRequestParams('GET');
    this.getAdvert(requestURL, requestParams);
  }

  render() {
    const { isAuthorized, user, preferredCurrency } = this.props;
    const {
      isLoaded, advert, isDeleting, isDeleted, isNotFound,
      isNumberShown, isModalActive, phoneNumber, customerName, exchangeRates
    } = this.state;
    if (advert.id === null) return (<NotFound />);
    const {
      id,
      published,
      year,
      sn,
      hours,
      condition,
      author,
      contacts,
      full,
      images,
      location,
      price,
      short,
      category,
      model_vendor,
      proposed_vendor,
      proposed_model,
    } = advert;
    const isUserMatch = ProductDetail.matchUser(user, isAuthorized, advert);

    if (!isLoaded)
      return (<Loading isActive={!isLoaded} />)

    if (isNotFound)
      return (<NotFound />);

    if (isDeleting)
      return (
        <Deleting
          deleteAdvert={this.deleteAdvert.bind(this)}
          setIsDeleting={this.setIsDeleting.bind(this)}
        />
      );

    if (isDeleted)
      return (<Deleted />);

    const [model, vendor] = model_vendor ? model_vendor : ['Buffalo AF 10W', 'Ponsse'];

    const leftColumnProps = {
      full,
      short,
      exchange: price.exchange,
      images,
      model,
      vendor,
      proposed_vendor,
      proposed_model,
      category,
      sn,
      year,
      hours,
      condition
    };

    const contactNumber = contacts ? contacts.phone : '';

    const rightColumnProps = {
      price,
      preferredCurrency,
      exchangeRates,
      year,
      location,
      hours,
      condition,
      author,
      isNumberShown,
      contactNumber,
      isUserMatch,
      showContactNumber: this.showContactNumber.bind(this),
      deleteAction: this.setIsDeleting.bind(this)
    };

    const headerProps = {
      id,
      published,
      model,
      vendor,
      category
    };

    const modalProps = {
      phoneNumber,
      customerName,
      handleNameChange: this.handleNameChange.bind(this),
      handlePhoneNumberChange: this.handlePhoneNumberChange.bind(this),
      toggle: this.toggleModal.bind(this),
      sendData: this.sendData.bind(this)
    };

    const pathname = this.props.location ? this.props.location.pathname : '';
    return (
      <div className="product-detail-wrapper" style={{ width: pathname.includes('dashboard/ads') ? '100%' : undefined }} itemScope itemType="http://schema.org/Product">
        {(user && user.roles && user.roles.includes("administrator")) ? <Link className="action-button link preview-btn" style={{ display: "inline-block" }} to="./create-offer" >Создать предложение</Link> : null}
        {isModalActive ? <GetPhoneNumberModal {...modalProps} /> : null}
        <ProductDetailHeader {...headerProps} />
        <div className="product-detail-main">
          <LeftColumn {...leftColumnProps} />
          <RightColumn {...rightColumnProps} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IStoreState) => ({
  preferredCurrency: state.preferredCurrency
});

export default connect(mapStateToProps)(ProductDetail);
