import React, { useRef } from 'react';

interface IComponentProps {
  customerName: string,
  handleNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  phoneNumber: string,
  handlePhoneNumberChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  toggle: () => void,
  sendData: (phone: string, name: string) => void,
}

const GetPhoneNumberModal: React.FC<IComponentProps> = ({
  phoneNumber, handlePhoneNumberChange, toggle, sendData, customerName, handleNameChange
}) => {
  const modalRef = useRef(null);
  const handleDisable = (event: React.MouseEvent<HTMLDivElement>) => {
    if (modalRef.current === event.target) toggle();
  }
  return(
    <div className="modal" onClick={handleDisable} ref={modalRef}>
      <form className="pop-up">
        <p className="info-message">Для безопасности наших пользователей Вам нужно оставить свой контактный номер, либо зарегистрироваться на сайте</p>
        <div className="content-wrapper">
          <div className="forms-container">
            <input
              className="auth-input last-child"
              type="tel"
              value={phoneNumber}
              placeholder="Номер телефона"
              name="tel"
              onChange={handlePhoneNumberChange}
              required
            />
            <input
              className="auth-input last-child"
              type="text"
              value={customerName}
              placeholder="Ваше имя (по желанию)"
              name="name"
              onChange={handleNameChange}
              required
            />
          </div>
          <div className="buttons-container">
            <button type="button" className="action-button" onClick={() => sendData(phoneNumber, customerName)}>Отправить</button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default GetPhoneNumberModal;
