import React from 'react';
import experienceIcon from '../../../assets/icons/experience-icon.svg';
import workTimeIcon from '../../../assets/icons/work-time-icon.svg';
import timetableIcon from '../../../assets/icons/timetable-icon.svg';
import workTypeIcon from '../../../assets/icons/work-type-icon.svg';
import { IVacancyItem } from '../../../common/types';

interface IComponentProps {
  full: IVacancyItem['full']
  experience: string,
  work: string,
  employment: string,
  schedule: string,
  author: IVacancyItem['author'],
}

const VacancyDetailMain: React.FC<IComponentProps> = ({
  full, experience, work, employment, schedule, author
}) => {
  const contentToHtml = (text:string) => text.split('\r\n').filter(item => item !== "").map((paragraph:string) => <p key={paragraph}>{paragraph}</p>);
  return (
    <div className="vacancy-detail-main">
      <div className="description-section">
        <div className="state-section">
          <div className="blocks">
            <div className="block">
              <img src={experienceIcon} alt="experience"/>
              <p>Опыт: {experience}</p>
            </div>
            <div className="block">
              <img src={timetableIcon} alt="timetable"/>
              <p>График работы: {schedule}</p>
            </div>
          </div>
          <div className="blocks">
            <div className="block">
              <img src={workTypeIcon} alt="work-type"/>
              <p>Характер работы: {work}</p>
            </div>
            <div className="block">
              <img src={workTimeIcon} alt="work-time"/>
              <p>Занятость: {employment}</p>
            </div>
          </div>
        </div>
        <div className="text-section">
          <h2>Описание вакансии</h2>
          {contentToHtml(full)}
        </div>
      </div>
      <div className="contacts-section">
        <h2>Контакты</h2>
        <div className="contact-block">
          <p className="name">Контактное лицо</p>
          <p className="value">{author.user.user_details.first_name} {author.user.user_details.last_name}</p>
        </div>
        <div className="contact-block">
          <p className="name">Телефон</p>
          <a 
            href={`tel:${author.user.contacts ? author.user.contacts.phone : author.company.contacts.phone}`}
            className="value"
          >
            {author.user.contacts ? `+${author.user.contacts.phone}` : author.company.contacts.phone}
          </a>
        </div>
        {author.company.contacts.email ?
          <div className="contact-block">
            <p className="name">Электронная почта</p>
            <a 
              href={`mailto:${author.company.contacts.email}`}
              className="value mail-link"
            >
              {author.company.contacts.email}
            </a>
          </div>
        : null}
      </div>
    </div>
  )
}

export default VacancyDetailMain;
