import React, { useState } from 'react';
import setCalculatedPrice from '../../common/utilits/setCalculatedPrice';
import { IVacancyItem, IExchangeRates } from '../../common/types';

interface IComponentProps {
  payload?: IVacancyItem,
  createVacancy: (composedData: object) => void,
  exchangeRates: IExchangeRates
}

const CompanyRegistrationForm: React.FC<IComponentProps> = ({ createVacancy, payload, exchangeRates }) => {

  const [validity, setValidity] = useState({
    isVacancyTitleValid: true,
    isVacancyCountryValid: true,
    isVacancyCityValid: true,
    isVacancyCategoryValid: true,
    isMinSalaryValid: true,
    isMaxSalaryValid: true,
    isVacancyDescriptionValid: true
  });

  const [formState, setFormState] = useState({
    vacancyTitle: payload ? payload.title : '',
    vacancyLocation: {
      country: payload ? payload.location.country : '',
      city: payload ? payload.location.city : ''
    },
    vacancySalary: {
      min: '',
      max: '',
      currency: 'BYN',
    },
    category: payload ? payload.category[0] : '',
    experience: payload ? payload.experience[0] : 'Без опыта',
    schedule: payload ? payload.schedule[0] : 'Фиксированный',
    employment: payload ? payload.employment[0] : 'Полная',
    work: payload ? payload.work[0] : 'На территории работодателя',
    vacancyDescription: payload ? payload.full : '',
  });

  const numberReg = /^\d{1,}$/;

  const checkValidity = () => {
    if (formState.vacancyTitle === '' || formState.vacancyLocation.country === '' || formState.vacancyLocation.city === '' 
      || formState.vacancyDescription === '' || formState.category === '' || formState.vacancySalary.min === ''
      || formState.vacancySalary.max === '' || !numberReg.test(formState.vacancySalary.min) || !numberReg.test(formState.vacancySalary.max)
    ) {
      setValidity({
        isVacancyTitleValid: formState.vacancyTitle !== '',
        isVacancyDescriptionValid: formState.vacancyDescription !== '',
        isVacancyCountryValid: formState.vacancyLocation.country !== '',
        isVacancyCityValid: formState.vacancyLocation.city !== '',
        isVacancyCategoryValid: formState.category !== '',
        isMinSalaryValid: formState.vacancySalary.min !== '' && numberReg.test(formState.vacancySalary.min),
        isMaxSalaryValid: formState.vacancySalary.max !== '' && numberReg.test(formState.vacancySalary.max)
      });
      window.scrollTo(0 , 0);
    } else {
      createVacancy(composeData());
    }
  }

  const composeData = () => ({
    title: formState.vacancyTitle,
    full: formState.vacancyDescription,
    location: formState.vacancyLocation,
    price: {
      currency: formState.vacancySalary.currency,
      min: {
        value: formState.vacancySalary.min,
        byn: setCalculatedPrice(formState.vacancySalary.currency, formState.vacancySalary.min, exchangeRates)['byn']
      },
      max: {
        value: formState.vacancySalary.max,
        byn: setCalculatedPrice(formState.vacancySalary.currency, formState.vacancySalary.max, exchangeRates)['byn']
      }
    },
    category: formState.category,
    experience: formState.experience,
    work: formState.work,
    employment: formState.employment,
    schedule: formState.schedule
  })

  return (
    <form className="company-registration-container">
      <div className="payload-container">
        <div className="input-container">
          <label htmlFor="category">Категория*</label>
          <select
            name="category"
            className={validity.isVacancyCategoryValid ? "product-input" : "product-input invalid"}
            value={formState.category}
            onChange={(event) => setFormState({ ...formState, category: event.currentTarget.value })}
            onBlur={() => setValidity({...validity, isVacancyCategoryValid: formState.category !== ''})}
          >
            <option value=""></option>
            <option value="Механик">Механик</option>
            <option value="Оператор форвардера">Оператор форвардера</option>
            <option value="Оператор харвестера">Оператор харвестера</option>
          </select>
          { validity.isVacancyCategoryValid ? null
            : <p className="form-invalid-indicator">Необходимо указать категорию!</p> 
          }
        </div>
        <div className="input-container">
          <label htmlFor="companyName">Вакансия*</label>
          <input
            name="companyName"
            type="text"
            className={validity.isVacancyTitleValid ? "product-input" : "product-input invalid"}
            value={formState.vacancyTitle}
            onChange={(event) => setFormState({ ...formState, vacancyTitle: event.currentTarget.value })}
            onBlur={() => setValidity({...validity, isVacancyTitleValid: formState.vacancyTitle !== ''})}
          />
          { validity.isVacancyTitleValid ? null
            : <p className="form-invalid-indicator">Необходимо указать вакансию!</p> 
          }
        </div>

        <div className="input-container textarea">
          <label htmlFor="companyDescription">Город*</label>
          <div className="address-container">
            <div className="address-form">
              <input
                type="text"
                className={validity.isVacancyCountryValid ? "product-input left" : "product-input invalid left"}
                value={formState.vacancyLocation.country}
                name="country"
                placeholder="Страна"
                onChange={(event) => setFormState({ ...formState, vacancyLocation:{ ...formState.vacancyLocation, country: event.currentTarget.value }})}
                onBlur={() => setValidity({...validity, isVacancyCountryValid: formState.vacancyLocation.country !== ''})}
              />
              <input
                className={validity.isVacancyCityValid ? "product-input right" : "product-input invalid right"}
                name="city"
                type="text"
                placeholder="Город"
                value={formState.vacancyLocation.city}
                onChange={(event) => setFormState({ ...formState, vacancyLocation:{ ...formState.vacancyLocation, city: event.currentTarget.value }})}
                onBlur={() => setValidity({...validity, isVacancyCityValid: formState.vacancyLocation.city !== ''})}
              />
            </div>
          </div> 
          { validity.isVacancyCountryValid && validity.isVacancyCityValid ? null : <p className="form-invalid-indicator">Необходимо указать город!</p> }
        </div>
      </div>

      <div className="payload-container">
        <div className="input-container">
          <label htmlFor="price">Зарплата мин.</label>
          <div className="price-container">
            <div className="price-form">
              <input
                type="text"
                className={validity.isMinSalaryValid ? "product-input left" : "product-input invalid left"}
                value={formState.vacancySalary.min}
                name="value"
                onBlur={() => setValidity({...validity, isMinSalaryValid: formState.vacancySalary.min !== '' && numberReg.test(formState.vacancySalary.min)})}
                onChange={(event) => setFormState({ ...formState, vacancySalary:{ ...formState.vacancySalary, min: event.currentTarget.value }})}
              />
              <select
                className={validity.isMinSalaryValid ? "product-input right" : "product-input invalid right"}
                name="currency"
                value={formState.vacancySalary.currency}
                onChange={(event) => setFormState({ ...formState, vacancySalary:{ ...formState.vacancySalary, currency: event.currentTarget.value }})}
              >
                <option value="BYN">BYN</option>
                <option value="RUB">RUB</option>
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
              </select>
            </div>
          </div>
        </div>

        <div className="input-container">
          <label htmlFor="price">Зарплата макс.</label>
          <div className="price-container">
            <div className="price-form">
              <input
                type="text"
                className={validity.isMaxSalaryValid ? "product-input left" : "product-input invalid left"}
                value={formState.vacancySalary.max}
                name="value"
                onBlur={() => setValidity({...validity, isMaxSalaryValid: formState.vacancySalary.max !== '' && numberReg.test(formState.vacancySalary.max)})}
                onChange={(event) => setFormState({ ...formState, vacancySalary:{ ...formState.vacancySalary, max: event.currentTarget.value }})}
              />
              <select
                className={validity.isMaxSalaryValid ? "product-input right" : "product-input invalid right"}
                name="currency"
                value={formState.vacancySalary.currency}
                onChange={(event) => setFormState({ ...formState, vacancySalary:{ ...formState.vacancySalary, currency: event.currentTarget.value }})}
              >
                <option value="BYN">BYN</option>
                <option value="RUB">RUB</option>
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
              </select>
            </div>
          </div>
          { validity.isMinSalaryValid && validity.isMaxSalaryValid ? null : <p className="form-invalid-indicator">Укажите правильную зарплату!</p> }
        </div>
      </div>

      <div className="payload-container">

        <div className="input-container">
          <label htmlFor="experience">Опыт</label>
          <select
            className="product-input"
            name="experience"
            value={formState.experience}
            onChange={(event) => setFormState({ ...formState,experience: event.currentTarget.value })}
          >
            <option value="Без опыта">Без опыта</option>
            <option value="До 2 лет">До 2 лет</option>
            <option value="От 2 до 5 лет">От 2 до 5 лет</option>
            <option value="Более 5 лет">Более 5 лет</option>
          </select>
        </div>

        <div className="input-container">
          <label htmlFor="work">Характер работы</label>
          <select
            className="product-input"
            name="work"
            value={formState.work}
            onChange={(event) => setFormState({ ...formState, work: event.currentTarget.value })}
          >
            <option value="На территории работодателя">На территории работодателя</option>
            <option value="Разъездной">Разъездной</option>
          </select>
        </div>

        <div className="input-container">
          <label htmlFor="schedule">График работы</label>
          <select
            className="product-input"
            name="schedule"
            value={formState.schedule}
            onChange={(event) => setFormState({ ...formState, schedule: event.currentTarget.value })}
          >
            <option value="Фиксированный">Фиксированный</option>
            <option value="Сменный">Сменный</option>
            <option value="Вахтовый метод">Вахтовый метод</option>
          </select>
        </div>

        <div className="input-container">
          <label htmlFor="employment">Занятость</label>
          <select
            className="product-input"
            name="employment"
            value={formState.employment}
            onChange={(event) => setFormState({ ...formState, employment: event.currentTarget.value })}
          >
            <option value="Полная">Полная</option>
            <option value="Частичная">Частичная</option>
            <option value="Разовая">Разовая</option>
          </select>
        </div>
      </div>

      <div className="payload-container">
        <div className="input-container textarea">
          <label htmlFor="full">Описание*</label>
          <textarea
            className={validity.isVacancyDescriptionValid ? "product-input" : "product-input invalid"}
            name="full"
            value={formState.vacancyDescription}
            onChange={(event) => setFormState({ ...formState, vacancyDescription: event.currentTarget.value })}
            onBlur={() => setValidity({...validity, isVacancyDescriptionValid: formState.vacancyDescription !== ''})}
            rows={10}
            cols={45}
          ></textarea>
          { validity.isVacancyDescriptionValid ? null : <p className="form-invalid-indicator">Необходимо описание!</p> }
        </div>
      </div>

      <div className="submit-container">
        <button
          type="button"
          className="action-button"
          onClick={checkValidity}
        >
          { payload ? 'Редактировать данные' : 'Разместить вакансию' } 
        </button>
      </div>
    </form>
  );
}

export default CompanyRegistrationForm;
