import React, { Component } from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import CompanyVacanciesList from '../../components/company/company-detail/CompanyVacanciesList';
import VacancyDetail from '../vacancies/VacancyDetail';
import setRequestUrl from '../../common/utilits/setRequestUrl';
import setRequestParams from '../../common/utilits/setRequestParams';
import sendRequest from '../../common/utilits/sendRequest';
import { IVacancyItem, IStoreState } from '../../common/types';
import EditVacancy from '../dashboard/EditVacancy';
import DeletingVacansy from '../../components/products/utils/DeletingVacansy';


interface IComponentState {
  isLoaded: boolean,
  errMsg?: string,
  vacanciesList: Array<IVacancyItem>,
  sortedBy: 'date' | 'price',
  order: 'DESC' | 'ASC',
  page: number,
  perPage: number,
  totalPages: number,
}

interface IComponentProps extends RouteComponentProps {
  user: IStoreState['user'],
}

class CompanyVacancies extends Component<IComponentProps, IComponentState> {

  readonly state: IComponentState = {
    isLoaded: false,
    vacanciesList: [],
    sortedBy: 'date',
    order: 'DESC',
    page: 1,
    perPage: 10,
    totalPages: 1
  };

  async getPosts(pageId: number = 1) {
    const { user: { token, company } } = this.props;
    const { perPage, sortedBy, order } = this.state;

    const requestURL = company
      ? setRequestUrl('wp-json/api/v1/vacancy/', 'list', `size=${perPage}&page=${pageId}&company_id=${company}&orderby=${sortedBy}&order=${order}`)
      : setRequestUrl('wp-json/api/v1/vacancy/', 'list', `size=${perPage}&page=${pageId}&orderby=${sortedBy}&order=${order}`);
    const requestParams = setRequestParams('GET', token);

    this.setState({ isLoaded: false });

    const content = await sendRequest(requestURL, requestParams);
    if (content.errMsg) {
      this.setState({
        errMsg: content.errMsg,
        isLoaded: true
      });
    } else {
      this.setState({
        vacanciesList: content.data,
        page: content.page,
        perPage: content.perPage,
        totalPages: content.totalPages,
        isLoaded: true
      });
    }
  }

  getPage(pageNumber: number) {
    this.getPosts(pageNumber);
  }

  render() {
    const { user } = this.props;
    const isAuthorized = user ? true : false;

    if (this.state.errMsg)
      return (<div>{this.state.errMsg}</div>);

    return (
      <Router className="products-wrapper">
        <CompanyVacanciesList
          path='/:vacancyId'
          vacancyId=':vacancyId'
          companyId={`${user.company}`}
        />
        <VacancyDetail
          path='/:page/:vacancyId'
          vacancyId=':vacancyId'
          isAuthorized={isAuthorized}
          user={user}
        />

        <EditVacancy
          path='/:page/:vacancyId/edit'
          vacancyId=':vacancyId'
          user={user}
          fromCompanyList={true}
        />
        <DeletingVacansy
          path='/:page/:vacancyId/delete'
          vacancyId=':vacancyId'
          user={user}
        />
      </Router>
    );
  }
}

export default CompanyVacancies;
