import React, { Component } from 'react';
import { RouteComponentProps, Link } from '@reach/router';
import Loading from '../../common/loading';
import JoinedSuccessfuly from '../../components/company/utils/JoinedSuccessfuly';
import setRequestUrl from '../../common/utilits/setRequestUrl';
import setRequestParams from '../../common/utilits/setRequestParams';
import sendRequest from '../../common/utilits/sendRequest';
import { ICompanyItem } from '../../common/types';

interface IComponentProps extends RouteComponentProps {
    updateUserCompany: () => void,
    token: string
}

interface IComponentState {
    companiesList?: Array<ICompanyItem>,
    currentCompanyId: number,
    errMsg: string,
    isLoading: boolean,
    isJoined: boolean
}

class CompanyJoinPage extends Component<IComponentProps, IComponentState> {

    readonly state: IComponentState = {
        isLoading: false,
        isJoined: false,
        errMsg: '',
        currentCompanyId: 0
    }

    handleSelectChange(event: React.ChangeEvent<HTMLSelectElement>) {
        const value = event.currentTarget.value;
        this.setState({
            errMsg: '',
            currentCompanyId: parseInt(value, 10)
        })
    }

    async getCompaniesList() {
        const { token } = this.props;

        const requestURL = setRequestUrl('wp-json/api/v1/company/', 'list', `size=100&page=1`);
        const requestParams = setRequestParams('GET', token);

        this.setState({ isLoading: true });

        const content = await sendRequest(requestURL, requestParams);
        this.setState({ companiesList: content.data, isLoading: false })
    }

    async joinCompany() {

        const { token } = this.props;
        const { currentCompanyId } = this.state;

        if (currentCompanyId === 0) {
            this.setState({ errMsg: 'empty company' });
            return
        }

        const requestURL = setRequestUrl('wp-json/api/v1/company/', 'user/add');
        const requestParams = setRequestParams('POST', token, { companyId: currentCompanyId });

        this.setState({ isLoading: true });

        const content = await sendRequest(requestURL, requestParams);
        if (content) this.setState({ isJoined: true, isLoading: false })
        this.props.updateUserCompany();
    }

    static composeSelectorOptions(companiesList: Array<ICompanyItem>) {
        return companiesList.map(item => <option key={item.title} value={item.id}>{item.title}</option>)
    }

    componentDidMount() {
        this.getCompaniesList();
    }

    render() {
        const { isLoading, isJoined, companiesList, currentCompanyId, errMsg } = this.state;

        if (isLoading)
            return (<Loading isActive={isLoading} />)

        if (isJoined)
            return (<JoinedSuccessfuly />)

        const selectorOptions = companiesList
            ? CompanyJoinPage.composeSelectorOptions(companiesList)
            : [];

        return (
            <div className="company-wrapper__join-company">
                <p>
                    Только сотрудники имеют право публиковать объявления от имени компании.
                    Выберите из списка компанию, в которой вы работаете.
        </p>
                <div className="action-container">
                    <select
                        className={errMsg ? "person-input invalid" : "person-input"}
                        value={currentCompanyId}
                        name="companiesList"
                        onChange={this.handleSelectChange.bind(this)}
                    >
                        <option value="0">Выберите компанию</option>
                        {selectorOptions}
                    </select>
                    <button
                        type="button"
                        className="action-button profile"
                        onClick={this.joinCompany.bind(this)}
                    >
                        Стать сотрудником
          </button>
                </div>
                {errMsg ?
                    <div className="invalid-select">
                        Выберите компанию!
          </div>
                    : null
                }
                <div className="company-missing">
                    Если вашей компании нет в списке, вы можете ее <Link to="../company-registration">зарегистрировать</Link>
                </div>
            </div>
        );
    }
}

export default CompanyJoinPage
