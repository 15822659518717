import React, { Component } from 'react';
import { RouteComponentProps } from '@reach/router';
import { IAdvertFilter, IAdvertCategories } from '../../common/types';
import closeIcon from '../../assets/icons/close-icon.png';
import setRequestUrl from '../../common/utilits/setRequestUrl';
import setRequestParams from '../../common/utilits/setRequestParams';
import sendRequest from '../../common/utilits/sendRequest';
import '../../assets/styles/products/filter.scss';

interface IComponentProps {
  location: RouteComponentProps['location'],
  sendFilterParams: (filterParams:Array<string>) => any,
  setIsFilterOpen: () => void,
  isFilterOpen: boolean,
  token: string | undefined
}

interface IComponentState {
  payload: IAdvertFilter,
  advertCategories: IAdvertCategories
}

class ProductsFilter extends Component<IComponentProps, IComponentState> {
  readonly state:IComponentState = {
    payload: {
      categories: [],
      vendor: '',
      model: '',
      country: '',
      city: '',
      yearMin: '',
      yearMax: '',
      priceMin: '',
      priceMax: '',
      hoursMin: '',
      hoursMax: ''
    },
    advertCategories: {
      category: [{id: 1, name: '', count: 1}],
      vendor: [{id: 1, name: '', count: 1, models: [{id: 1, name: '', count: 1, models:[]}]}]
    }
  };

  handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const value: string = event.currentTarget.value;
    const fieldName: string = event.currentTarget.name;
    this.setState({ payload: { ...this.state.payload, [fieldName]: value } });
  }

  clearModel(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const value = event.currentTarget.value;
    this.setState({ 
      payload: { 
        ...this.state.payload, 
        vendor: value,
        model: ''
      } 
    });
  };

  clearCity(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.currentTarget.value;
    this.setState({ 
      payload: { 
        ...this.state.payload, 
        country: value,
        city: ''
      } 
    });
  };

  handleCheckbox(event: React.ChangeEvent<HTMLInputElement>) {
    const value: boolean = event.currentTarget.checked;
    const fieldName: string = event.currentTarget.name;
    if (value) {
      this.setState({
        payload: {
          ...this.state.payload,
          categories: [...this.state.payload.categories, fieldName],
        }
      });
    } else {
      this.setState({
        payload: {
          ...this.state.payload,
          categories: this.state.payload.categories.filter((item:string) => item !== fieldName),
        }
      });
    }
  }
 
  async getAdvertCategories() {
    const requestURL = setRequestUrl('wp-json/api/v1/post/', 'formSubset');
    const requestParams = setRequestParams('GET');
    const content = await sendRequest(requestURL, requestParams);
    this.setState({ advertCategories: content });
  }

  setParamsPrefix() {
    this.props.setIsFilterOpen();
    window.scrollTo(0, 0);
    const { sendFilterParams } = this.props;
    const { categories } = this.state.payload;
    const validatedState = {
      vendor: this.state.payload.vendor,
      model: this.state.payload.model !== '' && this.state.payload.vendor === '' ? '' : this.state.payload.model,
      country: this.state.payload.country,
      city: this.state.payload.city !== '' && this.state.payload.country === '' ? '' : this.state.payload.city,
      yearMin: this.state.payload.yearMax !== '' && this.state.payload.yearMin === '' ? '1960' : this.state.payload.yearMin,
      yearMax: this.state.payload.yearMin !== '' && this.state.payload.yearMax === '' ? '2099' : this.state.payload.yearMax,
      priceMin: this.state.payload.priceMax !== '' && this.state.payload.priceMin === '' ? '1' : this.state.payload.priceMin,
      priceMax: this.state.payload.priceMin !== '' && this.state.payload.priceMax === '' ? '100000000000000000' : this.state.payload.priceMax,
      hoursMin: this.state.payload.hoursMax !== '' && this.state.payload.hoursMin === '' ? '0' : this.state.payload.hoursMin,
      hoursMax: this.state.payload.hoursMin !== '' && this.state.payload.hoursMax === '' ? '100000000000000000' : this.state.payload.hoursMax
    }
    categories.forEach((item, index) => {
      const key = `category${index + 1}`;
      //@ts-ignore
      validatedState[key] = item;
    })
    const prefixedState = {
      vendor: `&filter[vendor][]=${validatedState.vendor}`,
      model: `&filter[model][]=${validatedState.model}`,
      country: `&filter[location][country]=${validatedState.country}`,
      city: `&filter[location][city]=${validatedState.city}`,
      yearMin: `&filter[year][from]=${validatedState.yearMin}`,
      yearMax: `&filter[year][to]=${validatedState.yearMax}`,
      priceMin: `&filter[price][from]=${validatedState.priceMin}`,
      priceMax: `&filter[price][to]=${validatedState.priceMax}`,
      hoursMin: `&filter[hours][from]=${validatedState.hoursMin}`,
      hoursMax: `&filter[hours][to]=${validatedState.hoursMax}`
    }
    categories.forEach((item, index) => {
      const key = `category${index + 1}`;
      //@ts-ignore
      prefixedState[key] = `&filter[category][]=${item}`;
    })
    const searchParams: any[any] = [];
    Object.keys(validatedState).forEach(key => {
      //@ts-ignore
      if(validatedState[key].length > 0) {
        //@ts-ignore
        searchParams.push(prefixedState[key]);
      } 
    })
    sendFilterParams(searchParams);
  }

  createAdvertCategories() {
    const { advertCategories, payload: { categories } } = this.state;
    if(!advertCategories.category) return;
    const categoryFilter = advertCategories.category.map((item:IAdvertCategories['category'][0]) => (
      <label
        className="checkbox-container"
        htmlFor={item.name}
        key={item.id}
      >
        <input
          name={item.name}
          checked={categories.find((element:string) => element === item.name) ? true : false}
          onChange={this.handleCheckbox.bind(this)}
          type="checkbox"
          className="checkbox"
        />
        <p>{item.name}</p>
      </label>
    ));
    const categoriesBlock = 
      <div className="categories">
        <h4>Тип</h4>
        {categoryFilter}
      </div>
    return categoriesBlock;
  }

  showVendorsList() {
    const { advertCategories: { vendor } } = this.state;
    if(!vendor) return;
    const options = vendor.map((item) => (
      <option key={item.id} value={item.name}>{item.name}</option>
    ));
    options.unshift(<option key={0} value={''}></option>)
    return options
  }

  showModelsList() {
    const { advertCategories, payload: { vendor } } = this.state;
    if(!advertCategories.vendor) return;
    const matchedVendors: any[] = advertCategories.vendor.filter((item) => item.name === vendor);
    const models = matchedVendors.length > 0 ? matchedVendors[0].models : undefined;
    if(!models) return;
    const options = models.map((item:any) => (
      <option key={item.id} value={item.name}/>
    ));
    const datalist = 
      <datalist id="models">
        {options}
      </datalist>
    return datalist
  }

  componentDidMount() {
    this.getAdvertCategories();
  }

  componentWillReceiveProps() {
    this.getAdvertCategories();
  }

  static checkVisibilityForLocation(location: RouteComponentProps['location']) {
    if (location) {
      if(location.pathname.split('/').length === 3) return true;
      else return false;
    }
    return false;
  }

  render() {
    const { location, setIsFilterOpen, isFilterOpen } = this.props;
    const isVisible = ProductsFilter.checkVisibilityForLocation(location);
    return (
      <form 
        className="filter"
        style={{ 
          display: isVisible ? undefined : 'none',
          left: isFilterOpen ? '0' : undefined,
        }}
      >
        <h3>Фильтр</h3>
        <img
          className="close-button"
          src={closeIcon}
          alt="close filter"
          onClick={setIsFilterOpen}
        />
        <div style={{ borderBottom: '1px solid #e5e5e5', marginBottom: '1rem' }}>
          {this.createAdvertCategories()}

          <label htmlFor="vendor">Производитель</label>
          <select
            className="filter-item"
            name="vendor"
            value={this.state.payload.vendor}
            onChange={(event) => {
                if(event.currentTarget.value === '') this.clearModel.call(this, event);
                else this.handleChange.call(this, event);
              }
            }
          >
            {this.showVendorsList()}
          </select>

          <label htmlFor="model">Модель</label>
          <input
            className={this.state.payload.vendor === '' ? "filter-item disabled" : "filter-item"}
            type="text"
            name="model"
            list="models"
            value={this.state.payload.model}
            onChange={this.handleChange.bind(this)}
            disabled={this.state.payload.vendor === ''}
          />
          {this.showModelsList()}

        </div>
        <div style={{ borderBottom: '1px solid #e5e5e5', marginBottom: '1rem' }}>
          <label htmlFor="country">Страна нахождения</label>
          <input
            className="filter-item"
            type="text"
            name="country"
            value={this.state.payload.country}
            onChange={(event) => {
                if(event.currentTarget.value === '') this.clearCity.call(this, event);
                else this.handleChange.call(this, event);
              }
            }
          />

          <label htmlFor="city"><b>Город</b></label>
          <input
            className={this.state.payload.country === '' ? "filter-item disabled" : "filter-item"}
            type="text"
            name="city"
            value={this.state.payload.city}
            onChange={this.handleChange.bind(this)}
            disabled={this.state.payload.country ===''}
          />

        </div>
        <div>

          <label>Цена, BYN</label>
          <div className="input-horizontal-block" >
            <input
              className="filter-item left"
              type="text"
              name="priceMin"
              placeholder="От"
              value={this.state.payload.priceMin}
              onChange={this.handleChange.bind(this)}
            />
            <input
              className="filter-item right"
              type="text"
              name="priceMax"
              placeholder="До"
              value={this.state.payload.priceMax}
              onChange={this.handleChange.bind(this)}
            />
          </div>

          <label>Наработка моточасов</label>
          <div className="input-horizontal-block" >
            <input
              className="filter-item left"
              type="text"
              name="hoursMin"
              placeholder="От"
              value={this.state.payload.hoursMin}
              onChange={this.handleChange.bind(this)}
            />
            <input
              className="filter-item right"
              type="text"
              name="hoursMax"
              placeholder="До"
              value={this.state.payload.hoursMax}
              onChange={this.handleChange.bind(this)}
            />
          </div>

          <label>Год выпуска</label>
          <div className="input-horizontal-block" >
            <input
              className="filter-item left"
              type="text"
              name="yearMin"
              placeholder="От"
              value={this.state.payload.yearMin}
              onChange={this.handleChange.bind(this)}
            />
            <input
              className="filter-item right"
              type="text"
              name="yearMax"
              placeholder="До"
              value={this.state.payload.yearMax}
              onChange={this.handleChange.bind(this)}
            />
          </div>

          <button
            type="button"
            className="action-button"
            onClick={this.setParamsPrefix.bind(this)}
          >
            Показать результаты
          </button>
        </div>
      </form>
    )
  }
};

export default ProductsFilter;
