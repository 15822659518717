import React, { useState } from 'react';
import Modal from 'react-modal';
import setRequestParams from '../../../common/utilits/setRequestParams';
import setRequestUrl from '../../../common/utilits/setRequestUrl';
import sendRequest from '../../../common/utilits/sendRequest';
import acceptImage from '../../../assets/img/accept-round.png';
import confirmEmailNotification from '../../../assets/img/confirm-email-notification.png';
import unApproveIcon from "../../../assets/img/urgent-message.svg";
import approveIcon from "../../../assets/img/checkmark.svg";
import ReactTooltip from 'react-tooltip';

interface IComponentProps {
  token: string,
  showBanner: boolean;
  emailVerified: boolean;
}

const ConfirmEmailBanner: React.FC<IComponentProps> = ({ token, showBanner, emailVerified }) => {
  const [isSent, setIsSent] = useState(false);

  const requestConfirm = async () => {
    const requestURL = setRequestUrl('wp-json/api/v1/user/', 'requestEmailConfirm');
    const requestParams = setRequestParams('GET', token);
    const content = await sendRequest(requestURL, requestParams);

    if (content) setIsSent(true);
  }

  return (
    <React.Fragment>
      { showBanner ?
        <React.Fragment>
          <div className="confirm-banner-primary">
            <div className="img-container">
              <img src={confirmEmailNotification} alt="confirm email" />
            </div>
            <div className="text-container">
              На почту, которую вы указали, было отправлено письмо.
              Перейдите по ссылке из этого письма для подтверждения почты.
              Если письмо не пришло
            <button
                type="button"
                onClick={requestConfirm}
                className='request-confirm-button'
              >
                , нажмите сюда
            </button>
            , и мы вышлем вам его повторно.
          </div>
          </div >
        </React.Fragment> :
        <React.Fragment>
          <img
            src={emailVerified ? approveIcon : unApproveIcon}
            className={emailVerified ? "verified-status" : "invalid-verified-status"}
            data-tip=''
            data-for='email-verified-status'
            alt="Статус Email"
            onClick={requestConfirm}>
          </img>
          <ReactTooltip id="email-verified-status" type='dark' effect='solid'>{!emailVerified ? "Отправить письмо с кодом подтверждения" : "Почта подтверждена"}</ReactTooltip>
        </React.Fragment>
      }


      <Modal
        onAfterOpen={() => setTimeout(() => { setIsSent(false) }, 2000)}
        isOpen={isSent}
        overlayClassName="ReactModal__Overlay--notification"
        className="ReactModal__Content--notification"
      >
        <div className="content">
          <p style={{ color: 'green', margin: '0.5rem 0' }}>Сообщение отправлено на почту!</p>
          <img src={acceptImage} alt="accepted" />
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default ConfirmEmailBanner;
