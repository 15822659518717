import { createStore } from 'redux';
import mainReducer from './reducers/reducer';
import { loadState, saveState } from '../localstorage';

const persistedState = loadState();
const store = createStore(mainReducer, persistedState);

store.subscribe(() => { 
  const state = store.getState();
  //@ts-ignore
  saveState(state);
});

export default store;
