import React, { Component } from 'react';
import UserNavigation from '../../dashboard/UserNavigation';
import { IUser } from '../../../common/types';
import noAvatar from '../../../assets/img/avatar.png';
import arrow from '../../../assets/img/header-arrow.png';


interface IComponentProps {
  user: IUser
}

type LinkProperty = "none" | "auto";

class AuthorizedUser extends Component<IComponentProps> {

  constructor(props: IComponentProps) {
    super(props);
    this.setOpen = this.setOpen.bind(this);
    this.setClosed = this.setClosed.bind(this);
    this.closeMenuCallback = this.closeMenuCallback.bind(this);
  }

  closeMenuCallback(event: Event) {
    const path = event.composedPath().slice(0, -3);
    const menu = document.getElementById('user-pop-up');
    if (!(menu && path.includes(menu))) {
      AuthorizedUser.toggleLinks("auto");
      this.setClosed();
      document.removeEventListener("click", this.closeMenuCallback);
    }
  }

  setOpen() {
    const popUp = document.getElementById('user-pop-up');
    if (popUp) {
      if (popUp.classList.value.includes('is-open')) {
        AuthorizedUser.toggleLinks("auto");
        document.removeEventListener("click", this.closeMenuCallback);
      } else {
        AuthorizedUser.toggleLinks("none");
        document.addEventListener("click", this.closeMenuCallback);
      }
      popUp.classList.toggle('is-open');
    }
  };

  setClosed() {
    const popUp = document.getElementById('user-pop-up');
    if (popUp) popUp.classList.remove('is-open');
    AuthorizedUser.toggleLinks("auto");
    document.removeEventListener("click", this.closeMenuCallback);
  };

  static toggleLinks(property: LinkProperty) {
    const productLinks:any = document.getElementsByClassName('product');
    for (let item of productLinks) {
      item.style.pointerEvents = property;
    }
  }

  render() {
    const { user } = this.props;
    const { user_avatar, user_details } = user;
    return (
      <div className="auth-user">
        <div className="user-container">
          <div className="name-container">
            <p>{user_details.first_name}</p>
            <p>{user_details.last_name}</p>
          </div>
          <div className="img-container" onClick={this.setOpen}>
            <img src={user_avatar !== '' && user_avatar ? user_avatar : noAvatar} alt="avatar" className="avatar"/>
            <img src={arrow} alt="arrow" className="arrow"/>
          </div>
          <div className="pop-up" id="user-pop-up" onClick={this.setClosed}>
            <UserNavigation />
          </div>
        </div>
      </div>
    )
  }
};

export default AuthorizedUser;
