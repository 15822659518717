import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Router } from '@reach/router';
import CompaniesList from '../../containers/CompaniesList';
import CompanyDetail from './CompanyDetail';
import sendRequest from '../../common/utilits/sendRequest';
import setRequestParams from '../../common/utilits/setRequestParams';
import setRequestUrl from '../../common/utilits/setRequestUrl';
import { IStoreState, ICompanyItem } from '../../common/types';
import Helmet from 'react-helmet';
import { ym } from 'common/utilits/yandex-metrix';

interface IComponentProps extends RouteComponentProps {
  user: IStoreState['user'],
}

interface IComponentState {
  page: number,
  perPage: number,
  totalPages: number,
  companiesList: Array<ICompanyItem>,
  apiURL: string,
  filterParams: Array<string>,
  isLoaded: boolean,
  isFilterOpen: boolean
}

class CompanyListPage extends Component<IComponentProps, IComponentState> {

  readonly state: IComponentState = {
    isLoaded: false,
    isFilterOpen: false,
    companiesList: [],
    page: 1,
    perPage: 10,
    totalPages: 1,
    apiURL: `${process.env.REACT_APP_API_URL}wp-json/api/v1/company/list?size=10`,
    filterParams: []
  };

  setIsFilterOpen() {
    this.setState((prevState) => ({ isFilterOpen: !prevState.isFilterOpen }));
  }

  // setFilterParams(filterParams:Array<string>, pageId:number = 1):string {
  //   const { apiURL } = this.state;
  //   const concatedFilterParams:string = filterParams[0] === 'cache'
  //     ? this.state.filterParams.reduce((acc, value) => acc.concat(value), `&page=${pageId}`)
  //     : filterParams.reduce((acc, value) => acc.concat(value), `&page=${pageId}`);
  //   const requestURL = apiURL.concat(concatedFilterParams)
  //   if (filterParams[0] !== 'cache') this.setState({ filterParams });
  //   return requestURL;
  // }

  async getPosts(filterParams: Array<string> = [], pageId: number = 1, method: string = 'GET') {
    const requestURL = setRequestUrl('wp-json/api/v1/company/', 'list', `size=10&page=${pageId}`);
    const requestParams = setRequestParams(method);
    this.setState({ isLoaded: false });

    const content = await sendRequest(requestURL, requestParams);
    this.setState({
      companiesList: content.data,
      page: content.page,
      perPage: content.perPage,
      totalPages: content.totalPages,
      isLoaded: true
    });
  }

  getPage(pageNumber: number) {
    this.getPosts(['cache'], pageNumber);
  }

  render() {
    const { user } = this.props;
    const { isLoaded } = this.state;
    const isAuthorized = user ? true : false;
    //const pathname = location ? location.pathname : '';
    return (
      <div className="main-wrapper">
        <Helmet
          script={[{
            type: 'text/javascript',
            innerHTML: ym()
          }]} >
          <title>Ударник | Компании</title>
          <meta
            name="description"
            content="Компании предоставляющее услуги по продаже и аренде лесозаготовительной сельхоз технике."
          />
        </Helmet>
        {/* {isLoaded && pathname.split('/').length === 3 ?
        <div className="mobile-filter-button-container" onClick={this.setIsFilterOpen.bind(this)}>
          <p>Фильтры</p>
          <img
            src={mobileFilterButton}
            alt="filter button"
          />
        </div>
      : null} */}
        {/* <CompaniesFilter
        location={location}
        sendFilterParams={this.getPosts.bind(this)}
        setIsFilterOpen={this.setIsFilterOpen.bind(this)}
        isFilterOpen={isFilterOpen}
        token={token}
      /> */}
        <Router style={{ width: '100%' }}>
          <CompaniesList
            path='/:page'
            page=':page'
            companiesList={this.state.companiesList}
            setParentState={this.setState.bind(this)}
            user={user}
            filterParams={this.state.filterParams}
            totalPages={this.state.totalPages}
            getPosts={this.getPosts.bind(this)}
            getPage={this.getPage.bind(this)}
            isLoaded={isLoaded}
          />
          <CompanyDetail
            path='/:page/:companyId/*'
            companyId=':companyId'
            isAuthorized={isAuthorized}
            user={user}
          />
        </Router>
      </div>
    )
  }
}

const mapStateToProps = (state: IStoreState) => ({
  user: state.user,
});

export default connect(mapStateToProps)(CompanyListPage);
