import React, { Component } from "react";
import { RouteComponentProps, Router, Redirect } from "@reach/router";
import NoCompanyPage from "./NoCompanyPage";
import UserPendingPage from "./UserPendingPage";
import CompanyRegistrationPage from "./CompanyRegistrationPage";
import CompanyEditPage from "./CompanyEditPage";
import CompanyDashboardPage from "./CompanyDashboardPage";
import CompanyJoinPage from "./CompanyJoinPage";
import Loading from "../../common/loading";
import ErrorComponent from "../../components/company/utils/ErrorComponent";
import { IStoreState, ICompanyItem, IUser } from "../../common/types";
import setRequestUrl from "../../common/utilits/setRequestUrl";
import setRequestParams from "../../common/utilits/setRequestParams";
import sendRequest from "../../common/utilits/sendRequest";

import CancelSuccessfuly from "../../components/company/utils/cancelSuccessfuly";
import Canceling from "./utils/Canceling";

interface IComponentProps extends RouteComponentProps {
  user: IStoreState["user"];
  getUser: (userDetails: IUser) => any;
}

interface IComponentState {
  companyData?: ICompanyItem;
  errMsg?: string;
  isLoading: boolean;
  isCancelingPopup: boolean;
  isCanceling: boolean;
  isUserJoinedCompany: boolean;
  isUserPending: boolean;
}

class CompanyMainPage extends Component<IComponentProps> {
  readonly state: IComponentState = {
    isLoading: true,
    isCancelingPopup: false,
    isCanceling: false,
    isUserJoinedCompany: false,
    isUserPending: false,
  };

  async acceptPendingEmployee(pendingEmployeeId: number) {
    const {
      user: { token, company },
    } = this.props;
    const payload = {
      userId: pendingEmployeeId,
      companyId: company,
    };

    const requestURL = setRequestUrl("wp-json/api/v1/company/", "user/verify");
    const requestParams = setRequestParams("POST", token, payload);

    this.setState({ isLoading: true });

    const content = await sendRequest(requestURL, requestParams);
    if (content.errMsg) {
      this.setState({ errMsg: content.errMsg, isLoading: false });
    } else {
      this.setState({
        companyData: content,
        isLoading: false,
        isUserJoinedCompany: !!content,
      });
    }
  }

  async updateUserCompany() {
    const {
      getUser,
      user: { id, token },
    } = this.props;

    const requestURL = setRequestUrl("wp-json/api/v1/user/", `${id}`);
    const requestParams = setRequestParams("GET", token);
    const res = await sendRequest(requestURL, requestParams);
    if (!res.errMsg) {
      res["token"] = token;
      getUser(res);
    }
  }

  togglePopupRequest() {
    const { isCancelingPopup } = this.state;
    this.setState(() => ({ isCancelingPopup: !isCancelingPopup }));
  }

  async deleteRequestInCompany() {
    const { isCancelingPopup } = this.state;
    const {
      user: { token },
    } = this.props;

    const requestURL = setRequestUrl(
      "/wp-json/api/v1/company/user/",
      `withdraw`
    );
    const requestParams = setRequestParams("POST", token);
    const res = await sendRequest(requestURL, requestParams);

    if (res) {
      this.updateUserCompany();
      this.setState(() => ({
        isCancelingPopup: !isCancelingPopup,
        isCanceling: true,
      }));
    }
  }

  async removeEmployee(employeeId: number) {
    const {
      user: { token },
    } = this.props;
    const { companyData } = this.state;
    const payload = {
      userId: employeeId,
    };

    if (companyData && parseInt(companyData.admin, 10) === employeeId) {
      this.setState({ errMsg: "Невозможно удалить администратора компании!" });
      return;
    }

    const requestURL = setRequestUrl("wp-json/api/v1/company/", "user/remove");
    const requestParams = setRequestParams("POST", token, payload);

    this.setState({ isLoading: true });

    const content = await sendRequest(requestURL, requestParams);
    if (content.errMsg) {
      this.setState({ errMsg: content.errMsg, isLoading: false });
    } else {
      this.setState({
        companyData: content,
        isLoading: false,
        isUserJoinedCompany: !!content,
      });
    }
  }

  async getUserCompany() {
    const {
      user: { token, company },
    } = this.props;

    if (!company) {
      this.setState({
        companyData: undefined,
        isLoading: false,
        isUserJoinedCompany: false,
      });
      return;
    }

    const requestURL = setRequestUrl(
      "wp-json/api/v1/company/",
      "single",
      `id=${company}`
    );
    const requestParams = setRequestParams("GET", token);

    this.setState({ isLoading: true });

    const content = await sendRequest(requestURL, requestParams);

    if (!content.users) {
      this.setState({
        companyData: content,
        isLoading: false,
        isUserJoinedCompany: false,
        isUserPending: true,
      });
      return;
    } else {
      this.setState({
        companyData: content,
        isLoading: false,
        isUserJoinedCompany: true,
        isUserPending: false,
      });
    }
  }

  async userExit(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    const {
      user: { token },
    } = this.props;
    const requestURL = setRequestUrl("wp-json/api/v1/company/user/", "exit");
    const requestParams = setRequestParams("POST", token, {});
    await sendRequest(requestURL, requestParams);
    await this.updateUserCompany();
    await this.getUserCompany();
  }

  componentDidMount() {
    this.getUserCompany();
  }

  private checkUserState() {
    const { isUserPending, isUserJoinedCompany } = this.state;
    if (isUserPending)
      return (
        <Redirect from="/*" to="/dashboard/company/user-pending" noThrow />
      );

    if (!isUserJoinedCompany)
      return <Redirect from="/*" to="/dashboard/company/no-company" noThrow />;

    return null;
  }

  private static checkIsAdmin(
    userId: IStoreState["user"]["id"],
    companyData: IComponentState["companyData"]
  ) {
    if (companyData) return userId === parseInt(companyData.admin, 10);
    else return false;
  }

  render() {
    const {
      user: { token, company, id },
    } = this.props;
    const {
      isLoading,
      isCancelingPopup,
      isCanceling,
      errMsg,
      companyData,
      isUserJoinedCompany,
    } = this.state;
    const companyTitle = this.state.companyData
      ? this.state.companyData.title
      : "";
    const isUserAdmin = CompanyMainPage.checkIsAdmin(id, companyData);

    if (isLoading) return <Loading isActive={isLoading} />;

    if (isCanceling) return <CancelSuccessfuly />;

    if (isCancelingPopup)
      return (
        <Canceling
          cancelRequest={this.togglePopupRequest.bind(this)}
          deleteRequestInCompany={this.deleteRequestInCompany.bind(this)}
        />
      );

    if (errMsg) return <ErrorComponent errMsg={errMsg} />;

    return (
      <Router className="company-wrapper" primary={false}>
        {isUserJoinedCompany ? (
          <CompanyDashboardPage
            path="/*"
            isUserAdmin={isUserAdmin}
            companyData={this.state.companyData}
            acceptPendingEmployee={this.acceptPendingEmployee.bind(this)}
            removeEmployee={this.removeEmployee.bind(this)}
            userExit={this.userExit.bind(this)}
          />
        ) : null}
        <NoCompanyPage
          path="no-company"
          isUserJoinedCompany={isUserJoinedCompany}
        />
        <UserPendingPage
          path="user-pending"
          title={companyTitle}
          isUserJoinedCompany={isUserJoinedCompany}
          togglePopupRequest={this.togglePopupRequest.bind(this)}
        />
        <CompanyRegistrationPage path="company-registration" token={token} />
        {isUserJoinedCompany ? (
          <CompanyEditPage
            path="company-edit"
            token={token}
            companyId={company}
          />
        ) : null}
        <CompanyJoinPage
          path="company-join"
          token={token}
          updateUserCompany={this.updateUserCompany.bind(this)}
        />
        {this.checkUserState()}
      </Router>
    );
  }
}

export default CompanyMainPage;
