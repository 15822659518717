import React from 'react';
import { Link } from '@reach/router';
import formatDate from '../../common/utilits/formatDate';
import getConditionState from '../../common/utilits/getConditionState';
import placeMarkerIcon from '../../assets/icons/place-marker-icon.png';
import settingsIcon from '../../assets/icons/settings-icon.png';
import noPhoto from '../../assets/img/nophoto.jpg';
import getPriceToDisplay from '../../common/utilits/getPriceToDisplay';
import setCalculatedPrice from '../../common/utilits/setCalculatedPrice';
import { IAdvertItem, IExchangeRates } from '../../common/types';
import Control, { TControlText } from '../ItemControl';

interface IComponentProps {
  key: number,
  advert: IAdvertItem,
  fromCompany?: boolean,
  displayPrice: 'BYN' | 'RUB' | 'USD' | 'EUR',
  exchangeRates?: IExchangeRates,
  deleteAdvert: (id: number) => void,
  publishAdvert: (id: number) => void,
  draftAdvert: (id: number) => void
}

function getMainImage(images: IAdvertItem['images']) {
  for (let item of images) {
    if (item.main) return item;
  }
}

const ProductItem: React.FC<IComponentProps> = (props) => {
  const text: TControlText = {
    deleteTxt: 'Вы уверены, что хотите удалить объявление? Сейчас оно неактивно и видно только вам. Если вы удалите, то уже не сможете его восстановить.',
    deleteBtn: 'Удалить объявление',
    draftTxt: 'Объявление не будет показываться в общем списке, но останется в личном кабинете, так что вы всегда сможете его восстановить.',
    draftBtn: 'Скрыть объявление'
  }
  const {
    deleteAdvert,
    publishAdvert,
    draftAdvert,
    advert: {
      id,
      full,
      year,
      images,
      location: {
        city,
        country
      },
      price,
      post_status,
      sn,
      condition,
      model_vendor,
      category,
      hours,
      author,
      published
    },
    displayPrice,
    exchangeRates,
    fromCompany
  } = props;
  const [model, vendor] = model_vendor;
  const mainImage = getMainImage(images);
  const conditionState = getConditionState(condition);
  const datePublished = formatDate(published);
  const priceToDisplay = exchangeRates
    ? getPriceToDisplay(displayPrice, setCalculatedPrice('BYN', price.byn, exchangeRates), price.optional_price)
    : 'Подсчет...';
  return (
    <div className="product">
      <Link to={fromCompany ? `/posts/1/${id}` : `./${id}`}>
        <div className="product-image">
          {images.length > 0
            ? <img src={mainImage ? mainImage.url : images[0].url} alt="product" />
            : <img src={noPhoto} alt="product" />
          }
        </div>
      </Link>
      <div className="product-text-content">
        <Control post_status={post_status} id={id}
          onDelete={deleteAdvert}
          onPublish={publishAdvert}
          onDraft={draftAdvert}
          text={text}
        />
        <Link to={fromCompany ? `/posts/1/${id}` : `./${id}`}>
          <div className="product-header">
            <div className="labels">
              <h3> {vendor} {model} </h3>
              {price.exchange ? <p className="exchange">Обмен</p> : null}
              {post_status === 'pending' ? <p className="pending">На модерации</p> : null}
              {post_status === 'draft' ? <p className="draft">Неактивно</p> : null}
            </div>
            {post_status ? null : <p className="date-published">{datePublished}</p>}
          </div>
          <h4>{category}, {year} г., {sn}</h4>
          <div className="short-description-wrapper">
            <p className="short-description">{full}</p>
            {author.hasOwnProperty('user_details') && author.hasOwnProperty('user_avatar')
              ? null
              : <div className="company-logo-container product-item">
                <img src={author.company_logo} alt="company logo" />
              </div>
            }
          </div>
          <div className="product-footer">
            <div className="state-container">
              {country || city ?
                <div className="location">
                  <img src={placeMarkerIcon} alt="place marker" />
                  <p>{country ? `${country},` : null} {city}</p>
                </div>
                : null}
              <div className="working-hours">
                <img src={settingsIcon} alt="settings icon" />
                <p>{hours.length === 0 ? 0 : hours} ч.</p>
              </div>
              {conditionState ?
                <div className="condition">
                  <img src={conditionState.image} alt="stars" />
                </div>
                : null}
            </div>
            <div className="price-container">
              <p>{priceToDisplay}</p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ProductItem;
