import React from "react";
import { Link } from "@reach/router";
import { useDispatch, connect } from "react-redux";
import { alertPhoneVerified, exitUser } from "../../../redux/actions/actions";
import personIcon from "../../../assets/icons/person-icon-dashboard.png";
import exitIcon from "../../../assets/icons/exit-icon.png";
import advertsIcon from "../../../assets/icons/posts-icon.png";
import companyIcon from "../../../assets/icons/company-icon.png";
import vacanciesIcon from "../../../assets/icons/vacancies-icon.svg";
import { DisabledPhoneRoute, IStoreState } from "../../../common/types";
import store from "redux/store";

interface IComponentProps {
  user: IStoreState["user"];
}

const NavigationMenu: React.FC<IComponentProps> = ({ user }) => {
  const dispatch = useDispatch();
  const handleExit = () => dispatch(exitUser());
  return (
    <nav className="nav-menu">
      <Link to="/dashboard/" className="nav-item">
        <img src={personIcon} alt="person icon" />
        Личные данные
      </Link>
      <Link to="/dashboard/company" className="nav-item">
        <img src={companyIcon} alt="company icon" />
        Данные компании
      </Link>
      <Link to="/dashboard/ads/1" className="nav-item">
        <img src={advertsIcon} alt="posts icon" />
        Мои объявления
      </Link>
      {user && user.company ? (
        <Link to="/dashboard/company-vacancies/1" className="nav-item">
          <img src={vacanciesIcon} alt="vacancies icon" />
          Вакансии
        </Link>
      ) : null}
      <Link to="/posts/1" className="nav-item exit" onClick={handleExit}>
        <img src={exitIcon} alt="exit icon" />
        Выйти
      </Link>

      <button className="action-button link" style={{ maxWidth: '203px' }} onClick={() => { store.dispatch(alertPhoneVerified(DisabledPhoneRoute.CreateAdvert)) }}>
        Подать объявление
      </button>

      {user && user.company ? (
        <button
          onClick={() => { store.dispatch(alertPhoneVerified(DisabledPhoneRoute.CreateVacancy)) }}
          className="action-button profile light link"
          style={{ marginTop: "0.5rem", maxWidth: "200px" }}
        >
          Разместить вакансию
        </button>
      ) : null}
    </nav>
  );
};

const mapStateToProps = (state: IStoreState) => ({
  user: state.user,
});

export default connect(mapStateToProps)(NavigationMenu);
