import React, { Component, createRef } from 'react';
import { RouteComponentProps } from '@reach/router';
import Loading from '../../common/loading';
import NotFound from '../../common/NotFound';
import getBase64Image from '../../common/utilits/getBase64Image';
import EmptyFieldsMessage from '../../components/dashboard/utils/EmptyFieldsMessage';
import AdvUpdatedMessage from '../../components/dashboard/utils/AdvUpdatedMessage';
import ErrorMessage from '../../components/dashboard/utils/ErrorMessage';
import AdvertForm from '../../components/dashboard/AdvertForm';
import setRequestUrl from '../../common/utilits/setRequestUrl';
import setRequestParams from '../../common/utilits/setRequestParams';
import sendRequest from '../../common/utilits/sendRequest';
import getExchangeRates from '../../common/utilits/getExchangeRates';
import { IAdvertPayload, IAdvertCategories, IStoreState, IExchangeRates } from '../../common/types';

interface IComponentState {
  payload: IAdvertPayload,
  errMsg: string,
  advertCategories: IAdvertCategories,
  exchangeRates: IExchangeRates,
  isEmptyFields: boolean,
  isLoading: boolean,
  isLoaded: boolean,
  isError: boolean,
};

interface IComponentProps extends RouteComponentProps {
  user: IStoreState['user'],
  productId: string
}

class EditAdvert extends Component<IComponentProps, any> {
  private inputImagesRef = createRef<HTMLInputElement>();

  readonly state: IComponentState = {
    payload: {
      title: '',
      short: '',
      full: '',
      year: '',
      images: [],
      price: {
        byn: '',
        currency: '',
        value: '',
        include_tax: false,
        exchange: false,
        optional_price: false
      },
      contacts: {
        phone: ''
      },
      category: '',
      vendor: '',
      model: '',
      proposed_vendor: '',
      proposed_model: '',
      location: {
        country: '',
        city: '',
      },
      hours: '',
      condition: '',
      sn: '',
      fromCompany: false,
      company_id: ''
    },
    exchangeRates: {
      usd: 2,
      eur: 2.3,
      rub: 0.039
    },
    errMsg: '',
    advertCategories: {
      category: [{ id: 1, name: '', count: 1 }],
      vendor: [{ id: 1, name: '', count: 1, models: [{ id: 1, name: '', count: 1, models: [] }] }]
    },
    isEmptyFields: false,
    isLoading: false,
    isLoaded: false,
    isError: false
  };

  handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) {
    const value: string = event.currentTarget.value;
    const fieldName: string = event.currentTarget.name;
    //@ts-ignore
    this.setState({ payload: { ...this.state.payload, [fieldName]: value } });
  }

  clearShortItem(event: React.ChangeEvent<HTMLSelectElement>) {
    const value = event.currentTarget.value === 'true';
    this.setState({
      payload: {
        ...this.state.payload,
        short: '',
        price: {
          ...this.state.payload.price,
          exchange: value
        }
      }
    });
  };

  handleRadioChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value: string = event.currentTarget.value;
    this.setState({
      payload: { ...this.state.payload, fromCompany: value === 'true' }
    })
  }

  handlePriceChange(event: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>) {
    let value: string | boolean = event.currentTarget.type === 'checkbox'
      ? event.currentTarget.checked
      : event.currentTarget.value
    const fieldName: string = event.currentTarget.name;
    value = fieldName === 'exchange' ? (value === 'true') : value;
    const price = {
      ...this.state.payload.price,
      [fieldName]: value
    }
    if (fieldName === "optional_price" && value === true) {
      price.value = "1";
    }
    this.setState({ payload: { ...this.state.payload, price } });
  }

  handleLocationChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value: string = event.currentTarget.value;
    const fieldName: string = event.currentTarget.name;
    const location = {
      ...this.state.payload.location,
      [fieldName]: value
    }
    this.setState({ payload: { ...this.state.payload, location } });
  }

  handleCategoryChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const category: string = event.currentTarget.value;
    this.setState({
      payload: { ...this.state.payload, category }
    });
  };

  handleImagesClick() {
    if (this.inputImagesRef.current) {
      this.inputImagesRef.current.click();
    }
  }

  handleImagesChange() {
    if (this.inputImagesRef.current && this.inputImagesRef.current.files) {
      const file = this.inputImagesRef.current.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const images = this.state.payload.images;
        const base64Encoded = reader.result;
        const imagesItem = {
          data: base64Encoded,
          main: images.length === 0 ? 1 : 0
        }
        images.push(imagesItem);
        this.setState({ images })
      }
      if (file) {
        if (file.size < 10485760) {
          reader.readAsDataURL(file);
        }
      }
    }
  }

  setMainImage(event: React.MouseEvent<HTMLDivElement>) {
    const { payload: { images } } = this.state;
    const id = event.currentTarget.id;
    const updatedImages = images.map((item: any, index: number) => (
      index === parseInt(id, 10) ? Object.assign(item, { main: 1 }) : Object.assign(item, { main: 0 })
    ));
    this.setState({ payload: { ...this.state.payload, images: updatedImages } });
  }

  deleteImage(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    const { payload: { images } } = this.state;
    const id: number = parseInt(event.currentTarget.id, 10);
    const updatedImages = images.slice();
    const deletedImage: { main: boolean }[] = updatedImages.splice(id, 1);
    if (deletedImage[0].main && updatedImages[0]) updatedImages[0].main = 1;
    this.setState({ payload: { ...this.state.payload, images: updatedImages } });
  }

  async updateAdvert(validatedPayload: IAdvertPayload) {
    const { productId, user: { token } } = this.props;

    const requestParams = setRequestParams('PATCH', token, validatedPayload);
    const requestUrl = setRequestUrl('wp-json/api/v1/post/', 'update', `id=${productId}`);

    this.setState({ isLoading: true });

    const content = await sendRequest(requestUrl, requestParams);

    if (content.errMsg) {
      this.setState({
        errMsg: content.errMsg,
        isEmptyFields: true,
        isLoaded: false,
        isLoading: false
      })
    } else {
      this.setState({ isLoaded: true, isLoading: false });
    }
  }

  async getAdvert(requestURL: string, requestParams: object) {
    const content = await sendRequest(requestURL, requestParams);
    if (content.errMsg) {
      this.setState({
        errMsg: content.errMsg,
        isError: true,
        isLoading: false
      });
    } else {
      const convertedImages = await getBase64Image(content.images);
      const [model, vendor] = content.model_vendor ? content.model_vendor : ['', ''];
      this.setState({
        payload: {
          ...content,
          model,
          vendor,
          category: content.category[0],
          images: convertedImages
        },
        isLoading: false
      });
    }
  }

  async getAdvertCategories() {
    const requestURL = setRequestUrl('wp-json/api/v1/post/', 'formSubset');
    const requestParams = setRequestParams('GET');
    const content = await sendRequest(requestURL, requestParams);
    if (content.errMsg) {
      this.setState({
        errMsg: content.errMsg,
        isEmptyFields: true,
        isLoaded: false,
        isLoading: false
      })
    } else {
      const isCategoriesValid = content.category && content.vendor && content.category[0] && content.vendor[0];
      if (isCategoriesValid) this.setState({ advertCategories: content });
    }
  }

  resetLogic() {
    this.setState({
      isEmptyFields: false,
      isLoading: false,
      isLoaded: false,
      isError: false,
    })
  }

  componentDidMount() {
    const { user, productId } = this.props;
    if (!user) return;
    let requestParams = setRequestParams('GET', user.token);
    let requestUrl = setRequestUrl('wp-json/api/v1/post/', 'single', `id=${productId}`);
    this.setState({ isLoading: true });
    this.getAdvert(requestUrl, requestParams);
    this.getAdvertCategories();
    getExchangeRates().then(exchangeRates => this.setState({ exchangeRates }));
  }

  render() {
    const {
      isEmptyFields,
      isLoading,
      isLoaded,
      isError
    } = this.state;
    const { user } = this.props;

    if (!user)
      return (<NotFound />)

    if (isLoading)
      return (<Loading isActive={isLoading} />)

    if (isEmptyFields)
      return (
        <EmptyFieldsMessage
          errMsg={this.state.errMsg}
          resetLogic={this.resetLogic.bind(this)}
        />
      );

    if (isLoaded)
      return (<AdvUpdatedMessage />);

    if (isError)
      return (<ErrorMessage errMsg={this.state.errMsg} />);

    const location = {
      title: 'Редактирование объявления',
      button: 'Сохранить изменения'
    }

    const advertFormProps = {
      location,
      payload: this.state.payload,
      advertCategories: this.state.advertCategories,
      exchangeRates: this.state.exchangeRates,
      handlers: {
        handleChange: this.handleChange.bind(this),
        clearShortItem: this.clearShortItem.bind(this),
        handleLocationChange: this.handleLocationChange.bind(this),
        handlePriceChange: this.handlePriceChange.bind(this),
        handleImagesClick: this.handleImagesClick.bind(this),
        handleImagesChange: this.handleImagesChange.bind(this),
        handleCategoryChange: this.handleCategoryChange.bind(this),
        handleRadioChange: this.handleRadioChange.bind(this),
        handleImportFromMascus: () => { }
      },
      deleteImage: this.deleteImage.bind(this),
      setMainImage: this.setMainImage.bind(this),
      sendAdvert: this.updateAdvert.bind(this),
      inputImagesRef: this.inputImagesRef,
      user: this.props.user,
      isEdit: true
    }

    return (
      <AdvertForm {...advertFormProps} />
    )
  }
}

export default EditAdvert;
