import React from "react";
import cellPhoneImage from "assets/img/cell-phone.png";
import { Link } from "@reach/router";

const ConfirmPhoneNumberBanner: React.FC = () => {
  return (
    <div className="confirm-banner-warning">
      <div className="img-container">
        <img src={cellPhoneImage} alt="confirm phone" />
      </div>
      <div className="text-container">
        Номер вашего телефона не подтвержден. Для размещения объявлений и
        регистрации компании необходима верефикация номера телефона с помощью
        SMS-кода. Для подтвержения
        <Link to="validate-phone-number" className="request-confirm-button">
          &nbsp;нажмите сюда&nbsp;
        </Link>
        и мы вышлем код подтвержения на ваш номер.
      </div>
    </div>
  );
};

export default ConfirmPhoneNumberBanner;
