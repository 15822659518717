import React, { Component } from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import { connect } from 'react-redux';
import { CompanyNav } from '../../components/company/dashboard/CompanyNav';
import CompanyProductsList from '../../components/company/company-detail/CompanyProductList';
import CompanyVacanciesList from '../../components/company/company-detail/CompanyVacanciesList';
import NotFound from '../../common/NotFound';
import Loading from '../../common/loading';
import CompanyDetailHeader from '../../components/company/company-detail/CompanyDetailHeader';
import CompanyLeftColumn from '../../components/company/company-detail/CompanyLeftColumn';
import setRequestUrl from '../../common/utilits/setRequestUrl';
import setRequestParams from '../../common/utilits/setRequestParams';
import sendRequest from '../../common/utilits/sendRequest';
import { IStoreState, ICompanyItem, TPreferredCurrency, IExchangeRates } from '../../common/types';
import '../../assets/styles/products/products-detail.scss';

interface IComponentProps extends RouteComponentProps {
  companyId: string,
  isAuthorized: boolean,
  preferredCurrency: TPreferredCurrency,
  user: IStoreState['user']
}

interface IComponentState {
  isLoaded: boolean,
  isDeleting: boolean,
  isDeleted: boolean,
  isNotFound: boolean,
  companyData?: ICompanyItem,
  exchangeRates?: IExchangeRates
}

class CompanyDetail extends Component<IComponentProps, IComponentState> {

  readonly state:IComponentState = {
    isLoaded: false,
    isDeleting: false,
    isDeleted: false,
    isNotFound: false,
    companyData: undefined
  }

  // static matchUser(user: IStoreState['user'], isAuthorized: boolean, advert: IAdvertItem) {
  //   if (isAuthorized && advert.contacts) return advert.contacts.phone === user.phone ? true : false;
  //   return false;
  // }

  setIsDeleting() {
    this.setState((prevState) => ({ isDeleting: !prevState.isDeleting }));
  }

  async getData(user: IStoreState['user'], companyId: string, isAuthorized: boolean) {
    const requestURL = setRequestUrl('wp-json/api/v1/company/', 'single', `id=${companyId}`);
    const requestParams = isAuthorized ? setRequestParams('GET', user.token) : setRequestParams('GET');

    const content = await sendRequest(requestURL, requestParams);
    if (content.errMsg) this.setState({ isNotFound: true, isLoaded: true });
    else this.setState({ companyData: content, isLoaded: true })
  }

  componentDidMount() {
    const { user, companyId, isAuthorized } = this.props;
    this.getData(user, companyId, isAuthorized);
  }
  
  render() {
    const { companyId } = this.props;
    const { 
      isLoaded, isNotFound, companyData
    } = this.state;

    if(!isLoaded)
      return (<Loading isActive={!isLoaded} />)

    if(!companyData || isNotFound) {
      return (<NotFound/>);
    } else {
      const leftColumnProps = {
        unn: companyData.unn,
        urName: companyData.urName,
        urAddress: companyData.urAddress,
        contacts: companyData.contacts,
        location: companyData.location,
        work_days: companyData.work_days,
        company_logo: companyData.company_logo
      };

      const headerProps = {
        title: companyData.title,
        description: companyData.description
      };

      return (
        <div className="company-detail-wrapper">
          <CompanyLeftColumn {...leftColumnProps} />
          <div className="company-detail-body">
            <CompanyDetailHeader  {...headerProps} />
            <CompanyNav pathname={this.props.location ? this.props.location.pathname : ''} />
            <Router>
              <CompanyProductsList
                path="company-posts/:productId"
                productId=":productId"
                companyId={companyId}
              />
              <CompanyVacanciesList 
                path="company-vacancies/:vacancyId"
                vacancyId=":vacancyId"
                companyId={companyId}
              />
            </Router>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state: IStoreState) => ({
  preferredCurrency: state.preferredCurrency
});

export default connect(mapStateToProps)(CompanyDetail);
  