import { IExchangeRates } from '../types';

export default function setCalculatedPrice (
  currency: string, price: string, exchangeRates: IExchangeRates
): { byn: string, usd: string, eur: string, rub: string } {
  if(!price && !currency) return { byn: '0', usd: '0', eur: '0', rub: '0' };
  const parsedPrice = parseInt(price, 10);
  switch(currency) {
    case 'USD':
      return {
        byn: `${Math.round(parsedPrice * exchangeRates.usd)}`,
        usd: price,
        eur: `${Math.round(parsedPrice * exchangeRates.usd / exchangeRates.eur)}`,
        rub: `${Math.round(parsedPrice * exchangeRates.usd / exchangeRates.rub)}`
      };
    case 'EUR': 
      return {
        byn: `${Math.round(parsedPrice * exchangeRates.eur)}`,
        usd: `${Math.round(parsedPrice * exchangeRates.eur / exchangeRates.usd)}`,
        eur: price,
        rub: `${Math.round(parsedPrice * exchangeRates.eur / exchangeRates.rub)}`
      }
    case 'RUB': 
      return {
        byn: `${Math.round(parsedPrice * exchangeRates.rub)}`,
        usd: `${Math.round(parsedPrice * exchangeRates.rub / exchangeRates.usd)}`,
        eur: `${Math.round(parsedPrice * exchangeRates.rub / exchangeRates.eur)}`,
        rub: price
      }
    default:
      return {
        byn: price,
        usd: `${Math.round(parsedPrice / exchangeRates.usd)}`,
        eur: `${Math.round(parsedPrice / exchangeRates.eur)}`,
        rub: `${Math.round(parsedPrice / exchangeRates.rub)}`
      };
  }
};
