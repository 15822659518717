import React from 'react';

interface IComponentProps {
  deleteAdvert: () => void,
  setIsDeleting: () => void,
  path?: string
}

const Deleting: React.FC<IComponentProps> = ({ deleteAdvert, setIsDeleting }) => (
  <div className="deleting">
    <p>Вы уверены, что хотите удалить объявление?</p>
    <div className="button-container">
      <button
        type="button"
        className="action-button"
        onClick={setIsDeleting}
      >
        ОТМЕНА
      </button>
      <button
        type="button"
        className="action-button secondary"
        onClick={deleteAdvert}
      >
        УДАЛИТЬ
      </button>
    </div>
  </div>
);

export default Deleting;
