import React from 'react';
import { Link } from '@reach/router';

interface IComponentProps {
  isEdit?: boolean
}

const CompanyPostedMessage: React.FC<IComponentProps> = ({ isEdit }) => (
  <div className="loaded">
    <p>{ isEdit ? 'Данные компании успешно обновлены' : 'Компания успешно создана' }</p>
    <Link to="../../">Вернуться в личный кабинет</Link>
  </div>
);

export default CompanyPostedMessage;
