import React, { Component } from 'react';
import noPhoto from '../../assets/img/nophoto-profile.png';
import { IVacancyItem } from '../../common/types';

import Deleting from '../../components/products/utils/Deleting';
import setRequestUrl from '../../common/utilits/setRequestUrl';
import sendRequest from '../../common/utilits/sendRequest';
import setRequestParams from '../../common/utilits/setRequestParams';

import { IUser } from '../../common/types';
import Control, {TControlText} from '../ItemControl';
import { Link } from '@reach/router';
import formatDate from '../../common/utilits/formatDate';

interface IComponentProps {
    vacancy: IVacancyItem,
    fromCompany?: boolean,
    user?: IUser,
    deleteVacancy: (id: number) => void,
    publishVacancy: (id: number) => void,
    draftVacancy: (id: number) => void
}

interface IComponentState {
    isDeleting: boolean,
    isDeleted: boolean,
}

const displayPrice = (price: IVacancyItem['price']) => {
    if (price.min && !price.max) return `От ${price.min.byn} руб.`;
    if (price.max && !price.min) return `До ${price.max.byn} руб.`;
    if (price.min && price.max) return `От ${price.min.byn} - до ${price.max.byn} руб.`;
}

class VacancyItem extends Component<IComponentProps, IComponentState> {

    readonly state: IComponentState = {
        isDeleting: false,
        isDeleted: false,
    }

    async setIsDeleting() {
        this.setState((prevState) => ({ isDeleting: !prevState.isDeleting }));
    }

    async deleteVacancy() {
        const { vacancy: { id }, user } = this.props;

        if (user) {
            const requestURL = setRequestUrl('wp-json/api/v1/vacancy/', 'delete', `id=${id}`);
            const requestParams = setRequestParams('DELETE', user.token);
            await sendRequest(requestURL, requestParams);
            this.setState({ isDeleting: false, isDeleted: true })
        }
    }

    render() {
        const text: TControlText = {
            deleteTxt: 'Вы уверены, что хотите удалить вакансию? Сейчас оно неактивно и видно только вам. Если вы удалите, то уже не сможете его восстановить.',
            deleteBtn: 'Удалить вакансию',
            draftTxt: 'Вакансия не будет показываться в общем списке, но останется в личном кабинете, так что вы всегда сможете его восстановить.',
            draftBtn: 'Скрыть вакансию'        
        }
        const { vacancy: {
            id, title, full, location, price, post_status, published, author: { company }
        }, fromCompany } = this.props;
        const { isDeleting } = this.state;
        const {deleteVacancy, publishVacancy, draftVacancy} = this.props;

        const salary = displayPrice(price);
        const datePublished = formatDate(published);

        if (isDeleting) {
            return (
                <Deleting
                    deleteAdvert={this.deleteVacancy.bind(this)}
                    setIsDeleting={this.setIsDeleting.bind(this)}
                />
            );
        }

        return (
            <div className="product-text-main-conten">
                <div className="product">
                    <div className="product-text-content">
                    <Control post_status={post_status} id={id} 
                    onDelete={deleteVacancy}
                    onPublish={publishVacancy}
                    onDraft={draftVacancy}
                    text={text}/>
                    <Link to={fromCompany ? `../../../../../vacancies/1/${id}` : `./${id}`}>
                        <div className="product-header">
                            <div className="labels">
                                <h3> {title} </h3>
                                {post_status === 'pending' ? <p className="pending">На модерации</p> : null}
                                {post_status === 'draft' ? <p className="draft">Неактивно</p> : null}
                            </div>
                            { post_status ? null :<p className="date-published">{datePublished}</p>}
                        </div>
                        <p className="salary">{salary}</p>
                        <div className="location">
                            <p className="company">{company.urName}</p>
                            <p className="city">{location.city}</p>
                        </div>
                        <div className="description-container">
                            <p className="short-description vacancy">{full}</p>
                            {fromCompany
                                ? null
                                : (<div className="company-logo-container vacancy">
                                    <img src={company ? company.company_logo || noPhoto : noPhoto} alt="company-logo" />
                                </div>)
                            }
                        </div>
                      </Link>
                    </div>
                </div>
            </div>

        )
    }

}

export default VacancyItem;
