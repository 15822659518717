import React, { Component } from 'react';
//@ts-ignore
import { isValidPhoneNumber } from 'react-phone-number-input'
import RegistrationFormPopUp from './pop-ups/RegistrationFormPopUp';
import setRequestUrl from '../../../common/utilits/setRequestUrl';
import setRequestParams from '../../../common/utilits/setRequestParams';
import sendRequest from '../../../common/utilits/sendRequest';
import { ENTER_CHAR_CODE } from '../../../common/consts';
import { IUser } from '../../../common/types';
import store from 'redux/store';
import { alertNewUser } from 'redux/actions/actions';

interface IComponentProps {
  isActive: boolean,
  isInTrigger?: boolean,
  toggle: (isActive: boolean) => void,
  switchActive: (isActive: boolean) => void,
  updateUser: (user: IUser) => any
}

interface IComponentState {
  payload: {
    userName: string,
    userSurname: string,
    userEmail: string,
    userPhoneNumber: string,
    userPassword: string,
  },
  validity: {
    isUserNameValid: boolean,
    isUserSurnameValid: boolean,
    isUserEmailValid: boolean,
    isUserPhoneNumberValid: boolean,
    isUserPasswordValid: boolean,
    isRepeatedPasswordValid: boolean
  },
  repeatedPassword: string,
  errMsg: string | undefined,
  user: IUser | null
}

class Registration extends Component<IComponentProps, IComponentState> {
  constructor(props: Readonly<IComponentProps>) {
    super(props);
    this.state = {
      payload: {
        userName: '',
        userSurname: '',
        userEmail: '',
        userPhoneNumber: '',
        userPassword: ''
      },
      validity: {
        isUserNameValid: false,
        isUserSurnameValid: false,
        isUserEmailValid: false,
        isUserPhoneNumberValid: false,
        isUserPasswordValid: false,
        isRepeatedPasswordValid: false
      },
      repeatedPassword: '',
      errMsg: undefined,
      user: null
    }
    this.sendData = this.sendData.bind(this);
    this.handleKeyDownRegistration = this.handleKeyDownRegistration.bind(this);
  }

  handleClick: React.ReactEventHandler<HTMLDivElement> = (e) => {
    const { isActive, toggle } = this.props;
    toggle(!isActive);
  };

  handleKeyDownRegistration(event: any) {
    if (event.charCode === ENTER_CHAR_CODE) this.sendData();
  }

  handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    const userName: string = event.currentTarget.value;
    const isValid = userName.length > 0
    event.currentTarget.style.borderColor = isValid ? 'green' : 'red';
    this.setState({
      payload: { ...this.state.payload, userName },
      validity: { ...this.state.validity, isUserNameValid: isValid },
      errMsg: undefined
    });
  };

  handleSurnameChange(event: React.ChangeEvent<HTMLInputElement>) {
    const userSurname: string = event.currentTarget.value;
    const isValid = userSurname.length > 0
    event.currentTarget.style.borderColor = isValid ? 'green' : 'red';
    this.setState({
      payload: { ...this.state.payload, userSurname },
      validity: { ...this.state.validity, isUserSurnameValid: isValid },
      errMsg: undefined
    });
  };

  handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    const userEmail: string = event.currentTarget.value;
    const reg = /^[A-Z0-9._%+-]+@[A-Z0-9-]+\.[A-Z]{2,4}$/i;
    const isValid = reg.test(userEmail);
    event.currentTarget.style.borderColor = isValid ? 'green' : 'red';
    this.setState({
      payload: { ...this.state.payload, userEmail },
      validity: { ...this.state.validity, isUserEmailValid: isValid },
      errMsg: undefined
    });
  };

  handlePhoneNumberChange(value: string) {
    const isValid = !!value;
    this.setState({
      payload: {
        ...this.state.payload,
        userPhoneNumber: value,
      },
      validity: { ...this.state.validity, isUserPhoneNumberValid: isValid },
      errMsg: undefined
    });
  }

  handlePasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
    const userPassword: string = event.currentTarget.value;
    const isValid = userPassword.length > 4;
    event.currentTarget.style.borderColor = isValid ? 'green' : 'red';
    this.setState({
      payload: { ...this.state.payload, userPassword },
      validity: { ...this.state.validity, isUserPasswordValid: isValid },
      errMsg: undefined
    });
  };

  handleRepeatedPasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { payload: { userPassword } } = this.state;
    const repeatedPassword: string = event.currentTarget.value;
    const isValid = repeatedPassword === userPassword;
    event.currentTarget.style.borderColor = isValid ? 'green' : 'red';
    this.setState({
      repeatedPassword,
      validity: { ...this.state.validity, isRepeatedPasswordValid: isValid },
      errMsg: undefined
    });
  };

  async sendData() {
    const { updateUser } = this.props;
    const { validity } = this.state;

    if (!isValidPhoneNumber(this.state.payload.userPhoneNumber)) {
      this.setState({
        errMsg: 'Примеры ввода телефона: +375(BY) XX XXX-XX-XX, XX XXX-XX-XX'
      });
      return;
    }

    if (!validity.isUserEmailValid || !validity.isUserNameValid || !validity.isUserSurnameValid
      || !validity.isUserPhoneNumberValid || !validity.isUserPasswordValid || !validity.isRepeatedPasswordValid) {
      this.setState({
        errMsg: 'Поля заполнены с ошибками'
      });
      return;
    }

    const payload = {
      email: `${this.state.payload.userEmail}`,
      phone: this.state.payload.userPhoneNumber.slice(1),
      password: `${this.state.payload.userPassword}`,
      role: 'Catalog/User',
      user_details: {
        first_name: `${this.state.payload.userName}`,
        last_name: `${this.state.payload.userSurname}`
      }
    }

    const requestURL = setRequestUrl('wp-json/api/v1/user/', 'create');
    const requestParams = setRequestParams('POST', undefined, payload);
    const content = await sendRequest(requestURL, requestParams);

    if (content.errMsg) {
      this.decodeErrorMessage(content.errMsg);
    } else {
      this.setState({
        payload: {
          userName: '',
          userSurname: '',
          userEmail: '',
          userPhoneNumber: '',
          userPassword: ''
        },
        repeatedPassword: '',
        user: content,
      });
      const requestURL = setRequestUrl('wp-json/api/v1/user/', 'requestEmailConfirm');
      const requestParams = setRequestParams('GET', content.token);
      sendRequest(requestURL, requestParams);
      updateUser(content);
      store.dispatch(alertNewUser(true));
    }
  };

  decodeErrorMessage(errMsg: string) {
    const div = document.createElement("div");
    div.innerHTML = errMsg;
    const parsedText = div.textContent || div.innerText || "";
    this.setState({ errMsg: parsedText });
  }

  componentWillReceiveProps(nextProps: IComponentProps) {
    const { isActive } = nextProps;
    if (isActive) document.addEventListener("keypress", this.handleKeyDownRegistration);
    else document.removeEventListener("keypress", this.handleKeyDownRegistration);
  }

  componentWillUnmount() {
    document.removeEventListener("keypress", this.handleKeyDownRegistration);
  }

  render() {
    const popUpProps = {
      payload: this.state.payload,
      validity: this.state.validity,
      errMsg: this.state.errMsg,
      handleNameChange: this.handleNameChange.bind(this),
      handleSurnameChange: this.handleSurnameChange.bind(this),
      handlePhoneNumberChange: this.handlePhoneNumberChange.bind(this),
      handlePasswordChange: this.handlePasswordChange.bind(this),
      handleEmailChange: this.handleEmailChange.bind(this),
      handleRepeatedPasswordChange: this.handleRepeatedPasswordChange.bind(this),
      repeatedPassword: this.state.repeatedPassword,
      toggle: this.props.toggle,
      switchActive: this.props.switchActive,
      isActive: this.props.isActive,
      sendData: this.sendData
    }
    const { isActive, isInTrigger } = this.props;
    return (
      <div className="auth-button-wrapper">
        <p
          className="auth-button"
          onClick={this.handleClick}
          style={{
            color: isActive ? '#036d9e' : undefined,
            display: isInTrigger ? 'none' : undefined
          }}
        >
          зарегистрироваться
        </p>
        {isActive ? <RegistrationFormPopUp {...popUpProps} /> : null}
      </div>
    );
  }
}

export default Registration;
