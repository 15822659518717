import sendRequest from "../utilits/sendRequest";
import setRequestParams from "../utilits/setRequestParams";
import setRequestUrl from "../utilits/setRequestUrl";
import {
  IServerError,
  IServerErrorTranslation,
  translateServerError,
} from "./server-error";

const twilioUnavailable = "SMS plugin not working";
const phoneAlreadyVerified = "Phone already verified";
// should avoid in frontend
const samePhoneNumber = "New and previous phone numbers are equals";
// cooldown params
const manyRequests = "Too many requests";

const requestConfirmationCodeErrorTranslate: IServerErrorTranslation[] = [
  {
    error: twilioUnavailable,
    translate:
      "Сервис верификации временно недоступен, попробуйте повторить верификацию позже",
  },
  {
    error: phoneAlreadyVerified,
    translate: "Номер телефона уже подтвержден",
  },
  {
    error: samePhoneNumber,
    translate: "Данный номер телефона уже используется",
  },
  {
    error: manyRequests,
    translate: (serverError: IServerError) =>
      `Вы уже недавно запрашивали код подтверждения, повторите попытку через ${
        serverError.cooldown || 60
      } секунд`,
  },
];

const confirmConfirmationCodeErrorTranslate: IServerErrorTranslation[] = [
  {
    error: phoneAlreadyVerified,
    translate: "Номер телефона уже подтвержден",
  },
  {
    error: "No pending code",
    translate: "Неверный код, попробуйте перезапросить его",
  },
  {
    error: "Code expired, request new code",
    translate: "Время действия кода уже вышло, попробуйте перезапросить его",
  },
  {
    error: "Wrond code, request new code",
    translate: "Неверный код, попробуйте перезапросить его",
  },
];

export async function requestConfirmationCode(
  token: string,
  newPhoneNumber?: string
): Promise<string | undefined> {
  const requestURL = setRequestUrl(
    "wp-json/api/v1/user/",
    "requestPhoneCode",
    newPhoneNumber ? `phone=${newPhoneNumber}` : undefined
  );
  const requestParams = setRequestParams("POST", token);

  return translateServerError(
    sendRequest(requestURL, requestParams),
    requestConfirmationCodeErrorTranslate
  );
}

export async function confirmVerificationCode(
  securityCode: string,
  token: string
): Promise<string | undefined> {
  const requestURL = setRequestUrl(
    "wp-json/api/v1/user/",
    "confirmPhoneCode",
    `code=${securityCode}`
  );
  const requestParams = setRequestParams("POST", token);

  return translateServerError(
    sendRequest(requestURL, requestParams),
    confirmConfirmationCodeErrorTranslate
  );
}
