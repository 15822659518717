import React from 'react';
import { Link } from '@reach/router';
import getConditionState from '../../../common/utilits/getConditionState';
import noPhoto from '../../../assets/img/nophoto.jpg';
import placeMarkerIcon from '../../../assets/icons/place-marker-icon.png';
import settingsIcon from '../../../assets/icons/settings-icon.png';
import { IAdvertItem } from '../../../common/types';

interface IComponentProps {
  text: string,
  resetState: () => void,
  payload: IAdvertItem
}

function getMainImage(images: IAdvertItem['images']){
  for(let item of images) {
    if (item.main) return item;
  }
}

const AdvCreatedMessage: React.FC<IComponentProps> = ({text, resetState, payload }) => {
  const conditionState = getConditionState(payload.condition);
  const mainImage = getMainImage(payload.images);
  return (
    <div className="loaded product">
      <h3 className="loaded-message">{text}</h3>
      <div className="product-list">
        <div className="product">
          <div className="product-image">
            {payload.images.length > 0
              ? <img src={mainImage ? mainImage.url : payload.images[0].url} alt="product"/>
              : <img src={noPhoto} alt="product"/>
            }
          </div>
          <div className="product-text-content">
            <div className="product-header">
              <div className="labels">
                <h3> {payload.title} </h3>
                {payload.price.exchange ? <p className="exchange">Обмен</p> : null}
              </div>
            </div>
            <h4>{payload.category}, {payload.year} г., {payload.sn}</h4>
            <p className="short-description">{payload.full}</p>
            <div className="product-footer">
              <div className="state-container">
                {payload.location.country || payload.location.city ?
                  <div className="location">
                    <img src={placeMarkerIcon} alt="place marker"/>
                    <p>{payload.location.country ? `${payload.location.country},` : null} {payload.location.city}</p>
                  </div>
                : null}
                <div className="working-hours">
                  <img src={settingsIcon} alt="settings icon"/>
                  <p>{payload.hours.length === 0 ? 0 : payload.hours} ч.</p>
                </div>
                {conditionState ?
                  <div className="condition">
                    <img src={conditionState.image} alt="stars"/>
                  </div>
                : null}
              </div>
              <div className="price-container">
                <p>{payload.price.value} {payload.price.currency}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Link
        to="../ads"
        className="go-back-link"
        onClick={resetState}
      >
        Перейти к своим объявлениям
      </Link>
    </div>
  )
};

export default AdvCreatedMessage;
