import React, { useRef } from 'react';
import closeButtonIcon from '../../../../assets/icons/close-icon.png';

interface IComponentProps {
  handleUserLoginChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  handlePasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  errMsg: string | undefined,
  userLogin: string,
  userPassword: string,
  logIn: () => any,
  toggle: (isActive:boolean) => void,
  switchActive: (isActive:boolean) => void,
  isActive: boolean,
  toggleReset: () => void
}

const LogInFormPopUp: React.FC<IComponentProps>= (props) => {
  const modalRef = useRef(null);
  const {
    handlePasswordChange,
    handleUserLoginChange,
    logIn,
    userPassword,
    userLogin,
    errMsg,
    toggle,
    switchActive,
    isActive,
    toggleReset
  } = props;
  const handleDisable = (event: React.MouseEvent<HTMLDivElement>) => {
    if (modalRef.current === event.target) toggle(!isActive);
  }
  return (
    <div className="modal login" onClick={handleDisable} ref={modalRef}>
      <form 
        className="pop-up"
      >
        <div className="switch-container">
          <button
            type="button"
            className="switch-button"
            style={{ color: isActive ? '#212529' : '', borderBottom: isActive ? '1px solid #212529' : '' }}
          >
            Вход
          </button>
          <button
            type="button"
            className="switch-button"
            onClick={() => switchActive(isActive)}
          >
            Регистрация
          </button>
          <div
            className="close-button"
            onClick={() => toggle(!isActive)}
          >
            <img src={closeButtonIcon} alt="close"/>
          </div>
        </div>
        <div className="content-wrapper">
          <div className="forms-container">
            <div className="input-container">
              <label htmlFor="uname">Номер телефона или E-mail</label>
              <input
                className="auth-input"
                type="text"
                value={userLogin}
                name="uname"
                onChange={handleUserLoginChange}
                required
              />
            </div>

            <div className="input-container">
              <label htmlFor="psw">Пароль</label>
              <input
                className="auth-input"
                type="password"
                id="last-child"
                value={userPassword}
                name="psw"
                onChange={handlePasswordChange}
                required
              />
            </div>
          </div>
          
          <div className="buttons-container">
            <button type="button" onClick={toggleReset} className="reset-password-button">Забыли пароль?</button>
            {errMsg ? <p style={{color: 'red'}}>{errMsg}</p> : <></>}
            <button type="button" onClick={logIn} className="action-button">Войти</button>
          </div>
        </div>
      </form>
    </div>
  )
};

export default LogInFormPopUp;
