import React from 'react';
import { RouteComponentProps } from '@reach/router';
import PaginationItem from './PaginationItem';

interface IComponentProps extends RouteComponentProps {
  pageId: number,
  totalPages: number,
}

const Breadcrumbs: React.FC<IComponentProps> = ({ totalPages, pageId }) => {
  const paginationItems = [];
  if (totalPages <= 7) {
    for(let index = 1; index <= totalPages; index++) {
      paginationItems.push(
        <PaginationItem
          key={index}
          pageNumber={index}
          isCurrent={index === pageId ? true : false}
        />
      )
    }
  } else {
    for (
      let index = pageId - 3 > 0 ? pageId - 3 : 1;
      index <= pageId + 3 && index <= totalPages;
      index++
    ) {
      paginationItems.push(
        <PaginationItem
          key={index}
          pageNumber={index}
          isCurrent={index === pageId ? true : false}
        />
      )
    }
    if (totalPages - pageId > 3) {
      paginationItems.push(
        <PaginationItem
          id='end'
          key={totalPages}
          pageNumber={totalPages}
          isCurrent={totalPages === pageId ? true : false}
        />
      );
    }
    if (pageId - 3 > 1) {
      paginationItems.unshift(
        <PaginationItem
          id='start'
          key={1}
          pageNumber={1}
          isCurrent={1 === pageId ? true : false}
        />,
      );
    }
  }
  return (
    <div className="breadcrumbs">
      {paginationItems}
    </div>
  )
}

export default Breadcrumbs;
