/**
 * Set request params.
 * @constructor
 * @param {string} method - Request method (e.g. POST, GET, etc.).
 * @param {string?} token - JWT Bearer token.
 * @param {object?} payload - Request payload (Some data to send on server).
 * @param {object?} customHeaders - Custom headers (e.g. in case if content type of assets differs from 'application/json').
 */
export default function setRequestParams(method:string, token?:string, payload?:object, customHeaders?:object):object {
  const requestParams = {
    method,
    headers: setHeaders(token, customHeaders)
  };
  if (payload) return {...requestParams, body: JSON.stringify(payload)};
  else return requestParams;
}

function setHeaders(token?:string, customHeaders?:object):object {
  if(customHeaders) return customHeaders;
  if(token) return {
    'Authorization': `Bearer ${token}`,
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
}
