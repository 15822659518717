import React, { Component } from 'react';
import { RouteComponentProps } from '@reach/router';
import CompanyRegistrationForm from '../../components/company/CompanyRegistrationForm';
import Loading from '../../common/loading';
import Posted from '../../components/company/CompanyPostedMessage';
import setRequestUrl from '../../common/utilits/setRequestUrl';
import setRequestParams from '../../common/utilits/setRequestParams';
import sendRequest from '../../common/utilits/sendRequest';
import getBase64Image from '../../common/utilits/getBase64Image';
import { ICompanyItem } from '../../common/types';

interface IComponentProps extends RouteComponentProps {
  token: string,
  companyId?: number
}

interface IComponentState {
  payload?: ICompanyItem,
  isPosted: boolean,
  isLoading: boolean,
  isError: boolean,
  errMsg: string
}

class CompanyEditPage extends Component<IComponentProps, IComponentState> {

  readonly state: IComponentState = {
    isPosted: false,
    isLoading: false,
    isError: false,
    errMsg: ''
  }

  async getCompany() {
    const { token, companyId } = this.props;

    const requestURL = setRequestUrl('wp-json/api/v1/company/', 'single', `id=${companyId}`);
    const requestParams = setRequestParams('GET', token);
    
    this.setState({ isLoading: true });
    
    const content = await sendRequest(requestURL, requestParams);
    if (content.errMsg) {
      this.setState({
        errMsg: content.errMsg,
        isError: true,
        isPosted: false,
        isLoading: false
      })
    } else {
      const convertedImages = await getBase64Image([{ id: 1, main: 0, url: content.company_logo }]);
      this.setState({
        payload: { ...content, company_logo: convertedImages[0].data},
        isLoading: false
      })
    }
  }

  async updateCompany(composedData:object) {
    const { token, companyId } = this.props;

    const requestURL = setRequestUrl('wp-json/api/v1/company/', 'update', `id=${companyId}`);
    const requestParams = setRequestParams('PATCH', token, composedData);
    
    this.setState({ isLoading: true });
    
    const content = await sendRequest(requestURL, requestParams);
    if (content.errMsg) {
      this.setState({
        errMsg: content.errMsg,
        isError: true,
        isPosted: false,
        isLoading: false
      })
    } else {
      this.setState({
        isPosted: true,
        isLoading: false
      })
    }
  }

  componentDidMount() {
    this.getCompany();
  }

  render() {
    const { isLoading, isPosted, isError, errMsg, payload } = this.state;

    if(isLoading)
      return (<Loading isActive={isLoading} />)

    if(isPosted)
      return (<Posted isEdit={true} />)

    if(isError)
      return (<div>{errMsg}</div>)

    return (
      <CompanyRegistrationForm createCompany={this.updateCompany.bind(this)} payload={payload} />
    )
  }
}

export default CompanyEditPage;
