import { combineReducers } from "redux";
import {
  GET_USER,
  EXIT_USER,
  UPDATE_USER_DETAILS,
  GET_FILTER_PARAMS,
  UPDATE_USER_PREFERRED_CURRENCY,
  ALERT_NEW_USER,
  ALERT_PHONE_VERIFIED,
} from "../actions/consts";
import {
  IActionUser,
  IUser,
  IAdvertFilter,
  IActionFilter,
  TPreferredCurrency,
  IActionPreferredCurrency,
  INewUserAlert,
  DisabledPhoneRoute,
  IPhoneVerifiedAlert,
} from "../../common/types";

function user(state: IUser | null = null, action: IActionUser) {
  if (action.type === GET_USER) {
    return action.user;
  }
  if (action.type === EXIT_USER) {
    return null;
  }
  if (action.type === UPDATE_USER_DETAILS) {
    if (state) return { ...state, ...action.user_details };
  }
  return state;
}

function filter(state: IAdvertFilter | null = null, action: IActionFilter) {
  if (action.type === GET_FILTER_PARAMS) {
    return action.filterParams;
  }
  return state;
}

function preferredCurrency(
  state: TPreferredCurrency | null = null,
  action: IActionPreferredCurrency
) {
  if (action.type === UPDATE_USER_PREFERRED_CURRENCY) {
    return action.preferredCurrency;
  }
  return state;
}

function alertNewUser(state: boolean | null = null, action: INewUserAlert) {
  if (action.type === ALERT_NEW_USER) {
    return action.showAlert;
  }
  return state;
}

function alertPhoneVerified(
  state: DisabledPhoneRoute | null = null,
  action: IPhoneVerifiedAlert
) {
  if (action.type === ALERT_PHONE_VERIFIED) {
    return action.routeType || null;
  }
  return state;
}

const mainReducer = combineReducers({
  user,
  preferredCurrency,
  filter,
  alertNewUser,
  alertPhoneVerified,
});

export default mainReducer;
