import React from 'react';
import WorkDaysCollection from './WorkDaysCollection';
import WorkTimeCollection from './WorkTimeCollection';
import { IWorkDays, IWorkTime } from '../../../common/types';

interface IComponentProps {
  workDays: IWorkDays,
  workTime: IWorkTime,
  setWorkDays: any,
  setWorkTime: any
}

const WorkTimeForm: React.FC<IComponentProps> = ({ workDays, workTime, setWorkDays, setWorkTime }) => (
  <div className="payload-container">
    <h2>Время работы</h2>
    <WorkDaysCollection
      workDays={workDays}
      setWorkDays={setWorkDays}
    />
    <WorkTimeCollection
      workDays={workDays}
      workTime={workTime}
      setWorkTime={setWorkTime}
    />
  </div>
)

export default WorkTimeForm;
