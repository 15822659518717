import React, {Component, createRef} from 'react';
import {RouteComponentProps} from '@reach/router';
import Loading from '../../common/loading';
import ErrorMessage from '../../components/dashboard/utils/ErrorMessage';
import EmptyFieldsMessage from '../../components/dashboard/utils/EmptyFieldsMessage';
import AdvCreatedMessage from '../../components/dashboard/utils/AdvCreatedMessage';
import AdvertForm from '../../components/dashboard/AdvertForm';
import setRequestUrl from '../../common/utilits/setRequestUrl';
import setRequestParams from '../../common/utilits/setRequestParams';
import sendRequest from '../../common/utilits/sendRequest';
import getExchangeRates from '../../common/utilits/getExchangeRates';
import {IAdvertPayload, IAdvertItem, IAdvertCategories, IStoreState, IExchangeRates} from '../../common/types';

interface IComponentState {
  payload: IAdvertPayload,
  createdAdvertPayload?: IAdvertItem,
  advertCategories: IAdvertCategories,
  errMsg: string,
  isEmptyFields: boolean,
  isLoading: boolean,
  textLoading?: string,
  isLoaded: boolean,
  isError: boolean,
  exchangeRates: IExchangeRates
};

interface IComponentProps extends RouteComponentProps {
  user: IStoreState['user']
}

class CreateAdvert extends Component<IComponentProps, any> {
  private inputImagesRef = createRef<HTMLInputElement>();

  readonly state: IComponentState = {
    payload: {
      title: '',
      short: '',
      full: '',
      year: '',
      images: [],
      price: {
        byn: '',
        include_tax: false,
        exchange: false,
        currency: 'BYN',
        value: '',
        optional_price: false
      },
      contacts: {
        phone: ''
      },
      category: '',
      vendor: '',
      model: '',
      proposed_vendor: '',
      proposed_model: '',
      location: {
        country: '',
        city: '',
      },
      hours: '',
      condition: '3',
      sn: '',
      fromCompany: false,
      company_id: ''
    },
    errMsg: '',
    advertCategories: {
      category: [{id: 1, name: '', count: 1}],
      vendor: [{id: 1, name: '', count: 1, models: [{id: 1, name: '', count: 1, models: []}]}]
    },
    exchangeRates: {
      usd: 2,
      eur: 2.3,
      rub: 0.039
    },
    isEmptyFields: false,
    isLoading: false,
    isLoaded: false,
    isError: false
  };

  handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) {
    const value: string = event.currentTarget.value;
    const fieldName: string = event.currentTarget.name;
    //@ts-ignore
    this.setState({payload: {...this.state.payload, [fieldName]: value}});
  }

  clearShortItem(event: React.ChangeEvent<HTMLSelectElement>) {
    const value = event.currentTarget.value === 'true';
    this.setState({
      payload: {
        ...this.state.payload,
        short: '',
        price: {
          ...this.state.payload.price,
          exchange: value
        }
      }
    });
  };

  handleRadioChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value: string = event.currentTarget.value;
    this.setState({
      payload: {...this.state.payload, fromCompany: value === 'true'}
    })
  }

  handlePriceChange(event: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>) {
    let value: string | boolean = event.currentTarget.type === 'checkbox'
      ? event.currentTarget.checked
      : event.currentTarget.value
    const fieldName: string = event.currentTarget.name;
    value = fieldName === 'exchange' ? (value === 'true') : value;
    const price = {
      ...this.state.payload.price,
      [fieldName]: value
    }
    if (fieldName === "optional_price" && value === true) {
      price.value = "1";
    }
    this.setState({payload: {...this.state.payload, price}});
  }

  handleLocationChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value: string = event.currentTarget.value;
    const fieldName: string = event.currentTarget.name;
    const location = {
      ...this.state.payload.location,
      [fieldName]: value
    }
    this.setState({payload: {...this.state.payload, location}});
  }

  handleCategoryChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const category: string = event.currentTarget.value;
    this.setState({
      payload: {...this.state.payload, category}
    });
  };

  handleImagesClick() {
    if (this.inputImagesRef.current) {
      this.inputImagesRef.current.click();
    }
  }

  handleImagesChange() {
    if (this.inputImagesRef.current && this.inputImagesRef.current.files) {
      const file = this.inputImagesRef.current.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const images = this.state.payload.images;
        const base64Encoded = reader.result;
        const imagesItem = {
          data: base64Encoded,
          main: images.length === 0 ? 1 : 0
        }
        images.push(imagesItem);
        this.setState({images})
      }
      if (file) {
        if (file.size < 10485760) {
          reader.readAsDataURL(file);
        }
      }
    }
  }

  setMainImage(event: React.MouseEvent<HTMLDivElement>) {
    const {payload: {images}} = this.state;
    const id = event.currentTarget.id;
    const updatedImages = images.map((item: any, index: number) => (
      index === parseInt(id, 10) ? Object.assign(item, {main: 1}) : Object.assign(item, {main: 0})
    ));
    this.setState({payload: {...this.state.payload, images: updatedImages}});
  }

  deleteImage(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    const {payload: {images}} = this.state;
    const id: number = parseInt(event.currentTarget.id, 10);
    const updatedImages = images.slice();
    const deletedImage: { main: boolean }[] = updatedImages.splice(id, 1);
    if (deletedImage[0].main && updatedImages[0]) updatedImages[0].main = 1;
    this.setState({payload: {...this.state.payload, images: updatedImages}});
  }

  async sendAdvert(validatedPayload: IAdvertPayload) {
    const {user: {token}} = this.props;

    if (!validatedPayload.fromCompany) delete validatedPayload.fromCompany;

    const requestURL = setRequestUrl('wp-json/api/v1/post/', 'create');
    const requestParams = setRequestParams('POST', token, validatedPayload);

    this.setState({isLoading: true});

    const content = await sendRequest(requestURL, requestParams);
    if (content.errMsg) this.setErrorMessage(content.errMsg);
    else this.setState({createdAdvertPayload: content, isLoaded: true, isLoading: false, isAccepted: false});
  }

  async getAdvertCategories() {
    const requestURL = setRequestUrl('wp-json/api/v1/post/', 'formSubset');
    const requestParams = setRequestParams('GET');

    this.setState({isLoading: true});
    const content = await sendRequest(requestURL, requestParams);
    if (content.errMsg) this.setErrorMessage(content.errMsg);
    else {
      const isCategoriesValid = content.category && content.vendor && content.category[0] && content.vendor[0];
      if (isCategoriesValid) this.setState({advertCategories: content, isLoading: false});
    }
  }

  resetState() {
    this.setState({
      payload: {
        title: '',
        short: '',
        full: '',
        images: [],
        year: '',
        price: {
          byn: '',
          currency: '',
          value: '',
          include_tax: false,
          exchange: false,
        },
        category: 'test1',
        vendor: '',
        model: '',
        location: {
          country: '',
          city: '',
        },
        company_id: ''
      },
      errMsg: '',
      isLoading: false,
      textLoading: '',
      isLoaded: false,
      isEmptyFields: false,
      isError: false
    })
  }

  resetLogic() {
    this.setState({
      isLoading: false,
      isLoaded: false,
      isEmptyFields: false,
      isError: false,
      isAccepted: false
    })
  }

  setErrorMessage(errMsg: string) {
    this.setState({
      errMsg,
      isEmptyFields: true,
      isLoaded: false,
      isLoading: false
    })
  }

  componentDidMount() {
    getExchangeRates().then(exchangeRates => this.setState({exchangeRates}));
    this.getAdvertCategories();
  }

  render() {
    const {
      isEmptyFields,
      isLoading,
      textLoading,
      isLoaded,
      isError
    } = this.state;

    if (isLoading)
      return (<Loading isActive={isLoading} textLoading={textLoading}/>)

    if (isEmptyFields)
      return (
        <EmptyFieldsMessage
          errMsg={this.state.errMsg}
          resetLogic={this.resetLogic.bind(this)}
        />
      );

    if (isLoaded && this.state.createdAdvertPayload)
      return (this.props.user.roles[0] === 'administrator'
        ? <AdvCreatedMessage
          text='Ваше объявление успешно добавлено!'
          resetState={this.resetState.bind(this)}
          payload={this.state.createdAdvertPayload}/>
        : <AdvCreatedMessage
          text='Ваше объявление успешно отправлено на модерацию!'
          resetState={this.resetState.bind(this)}
          payload={this.state.createdAdvertPayload}/>);

    if (isError)
      return (<ErrorMessage errMsg={this.state.errMsg}/>);

    const location = {
      title: 'Новое объявление',
      button: 'Создать объявление'
    }

    const advertFormProps = {
      location,
      payload: this.state.payload,
      advertCategories: this.state.advertCategories,
      exchangeRates: this.state.exchangeRates,
      handlers: {
        handleChange: this.handleChange.bind(this),
        clearShortItem: this.clearShortItem.bind(this),
        handleLocationChange: this.handleLocationChange.bind(this),
        handlePriceChange: this.handlePriceChange.bind(this),
        handleImagesClick: this.handleImagesClick.bind(this),
        handleImagesChange: this.handleImagesChange.bind(this),
        handleCategoryChange: this.handleCategoryChange.bind(this),
        handleRadioChange: this.handleRadioChange.bind(this),
        handleImportFromMascus: this.handleImportFromMascus.bind(this)
      },
      deleteImage: this.deleteImage.bind(this),
      setMainImage: this.setMainImage.bind(this),
      sendAdvert: this.sendAdvert.bind(this),
      inputImagesRef: this.inputImagesRef,
      user: this.props.user
    }

    return (
      <AdvertForm {...advertFormProps} />
    )
  }

  async handleImportFromMascus(id: string) {
    let attemptsCount = 18;
    const delay = async (ms: number) => new Promise(res => setTimeout(res, ms));

    this.setState({isLoading: true});

    try {
      this.setState({textLoading: 'Поиск на своей базе данных'});
      const requestSearchURL = setRequestUrl('wp-json/api/v1/post/', 'search', `sn=${id}`);
      const requestSearchParams = setRequestParams('GET', this.props.user.token);
      const resSearch = await sendRequest(requestSearchURL, requestSearchParams).then(res => res)
        .catch(error => console.log(error))
      if (resSearch !== null) {
        for (const iterator of resSearch.images) {
          await this.importImage(iterator);
        }
        this.parseTableData(resSearch["table_data"])
      } else {
        this.setState({textLoading: 'Обработка серийного номера'});
        const requestURL = setRequestUrl('wp-json/api/v1/job/', 'run', `sn=${id}`);
        const requestParams = setRequestParams('GET', this.props.user.token);
        const result = await sendRequest(requestURL, requestParams) as {
          jobid: string
          status: string
        };

        if (result.status !== "ok") {
          return;
        }
        while (attemptsCount) {
          await delay(10000);

          this.setState({textLoading: 'Поиск на mascus'});
          const jobInfo = result.jobid.split("/");
          const jobId = jobInfo[1];
          const itemId = jobInfo[2];

          const requestURL = setRequestUrl('wp-json/api/v1/job/', 'info', `job=${jobId}&item=${itemId}`);
          const requestParams = setRequestParams('GET', this.props.user.token);
          const statusResult = await sendRequest(requestURL, requestParams);

          if (statusResult.status === "ok" && statusResult.jobs.length > 0 && statusResult.jobs[0] && statusResult.jobs[0].state === "finished") {
            const requestURL = setRequestUrl('wp-json/api/v1/post/', 'import', `job=${jobId}&item=${itemId}`);
            const requestParams = setRequestParams('GET', this.props.user.token);

            const res = await sendRequest(requestURL, requestParams);
            for (const iterator of res.images) {
              await this.importImage(iterator);
            }
            this.parseTableData(res["table_data"]);
            break;
          }
          attemptsCount--;
        }
      }
    } catch (error) {}
    this.setState({textLoading: ''});
    this.setState({isLoading: false});
  }

  async importImage(image: string) {
    const split = image.split("/");
    const res = await fetch(image);
    const img = await res.arrayBuffer();

    const file: File = new File([img], split[split.length - 1]);

    const reader = new FileReader();
    reader.onloadend = () => {
      const images = this.state.payload.images;
      const base64Encoded = reader.result;
      const imagesItem = {
        data: base64Encoded,
        main: images.length === 0 ? 1 : 0
      }
      images.push(imagesItem);
      this.setState({images})
    }
    if (file) {
      if (file.size < 10485760) {
        reader.readAsDataURL(file);
      }
    }
  }

  parseTableData(tableData: any): void {
    for (const [key, value] of Object.entries(tableData)) {
      if (key.includes("Год выпуска")) {
        this.setState({payload: {...this.state.payload, year: value}});
      } else if (key.includes("Наработка")) {
        let res: string | number = "0"
        if (typeof value === "number") {
          res = value;
        } else if (typeof value === "string") {
          res = value.replace(/\D/g, '')
        }

        this.setState({payload: {...this.state.payload, hours: res}});
      } else if (key.includes("Серийный номер")) {
        this.setState({payload: {...this.state.payload, sn: value}});
      } else if (key.includes("Страна местонахождения")) {
        const location = {...this.state.payload.location, country: value};
        this.setState({payload: {...this.state.payload, location}})
      } else if (key.includes("Местоположение")) {
        const location = {...this.state.payload.location, city: value};
        this.setState({payload: {...this.state.payload, location}})
      } else if (key.includes("Марка / модель")) {
        // @ts-ignore
        this.setState({payload: {...this.state.payload, vendor:value.split(' ')[0], model: value.split(' ').slice(1).join(' ')}});
      } else if (key.includes("Категория")) {
        this.setState({payload: {...this.state.payload, category: value}});
      } else {
        let full = this.state.payload.full || "";
        let newLine = full ? "\n" : ""
        this.setState({payload: {...this.state.payload, full: `${full}${newLine}${key}: ${value}`}});
      }
    }
  }

}

export default CreateAdvert;
