import React, { Component } from 'react'
import { RouteComponentProps, WindowLocation, navigate } from '@reach/router';
import setRequestUrl from '../common/utilits/setRequestUrl';
import setRequestParams from '../common/utilits/setRequestParams';
import sendRequset from '../common/utilits//sendRequest';
import Loading from '../common/loading';
import NotFound from '../common/NotFound';
import { IStoreState, IUser } from '../common/types';

const ConfirmedMessage: React.FC = () => (
  <div className="confirm-email-wrapper confirmed">
    <h3>Почта успешно подтверждена.</h3>
    <button type="button" className="action-button" onClick={() => navigate('/dashboard')}>В личный кабинет</button>
  </div>
);

const LogInMessage: React.FC = () => (
  <div className="confirm-email-wrapper confirmed">
    <h3>Чтобы подтвердить почту войдите на сайт.</h3>
  </div>
);

const ErrorMessage: React.FC = () => (
  <div className="confirm-email-wrapper confirmed">
    <h3>Неверный ключ подтверждения.</h3>
  </div>
);

interface ISearchParams {
  key: string | null,
}

interface IComponentProps extends RouteComponentProps {
  user: IStoreState['user'],
  getUser: (user: IUser) => any
}

interface IComponentState {
  isConfirmed: boolean,
  isAuthorized: boolean,
  isError: boolean
}

class ConfirmEmail extends Component<IComponentProps, IComponentState> {

  readonly state: IComponentState = {
    isConfirmed: false,
    isError: false,
    isAuthorized: true
  }

  static getSearchParams(location: WindowLocation | undefined): ISearchParams {
    if (location) {
      const url = new URL(location.href);
      const searchParams = {
        key: url.searchParams.get('key'),
      }
      return searchParams;
    }
    return { key: null };
  }

  async confirmEmail(user: IComponentProps['user']) {
    const { location } = this.props;
    if (!user) {
      this.setState({ isAuthorized: false })
    } else if (user.email_verified){
      this.setState({ isConfirmed: true });
    } else {
      const searchParams = ConfirmEmail.getSearchParams(location);
      const requestURL = setRequestUrl('wp-json/api/v1/user/', 'confirmEmail', `key=${searchParams.key}`);
      const requestParams = setRequestParams('GET', user.token);
      
      const content = await sendRequset(requestURL, requestParams);
      if (!content || content.errMsg) {
        this.setState({ isError: true })
      } else {
        this.setState({ isConfirmed: true })
        this.props.getUser({ ...content, token: user.token });
      }
    }
  }

  componentDidMount() {
    const { user } = this.props;
    this.confirmEmail(user);
  }

  componentWillReceiveProps(nextProps: IComponentProps) {
    const { user } = nextProps;
    if (user) this.confirmEmail(user);
  }

  render() {
    const { isConfirmed, isAuthorized, isError } = this.state;
    const { location } = this.props;
    const searchParams = ConfirmEmail.getSearchParams(location);

    if(searchParams === null || searchParams.key === null)
      return (<NotFound />);

    if(isError)
      return (<ErrorMessage />);

    if (isConfirmed)
      return (<ConfirmedMessage />);

    if (!isAuthorized)
      return (<LogInMessage />);

    return (
      <div className="confirm-email-wrapper">
        <Loading isActive={true} />
      </div>
    );
  }
}

export default ConfirmEmail;
