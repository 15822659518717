import React from 'react';
import { RouteComponentProps, Redirect } from '@reach/router';
import employeeImage from '../../assets/img/employee.png';

interface IComponentProps extends RouteComponentProps {
    title: string,
    isUserJoinedCompany: boolean,
    togglePopupRequest: () => void,
}

const UserPendingPage: React.FC<IComponentProps> = ({ title, isUserJoinedCompany, togglePopupRequest }) => {
    return (
        <div className="company-wrapper__user-pending">
            <div className="action-container">
                <div className="img-container">
                    <img src={employeeImage} alt="staff" />
                </div>
                <div className="column">
                    <p>Вы подали заявку в компанию <strong>{title}</strong>. Ожидайте, пока ваша кандидатура будет принята администратором.</p>
                    <button className="cancel-request" onClick={togglePopupRequest}>Отменить заявку</button>
                </div>
            </div>
            {isUserJoinedCompany ? <Redirect from="/" to="dashboard/company" noThrow /> : null}
        </div>
    )
}

export default UserPendingPage;
