import React from 'react';

interface IComponentProps {
  errMsg: string,
}

const ErrorComponent: React.FC<IComponentProps> = ({ errMsg }) => (
  <div className="error">
    <p> Упс... что-то пошло не так </p>
    <p style={{ color: 'red' }}>{errMsg}</p>
  </div>
);

export default ErrorComponent;
