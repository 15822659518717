//-------------Store---------------
export interface IUser {
  email: string;
  id: number;
  phone: string;
  token: string;
  user_avatar: string;
  user_details: IUserDetails;
  userProfiles: any;
  company?: number;
  username: string;
  verified: any;
  phone_verified: boolean;
  email_verified: boolean;
  company_admin?: boolean;
  roles: string[];
}

export interface IUserDetails {
  first_name: string;
  last_name: string;
  city: string;
  country: string;
}

export interface IStoreState {
  user: IUser;
  preferredCurrency: TPreferredCurrency;
  filter: IAdvertFilter;
  alertNewUser: boolean;
  alertPhoneVerified: DisabledPhoneRoute;
}
//---------------------------------

//-------------Actions-------------
export interface IActionUser {
  type: string;
  user_details: IUserDetails;
  user: IUser | null;
}

export interface IActionFilter {
  type: string;
  filterParams: IAdvertFilter;
}

export type TPreferredCurrency = "BYN" | "RUB" | "USD" | "EUR";

export interface IActionPreferredCurrency {
  type: string;
  preferredCurrency: TPreferredCurrency;
}

export interface INewUserAlert {
  type: string;
  showAlert: boolean;
}

export interface IPhoneVerifiedAlert {
  type: string;
  routeType?: DisabledPhoneRoute;
}

//---------------------------------

//-------------Adverts-------------
export interface IAdvertPayload {
  title: string;
  short: string;
  full: string;
  year: string;
  images: any;
  price: {
    byn: string;
    currency: string;
    value: string;
    include_tax: boolean;
    exchange: boolean;
    optional_price: boolean;
  };
  contacts: {
    phone: string;
  };
  category: string | any;
  vendor: string;
  model: string;
  proposed_vendor: string,
  proposed_model: string,
  location: {
    country: string;
    city: string;
  };
  hours: string;
  condition: string;
  sn: string;
  fromCompany: boolean;
  company_id: string | boolean | undefined;
}

export interface IAdvertItem extends IAdvertPayload {
  author: TAuthor | any;
  published: string;
  modified: string;
  model_vendor: [string, string];
  category: [string];
  id: number;
  post_status: string | undefined;
}

export type TAuthor = {
  user_details: IUserDetails;
  user_avatar: string;
  company?: number;
  contacts?: {
    phone: string;
  };
};

export interface IAdvertFilter {
  categories: any[];
  vendor: string;
  model: string;
  country: string;
  city: string;
  yearMin: string;
  yearMax: string;
  priceMin: string;
  priceMax: string;
  hoursMin: string;
  hoursMax: string;
}

export interface IAdvertCategories {
  category: [
    {
      id: number;
      name: string;
      count: number;
    }
  ];
  vendor: [
    {
      id: number;
      name: string;
      count: number;
      models: [
        {
          id: number;
          name: string;
          count: number;
          models: any[];
        }
      ];
    }
  ];
}
//---------------------------------
export interface IExchangeRates {
  usd: number;
  eur: number;
  rub: number;
}
//---------------------------------

//-----------Vacancy---------------

export interface IVacancyItem {
  title: string;
  full: string;
  location: {
    country: string;
    city: string;
  };
  price: {
    currency: string;
    min: {
      byn: string;
      value: string;
    };
    max: {
      byn: string;
      value: string;
    };
  };
  id: number;
  category: [string];
  experience: [string];
  work: [string];
  employment: [string];
  schedule: [string];
  author: {
    user: TAuthor;
    company: ICompanyItem;
  };
  post_status?: string;
  published: string;
  modified: string;
}

// export interface IVacancyFilter {
//   category: string,
//   experience: string,
//   work: string,
//   employment: string,
//   schedule: string,
//   priceMin: string,
//   priceMax: string
// }

export interface IVacancyFilter {
  filters: {
    [key: string]: string[];
  };
  city: string;
  priceMin: number;
  priceMax: number;
}

export interface IVacancyCategories {
  [category: string]: string[];
  experience: string[];
  work: string[];
  employment: string[];
  schedule: string[];
}

//---------------------------------

//-----------Company---------------

export interface ICompanyItem {
  title: string;
  description: string;
  unn: string;
  urName: string;
  urAddress: string;
  contacts: {
    phone: string;
    email?: string;
    site?: string;
  };
  location: {
    country: string;
    city?: string;
    address?: string;
  };
  work_days: {
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
    saturday: string;
    sunday: string;
  };
  users: {
    users: Array<IUser>;
    pending: Array<IUser>;
  };
  admin: string;
  company_logo?: string;
  id: string;
  post_status: string;
}
//---------------------------------

export interface IWorkDays {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

export interface IWorkTime {
  monday: { from: string; to: string };
  tuesday: { from: string; to: string };
  wednesday: { from: string; to: string };
  thursday: { from: string; to: string };
  friday: { from: string; to: string };
  saturday: { from: string; to: string };
  sunday: { from: string; to: string };
}

//---------------------------------

export enum DisabledPhoneRoute {
  CreateAdvert = "CreateAdvert",
  CreateVacancy = "CreateVacancy",
  CreateCompany = "CreateCompany",
}
