import React, { useState } from "react";
import { alertNewUser } from "redux/actions/actions";
import store from "redux/store";
import Modal from "react-modal";
import closeIcon from "assets/icons/close-icon.png";
import { navigate } from "@reach/router";
import "assets/styles/popup.scss";

interface IComponentProps {
  showAlert: boolean;
  phone: string;
}

export const AlertNewUSer: React.FC<IComponentProps> = ({ showAlert, phone }) => {
  const [isModalOpen, setOpenModel] = useState(showAlert);

  if (isModalOpen !== showAlert) {
    setOpenModel(showAlert);
  }

  const closeAlert = () => {
    store.dispatch(alertNewUser(false));
    setOpenModel(false);
  };

  const sendSms = () => {
    closeAlert();
    navigate("/dashboard/validate-phone-number");
  };

  return (<Modal
    isOpen={isModalOpen}
    className="modal-confirm"
    overlayClassName="modal-confirm-overlay"
    shouldCloseOnOverlayClick={true}
    shouldCloseOnEsc={true}
    onRequestClose={closeAlert}
  >
    <div className="new-user-alert ">
      <img
        src={closeIcon}
        alt="close"
        onClick={closeAlert}
      />
      <p className="title">
        <b>
          Поздравляем, Вы успешно зарегестрированы на udarnik.by!
        </b>
        <br />
        <br />

        Теперь вы можете просматривать
        объявления других пользователей, но
        для размещения собственных
        объявлений или регистрации своей
        компании вам необходимо
        подтвердить номер телефона.

        <br />
        <br />
        <b>+{phone}</b>
        <br />
        <br />
        Вы можете сделать это позже, в
        личном кабинете пользователя или
        сейчас, нажав на кнопку
      </p>

      <button className="secondary-btn" onClick={closeAlert}>Позже</button>
      <button className="primary-btn" onClick={sendSms}>Получить код по СМС</button>
    </div>
  </Modal>);
};
