import React, { Component } from 'react';
import { RouteComponentProps } from '@reach/router';
import { connect } from 'react-redux';
import ProductItem from '../components/products/ProductItem';
import Loading from '../common/loading';
import NoResult from '../components/products/utils/NoResult';
import Pagintation from '../components/products/Pagination';
import ProductsSort from '../components/products/ProductsSort';
import setRequestParams from '../common/utilits/setRequestParams';
import getExchangeRates from '../common/utilits/getExchangeRates';
import { updateUserPreferredCurrency } from '../redux/actions/actions';
import { IAdvertItem, IStoreState, TPreferredCurrency, IActionPreferredCurrency, IExchangeRates } from '../common/types';
import setRequestUrl from '../common/utilits/setRequestUrl';
import sendRequest from '../common/utilits/sendRequest';

interface IComponentProps extends RouteComponentProps {
  isLoaded: boolean,
  advertList: Array<IAdvertItem>,
  totalPages: number,
  page: string,
  user: IStoreState['user'],
  setParentState: any,
  getPage: (pageNumber: number) => void,
  getPosts: (filterParams: any) => any,
  filterParams?: Array<string>,
  apiURL?: string,
  preferredCurrency: TPreferredCurrency,
  updateUserPreferredCurrency: (preferredCurrency: TPreferredCurrency) => IActionPreferredCurrency,
  expired: () => void;
}

interface IComponentState {
  sortedBy: 'date' | 'hours' | 'year' | 'price',
  order: 'DESC' | 'ASC',
  displayPrice: TPreferredCurrency,
  exchangeRates?: IExchangeRates
}

class ProductsList extends Component<IComponentProps, IComponentState> {
  readonly state: IComponentState = {
    sortedBy: 'date',
    order: 'DESC',
    displayPrice: 'BYN'
  }

  static checkVisibilityForLocation(location: any): boolean {
    if (location && location.pathname.includes("dashboard/ads")) return false;
    return true
  }

  handleDisplayPriceChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const { updateUserPreferredCurrency } = this.props;
    const displayPrice: any = event.currentTarget.value;
    updateUserPreferredCurrency(displayPrice);
    this.setState({ displayPrice });
  }

  setSortParams(sortedBy: IComponentState['sortedBy'], order: IComponentState['order']): string {
    const { apiURL, filterParams, user } = this.props;
    if (apiURL && filterParams) {
      const concatedFilterParams = filterParams.reduce((acc, value) => acc.concat(value), `&page=1&orderby=${sortedBy}&order=${order}`);
      const requestURL = apiURL.concat(concatedFilterParams)
      return requestURL;
    } else return `${process.env.REACT_APP_API_URL}wp-json/api/v1/post/list?size=10&page=1&userId=${user.id}&orderby=${sortedBy}&order=${order}`
  }

  sendRequest(requestURL: string, requestParams: object): void {
    const { setParentState } = this.props;
    fetch(requestURL, requestParams)
      .then(response => response.json())
      .then(content => setParentState({
        advertList: content.data,
        page: content.page,
        perPage: content.perPage,
        totalPages: content.totalPages,
        requestParams,
        isLoaded: true
      }))
      .catch(err => console.log(err));
  }

  sortAdverts(sortedBy: IComponentState['sortedBy'], order: IComponentState['order']) {
    const { setParentState, apiURL, filterParams, user } = this.props;
    this.setState({ sortedBy, order });
    const requestURL = this.setSortParams(sortedBy, order);
    const requestParams = apiURL && filterParams ? setRequestParams('GET') : setRequestParams('GET', user.token);
    setParentState({ isLoaded: false, sortedBy, order });
    this.sendRequest(requestURL, requestParams);
  }

  componentDidMount() {
    const { getPage, page, preferredCurrency } = this.props;
    this.setState({ displayPrice: preferredCurrency });
    const pageId = parseInt(page, 10);
    getPage(pageId);
  }

  componentWillReceiveProps(nextProps: IComponentProps) {
    if (nextProps.page !== this.props.page) {
      const { getPage, page } = nextProps;
      const pageId = parseInt(page, 10);
      getPage(pageId);
    }
    getExchangeRates().then((exchangeRates) => this.setState({ exchangeRates }));
  }

  async deleteAdvert(id: number) {
    const requestURL = setRequestUrl('wp-json/api/v1/post/', 'delete', `id=${id}`);
    const requestParams = setRequestParams('DELETE', this.props.user.token, {});

    await sendRequest(requestURL, requestParams);

    this.props.expired();
  }

  async publishAdvert(id: number) {
    const requestURL = setRequestUrl('wp-json/api/v1/post/', 'update', `id=${id}`);
    const requestParams = setRequestParams('PATCH', this.props.user.token, { status: 'publish' });

    await sendRequest(requestURL, requestParams);

    this.props.expired();
  }

  async draftAdvert(id: number) {
    const requestURL = setRequestUrl('wp-json/api/v1/post/', 'update', `id=${id}`);
    const requestParams = setRequestParams('PATCH', this.props.user.token, { status: 'draft' });

    await sendRequest(requestURL, requestParams);

    this.props.expired();
  }

  render() {
    const { advertList, page, totalPages, isLoaded } = this.props;
    const pageId = parseInt(page, 10);
    const adverts = advertList ? advertList.map(advert => (
      <ProductItem
        key={advert.id}
        advert={advert}
        displayPrice={this.state.displayPrice}
        exchangeRates={this.state.exchangeRates}
        deleteAdvert={this.deleteAdvert.bind(this)}
        publishAdvert={this.publishAdvert.bind(this)}
        draftAdvert={this.draftAdvert.bind(this)}
      />
    )) : <NoResult location={this.props.location} />;

    if (!isLoaded)
      return (<Loading isActive={!isLoaded} />)

    return (
      <div className="products-wrapper column">
        <div className="products-wrapper">
          <div className="product-list">
            {advertList
              ? <ProductsSort
                displayPrice={this.state.displayPrice}
                setSortParams={this.sortAdverts.bind(this)}
                handleDisplayPriceChange={this.handleDisplayPriceChange.bind(this)}
                sortedBy={this.state.sortedBy}
                order={this.state.order}
              />
              : null
            }
            {adverts}
            {advertList
              ? <Pagintation pageId={pageId} totalPages={totalPages} />
              : null
            }
          </div>
        </div>
      </div>
    )
  }
};

const mapStateToProps = (state: IStoreState) => ({
  preferredCurrency: state.preferredCurrency,
});

const mapDispatchToProps = {
  updateUserPreferredCurrency
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsList);
