import React from "react";
import megafoneImage from "../../../assets/img/no-advert-megaphone.png";
import { RouteComponentProps } from "@reach/router";
import store from "redux/store";
import { alertPhoneVerified } from "redux/actions/actions";
import { DisabledPhoneRoute } from "common/types";

interface IComponentProps {
  location: RouteComponentProps["location"];
}

const NoResult: React.FC<IComponentProps> = ({
  location,
}) => {
  if (location && location.pathname.includes("dashboard/ads"))
    return (
      <NoResultDasboard />
    );
  else return <NoResultPosts />;
};

const NoResultDasboard: React.FC = () => (
  <div className="dashboard__no-posts">
    <div className="image-container">
      <img src={megafoneImage} alt="megafone" />
    </div>
    <div className="action-container">
      <p>К сожалению, у Вас пока нет объявлений.</p>
      <button className="action-button link profile" onClick={() => store.dispatch(alertPhoneVerified(DisabledPhoneRoute.CreateAdvert))}>Подать объявление</button>
    </div>
  </div>
);

const NoResultPosts: React.FC = () => (
  <div className="no-result">
    <p>Ничего не найдено по вашему запросу, либо произошла ошибка</p>
    <p>Обновите страницу или измените параметры поиска</p>
  </div>
);

export default NoResult;
