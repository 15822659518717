import React from "react";
import { DisabledPhoneRoute } from "common/types";
import store from "redux/store";
import { alertPhoneVerified } from "redux/actions/actions";
import { navigate } from "@reach/router";

interface IComponentProps {
  buttonText: string;
  descriptionText: string;
  linkTo: string;
  illustration: any;
  disableRouteType?: DisabledPhoneRoute;
}

const ActionItemNoCompany: React.FC<IComponentProps> = ({
  buttonText,
  descriptionText,
  linkTo,
  illustration,
  disableRouteType
}) => {
  return (
    <div className="action-container">
      <div className="img-container">
        <img src={illustration} alt="staff" />
      </div>
      <div className="action-container column">
        <p>{descriptionText}</p>
        <button onClick={() =>
          disableRouteType ? store.dispatch(alertPhoneVerified(disableRouteType)) : navigate(linkTo)
        } className="action-button profile">
          {buttonText}
        </button>
      </div>
    </div >
  );
};

export default ActionItemNoCompany;
