import React from 'react';
import { RouteComponentProps } from '@reach/router';
import StaffItem from './StaffItem';
import PendingItem from './PendingItem';
import { ICompanyItem } from '../../../common/types';

interface IComponentProps extends RouteComponentProps {
  adminId: string,
  isUserAdmin: boolean,
  staff: ICompanyItem['users']['users'],
  pending: ICompanyItem['users']['pending'],
  acceptPendingEmployee: (pendingEmployeeId: number) => void,
  removeEmployee: (employeeId: number) => void
}

const CompanyEmployees: React.FC<IComponentProps> = ({ 
  staff, pending, acceptPendingEmployee, removeEmployee, isUserAdmin, adminId
}) => {
  return (
    <div className="company-dashboard-wrapper__company-employees">
      {!pending || pending.length === 0 ? null : 
        <PendingSection
          isUserAdmin={isUserAdmin}
          pending={pending}
          acceptPendingEmployee={acceptPendingEmployee}
          removeEmployee={removeEmployee}
        />
      }
      <StaffSection
        adminId={adminId}
        isUserAdmin={isUserAdmin}
        staff={staff}
        removeEmployee={removeEmployee}
      />
    </div>
  )
}

interface IPendingSectionProps {
  isUserAdmin: boolean,
  pending: ICompanyItem['users']['pending'],
  acceptPendingEmployee: (pendingEmployeeId: number) => void,
  removeEmployee: (employeeId: number) => void
}

const PendingSection: React.FC<IPendingSectionProps> = ({ pending, acceptPendingEmployee, removeEmployee, isUserAdmin }) => {
  const staffCollection = pending.map((item) => (
    <PendingItem
      isUserAdmin={isUserAdmin}
      employee={item}
      acceptPendingEmployee={acceptPendingEmployee}
      removeEmployee={removeEmployee}
      key={item.id}
    />
  ));

  return (
    <div className="staff-section">
      <h3>Заявки</h3>
      <div className="staff-grid">
        {staffCollection}
      </div>
    </div>
  )
}

interface IStaffSectionProps {
  adminId: string,
  isUserAdmin: boolean,
  staff: ICompanyItem['users']['users'],
  removeEmployee: (employeeId: number) => void
}

const StaffSection: React.FC<IStaffSectionProps> = ({ adminId, staff, removeEmployee, isUserAdmin }) => {
  const staffCollection = staff.map((item: any) => (
    <StaffItem 
      isCompanyAdmin={item.id === +adminId}
      isUserAdmin={isUserAdmin}
      employee={item} 
      removeEmployee={removeEmployee}
      key={item.id}
    />
  ));
  return (
    <div className="staff-section">
      <h3>Сотрудники</h3>
      <div className="staff-grid">
        {staffCollection}
      </div>
    </div>
  )
}

export default CompanyEmployees;
