export default function formatDate(dateString: string) {
  var monthNames = [
    "Января", "Февраля", "Марта",
    "Апреля", "Mая", "Июня", "Июля",
    "Августа", "Сентября", "Октября",
    "Ноября", "Декабря"
  ];
  let universalDateString = dateString.replace(/ /g,"T");
  universalDateString+='Z';
  const date = new Date(universalDateString);
  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();

  return day + ' ' + monthNames[monthIndex] + ' ' + year;
}
