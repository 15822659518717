import React from 'react';
import { RouteComponentProps, Link } from '@reach/router';
import WorkDaysBlock from '../workTime/WorkDaysDisplayBlock';
import { ICompanyItem } from '../../../common/types';

interface IComponentProps extends RouteComponentProps {
  companyData: ICompanyItem,
  isUserAdmin: boolean,
  userExit: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

const CompanyInformation: React.FC<IComponentProps> = ({ companyData: {
  location, contacts, unn, urName, urAddress, work_days
}, userExit, isUserAdmin}) => {
  const composedAdress = `${location.country}${location.city ? ', ' + location.city : ''}${location.address ? ', ' + location.address : ''}`;
  return (
    <div className="company-dashboard-wrapper__company-information">
      <div className="information">
        <div className="information column">
          <h3>Контактная информация</h3>
          <p>{composedAdress}</p>
          {contacts.site ?
            <a href={contacts.site}>{contacts.site}</a>
            : null
          }
          {contacts.email ?
            <a href={`mailto:${contacts.email}`}>{contacts.email}</a>
            : null
          }
          {contacts.phone ?
            <a href={`tel:${contacts.phone}`}>{contacts.phone}</a>
            : null
          }
        </div>
        <div className="information column">
          <h3>Данные юридического лица</h3>
          <p>{urName}</p>
          <p>УНП: {unn}</p>
          <p>{urAddress}</p>
        </div>
      </div>
      <div className="information column">
        <h3>Режим работы</h3>
        <WorkDaysBlock work_days={work_days} />
      </div>
      { isUserAdmin ? <Link to="../company-edit" className="action-button link profile edit"> Редактировать </Link> : null}
      <p className="leave"> Я больше здесь не работаю. <Link to="" onClick={()=>userExit}>Покинуть компанию</Link> </p>
    </div>
  );
}

export default CompanyInformation;
