import React, { useState } from 'react'
//@ts-ignore
import PhoneInput from 'react-phone-number-input/max';
//@ts-ignore
import { isValidPhoneNumber } from 'react-phone-number-input'
import metadata from '../../../metadata.custom.json';
import { navigate, RouteComponentProps } from '@reach/router';
import { confirmVerificationCode, requestConfirmationCode } from 'common/api-requests/phone-verification';
import { IUser } from 'common/types';

interface IComponentProps extends RouteComponentProps {
  user: IUser
  useCurrentNumber: boolean;
  getUser: (user: IUser) => any
}

const ChangePhoneNumberForm: React.FC<IComponentProps> = ({ user, useCurrentNumber, getUser }) => {
  const [newPhoneNumber, setNewPhoneNumber] = useState(useCurrentNumber ? user.phone : '');
  const [securityCode, setSecurityCode] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [isPhoneNumberSuccessfullyUpdated, setIsPhoneNumberSuccessfullyUpdated] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const isNewPhoneNumberValid = isValidPhoneNumber(newPhoneNumber);

  const requestConfirmation = () => {
    requestConfirmationCode(user.token, useCurrentNumber ? undefined : newPhoneNumber).then((result) => {
      if (!result) {
        setIsCodeSent(true);
      }
      setErrMsg(result || '');
    })
  }

  const confirmVerification = () => confirmVerificationCode(securityCode, user.token).then((result) => {
    if (result) {
      setErrMsg(result);
      getUser({ ...user, phone_verified: false });
      return;
    }
    setIsPhoneNumberSuccessfullyUpdated(true);
    // TODO: redux for it
    getUser({ ...user, phone_verified: true, phone: newPhoneNumber.replace("+", "") });
    navigate("./");
  });

  if (useCurrentNumber && !isCodeSent) {
    setIsCodeSent(true);
    requestConfirmation();
  }

  return (
    <form className="change-user-data-container">
      <h3>{useCurrentNumber ? 'Подтверждение' : 'Изменение'} номера телефона</h3>
      {isCodeSent ?
        <p>На указанный номер телефона было выслано SMS с кодом.
        Введите код, чтобы изменить номер телефона.
        </p>
        :
        <p>Ваш аккаунт привязяан к номеру телефона. Укажите новый номер телефона,
        и мы вышлем на него проверочный код. После того как вы введете проверочный код,
        номер телефона будет обновлен.
        </p>
      }

      {isCodeSent ?
        <div className="data-block">
          <div className="input-container">
            <label htmlFor="securityCode">Код подтверждения</label>
            <input
              className="person-input"
              type="text"
              value={securityCode}
              name="securityCode"
              onChange={(event) => setSecurityCode(event.currentTarget.value)}
            />
          </div>
          <button
            type="button"
            className="action-button profile"
            onClick={async () => confirmVerification()}
          >
            Подтвердить
          </button>
        </div>
        :
        <div className="data-block flex-end">
          <div className="input-container">
            <label htmlFor="userPhoneNumber">Новый номер телефона</label>
            <PhoneInput
              value={newPhoneNumber}
              style={{ border: isNewPhoneNumberValid ? '1px solid green' : undefined }}
              metadata={metadata}
              onChange={(value: any) => {
                setErrMsg('');
                setNewPhoneNumber(value);
              }
              }
            />
          </div>
          <button
            type="button"
            className="action-button profile"
            onClick={async () => {
              if (isNewPhoneNumberValid && newPhoneNumber !== '') {
                await requestConfirmation();
              }
              else setErrMsg("Введите корректный номер телефона!")
            }
            }
          >
            Получить код
          </button>
        </div>
      }


      {isPhoneNumberSuccessfullyUpdated ? <p style={{ color: 'green', margin: '0.5rem 0' }}>Телефон успешно изменен!</p> : null}
      <p style={{ color: 'red', margin: '0.5rem 0' }}>{errMsg}</p>
    </form>
  );
}

export default ChangePhoneNumberForm;
