import React, { useState } from "react";
import Modal from "react-modal";
import closeIcon from "assets/icons/close-icon.png";
import { navigate } from "@reach/router";
import { DisabledPhoneRoute } from "common/types";
import { alertPhoneVerified } from "redux/actions/actions";
import store from "redux/store";
import "assets/styles/popup.scss";

interface IComponentProps {
  phone: string;
  routeType?: DisabledPhoneRoute;
  isPhoneVerified: boolean;
}

function getRoute(routeType: DisabledPhoneRoute): string {
  switch (routeType) {
    case DisabledPhoneRoute.CreateAdvert:
      return "/dashboard/create-advert";
    case DisabledPhoneRoute.CreateCompany:
      return "/dashboard/company/company-registration";
    case DisabledPhoneRoute.CreateVacancy:
      return "/dashboard/create-vacancy";
  }
}

export const DisableByPhoneAlert: React.FC<IComponentProps> = ({ routeType, phone, isPhoneVerified }) => {
  const needOpenModal = !!routeType && !isPhoneVerified;
  const [isModalOpen, setOpenModel] = useState(needOpenModal);

  if (isModalOpen !== needOpenModal) {
    setOpenModel(needOpenModal);
  }

  const closeAlert = () => {
    store.dispatch(alertPhoneVerified());
    setOpenModel(false);
  };

  if (isPhoneVerified && !!routeType) {
    navigate(getRoute(routeType));
    store.dispatch(alertPhoneVerified());
  }

  const sendSms = () => {
    closeAlert();
    navigate("/dashboard/validate-phone-number");
  };

  const changePhone = () => {
    closeAlert();
    navigate("/dashboard/change-phone-number");
  };

  return (
    <Modal
      isOpen={isModalOpen}
      className="modal-confirm"
      overlayClassName="modal-confirm-overlay"
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onRequestClose={closeAlert}
    >
      <div className="wrong-password-popup">
        <img
          src={closeIcon}
          alt="close"
          onClick={closeAlert}
        />
        <p className="title">
          Для подачи объявления вам необходимо сначала подтвердить номер
          телефона. Убедитесь, что вы указали его верно или смените его.
            </p>
        <p className="phone-title">+{phone}</p>

        <button
          onClick={sendSms}
          className="primary-btn"
        >
          Отправить СМС с кодом
      </button>

        <button
          onClick={changePhone}
          className="secondary-btn"
        >
          Изменить номер телефона
      </button>
      </div>
    </Modal>);
};
