import { IStoreState } from './common/types';

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
}; 

export const saveUserSession = async (user:IStoreState['user'] | null) => {
  try {
    const serializedUser = JSON.stringify({ user });
    localStorage.setItem('state', serializedUser);
  } catch {
    // ignore write errors
  }
};

export const saveState = async (state:IStoreState) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch {
    // ignore write errors
  }
};
