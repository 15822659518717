import React, { Fragment } from 'react';
import { Link } from '@reach/router';


interface IMatchesProps {
    deleteAction: () => void,
    fromCompanyList?: boolean,
    id?: number
}

const UserMatchesActionComponent: React.FC<IMatchesProps> = ({ deleteAction, fromCompanyList, id }): any => [
    <Fragment key={id}>
        {fromCompanyList ?
            (<Link
                to={`./${id}/delete`}
                className="action-button delete"
                key={`delete-vacancy-link-${id}`}
            >
                Удалить
            </Link>
            )
            :
            (<button
                type="button"
                className="action-button delete"
                onClick={deleteAction}
                key={`delete-vacancy-button-${id}`}
            >
                Удалить вакансию
            </button>
            )}
    </Fragment>,
    <Link
        to={fromCompanyList ? `./${id}/edit` : './edit'}
        className="action-button link"
        key="edit-advert-button"
    >
        {fromCompanyList ? ('Изменить') : 'Изменить вакансию'}
    </Link>
];

export default UserMatchesActionComponent;
