import React, { Component } from 'react';
import { RouteComponentProps } from '@reach/router';
import { connect } from 'react-redux';
import NotFound from '../../common/NotFound';
import Loading from '../../common/loading';
import Deleting from '../../components/products/utils/Deleting';
import Deleted from '../../components/products/utils/Deleted';
import VacancyDetailHeader from '../../components/vacancies/vacancy-detail/VacancyDetailHeader';
import VacancyDetailMain from '../../components/vacancies/vacancy-detail/VacancyDetailMain';
import VacancyLeftColumn from '../../components/vacancies/vacancy-detail/VacancyLeftColumn';
import setRequestUrl from '../../common/utilits/setRequestUrl';
import setRequestParams from '../../common/utilits/setRequestParams';
import sendRequest from '../../common/utilits/sendRequest';
// import getExchangeRates from '../../common/utilits/getExchangeRates';
import { IStoreState, IVacancyItem, TPreferredCurrency, IExchangeRates } from '../../common/types';
import '../../assets/styles/products/products-detail.scss';

import UserMatchesActionComponent from './UserMatchesActionComponent';

interface IComponentProps extends RouteComponentProps {
    vacancyId: string,
    isAuthorized: boolean,
    preferredCurrency: TPreferredCurrency,
    user: IStoreState['user']
}

interface IComponentState {
    isLoaded: boolean,
    isDeleting: boolean,
    isDeleted: boolean,
    isNotFound: boolean,
    vacancy: IVacancyItem | any,
    exchangeRates?: IExchangeRates
}

class VacancyDetail extends Component<IComponentProps, IComponentState> {

    readonly state: IComponentState = {
        isLoaded: false,
        isDeleting: false,
        isDeleted: false,
        isNotFound: false,
        vacancy: {}
    }

    static matchUser(user: IStoreState['user'], isAuthorized: boolean, vacancy: string | number ) {
        if (isAuthorized) return vacancy === user.phone ? true : false;
        return false;
    }

    setIsDeleting() {
        this.setState((prevState) => ({ isDeleting: !prevState.isDeleting }));
    }

    async getVacancy(requestURL: string, requestParams: object) {
        const content = await sendRequest(requestURL, requestParams);
        if (content.errMsg) this.setState({ isNotFound: true, isLoaded: true });
        else this.setState({ vacancy: content, isLoaded: true })
    }

    async deleteVacancy() {
        const { user, vacancyId, isAuthorized } = this.props;
        const { vacancy } = this.state;
        const phoneVacancy = vacancy.author.user.contacts.phone;
        const isUserMatch = VacancyDetail.matchUser(user, isAuthorized, phoneVacancy);

        if (isUserMatch) {
            const requestURL = setRequestUrl('wp-json/api/v1/vacancy/', 'delete', `id=${vacancyId}`);
            const requestParams = setRequestParams('DELETE', user.token);
            await sendRequest(requestURL, requestParams);
            this.setState({ isDeleting: false, isDeleted: true })
        }
    }


    componentDidMount() {
        const { user, vacancyId, isAuthorized } = this.props;
        const requestURL = setRequestUrl('wp-json/api/v1/vacancy/', 'single', `id=${vacancyId}`);
        const requestParams = isAuthorized ? setRequestParams('GET', user.token) : setRequestParams('GET');
        this.getVacancy(requestURL, requestParams);

        //getExchangeRates().then((exchangeRates) => this.setState({ exchangeRates }));
    }

    componentWillReceiveProps(nextProps: IComponentProps) {
        const { user, vacancyId, isAuthorized } = nextProps;
        const requestURL = setRequestUrl('wp-json/api/v1/vacancy/', 'single', `id=${vacancyId}`);
        const requestParams = isAuthorized ? setRequestParams('GET', user.token) : setRequestParams('GET');
        this.getVacancy(requestURL, requestParams);
    }

    render() {
        const { isAuthorized, user } = this.props;
        const {
            isLoaded, isNotFound, vacancy, isDeleting, isDeleted
        } = this.state;

        if (vacancy && vacancy.id === null) return (<NotFound />);

        const {
            id,
            published,
            title,
            location,
            author,
            full,
            price,
        } = vacancy;


        if (!isLoaded)
            return (<Loading isActive={!isLoaded} />);

        if (isNotFound)
            return (<NotFound />);

        const phoneVacancy = vacancy.author.user.contacts.phone;
        const isUserMatch = VacancyDetail.matchUser(user, isAuthorized, phoneVacancy);


        if (isDeleting)
            return (
                <Deleting
                    deleteAdvert={this.deleteVacancy.bind(this)}
                    setIsDeleting={this.setIsDeleting.bind(this)}
                />
            );

        if (isDeleted)
            return (<Deleted />);

        const leftColumnProps = {
            company: vacancy.author.company,
            user: vacancy.author.user
        };


        const mainProps = {
            full,
            experience: vacancy.experience[0],
            work: vacancy.work[0],
            employment: vacancy.employment[0],
            schedule: vacancy.schedule[0],
            author,
        };

        const headerProps = {
            id,
            companyId: author.company.id,
            companyName: author.company.urName,
            published,
            title,
            price,
            city: location.city
        };

        const pathname = this.props.location ? this.props.location.pathname : '';
        return (
            <div className="vacancy-detail-wrapper" style={{ width: pathname.includes('dashboard/ads') ? '100%' : undefined }}>
                <VacancyLeftColumn {...leftColumnProps} />
                <div className="vacancy-detail-body">
                    <VacancyDetailHeader {...headerProps} />

                    {isUserMatch
                        ? (<div className="vacancy-detail-control">
                            <UserMatchesActionComponent deleteAction={this.setIsDeleting.bind(this)} key={`vacancy-edit-${id}`} />
                        </div>)
                        : null
                    }

                    <VacancyDetailMain {...mainProps} />
                </div>
            </div>
        );
    }
}



const mapStateToProps = (state: IStoreState) => ({
    preferredCurrency: state.preferredCurrency
});

export default connect(mapStateToProps)(VacancyDetail);
