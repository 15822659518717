import fiveStars from '../../assets/img/5Stars.png';
import fourStars from '../../assets/img/4Stars.png';
import threeStars from '../../assets/img/3Stars.png';
import twoStars from '../../assets/img/2Stars.png';
import oneStars from '../../assets/img/1Stars.png';
 
export default function getConditionState(condition:string) {
  switch(condition) {
    case '1':
      return { image: oneStars, text: 'Ужасное' };
    case '2':
      return { image: twoStars, text: 'Плохое' };
    case '3':
      return { image: threeStars, text: 'Среднее' };
    case '4':
      return { image: fourStars, text: 'Хорошее' };
    case '5':
      return { image: fiveStars, text: 'Отличное' };
    default:
      return null;
  }
}
