import React, { Component } from 'react';
import { RouteComponentProps } from '@reach/router';
import Loading from '../../common/loading';
import NotFound from '../../common/NotFound';
import setRequestUrl from '../../common/utilits/setRequestUrl';
import setRequestParams from '../../common/utilits/setRequestParams';
import sendRequest from '../../common/utilits/sendRequest';
import getExchangeRates from '../../common/utilits/getExchangeRates';
import { IStoreState, IExchangeRates, IVacancyItem } from '../../common/types';
import VacancyForm from '../../components/vacancies/VacancyForm';
import Posted from '../../components/vacancies/utils/VacancyPostedMessage';


interface IComponentProps extends RouteComponentProps {
    user: IStoreState['user'],
    vacancyId: string,
    fromCompanyList?: boolean
}

interface IComponentState {
    payload?: IVacancyItem,
    isPosted: boolean,
    isLoading: boolean,
    isError: boolean,
    errMsg: string,
    exchangeRates: IExchangeRates
}

class EditVacancy extends Component<IComponentProps, IComponentState> {
    readonly state: IComponentState = {
        isPosted: false,
        isLoading: false,
        isError: false,
        errMsg: '',
        exchangeRates: {
            usd: 2,
            eur: 2.3,
            rub: 0.039
        },
    }

    async updateVacancy(composedData: object) {
        const { vacancyId, user: { token } } = this.props;


        const requestParams = setRequestParams('PATCH', token, composedData);
        const requestURL = setRequestUrl('wp-json/api/v1/vacancy/', 'update', `id=${vacancyId}`);

        this.setState({ isLoading: true });

        const content = await sendRequest(requestURL, requestParams);

        if (content.errMsg) {
            this.setState({
                errMsg: content.errMsg,
                isError: true,
                isPosted: false,
                isLoading: false
            })
        } else {
            this.setState({
                payload: {
                    ...content
                },
                isPosted: true,
                isLoading: false
            })
        }
    }


    async getVacancy() {
        const { user, vacancyId } = this.props;
        let requestParams = setRequestParams('GET', user.token);
        let requestURL = setRequestUrl('wp-json/api/v1/vacancy/', 'single', `id=${vacancyId}`);

        const content = await sendRequest(requestURL, requestParams);

        if (content.errMsg) {
            this.setState({
                errMsg: content.errMsg,
                isError: true,
                isPosted: false,
                isLoading: false
            })
        } else {
            this.setState({
                payload: {
                    ...content
                },
                isLoading: false
            });
        }
    }



    componentDidMount() {
        getExchangeRates().then(exchangeRates => this.setState({ exchangeRates }));
        this.getVacancy();
    }

    render() {
        const { isLoading, isPosted, isError, errMsg, payload } = this.state;
        const { user, fromCompanyList } = this.props;

        if (!user)
            return (<NotFound />)

        if (isLoading)
            return (<Loading isActive={isLoading} />)

        if (isPosted)
            return (<Posted isEdit={true} fromCompanyList={fromCompanyList}/>)

        if (isError)
            return (<div>{errMsg}</div>)

        if (payload) {

            return (
                <VacancyForm
                    createVacancy={this.updateVacancy.bind(this)}
                    exchangeRates={this.state.exchangeRates}
                    payload={this.state.payload}
                />
            )
        }

        return (<Loading isActive={isLoading} />);

    }
}

export default EditVacancy;
