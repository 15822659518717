import React, { Component } from 'react';
import LogInFormPopUp from './LogInFormPopUp';
import ResetPasswordPopUp from './ResetPasswordPopUp';
import ResetInfoPopUp from './ResetInfoPopUp';

interface IComponentProps {
  handleUserLoginChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  handlePasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  handleEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  errMsg: string | undefined,
  userLogin: string,
  userPassword: string,
  userEmail: string,
  switchActive: (isActive:boolean) => void,
  toggle: (isActive:boolean) => void,
  isActive: boolean,
  resetPassword: () => any,
  logIn: () => any
}

interface IComponentState {
  isResetPswActive: boolean,
  isReseted: boolean,
}

class LogInPopUpController extends Component<IComponentProps, IComponentState> {
  readonly state: IComponentState = {
    isResetPswActive: false,
    isReseted: false
  }

  toggleReset() {
    this.setState({
      isResetPswActive: true
    })
  }

  toggleResetInfo() {
    if (!this.props.errMsg) {
      this.setState({
        isResetPswActive: false,
        isReseted: true
      })
    }
  }

  abortReset() {
    this.setState({
      isResetPswActive: false,
      isReseted: false
    })
  }

  render() {
    const {
      handleEmailChange,
      handlePasswordChange,
      handleUserLoginChange,
      logIn,
      resetPassword,
      userEmail,
      errMsg,
      userPassword,
      userLogin,
      switchActive,
      toggle,
      isActive
    } = this.props;

    const {
      isReseted,
      isResetPswActive
    } = this.state;

    if (isResetPswActive) return (
      <ResetPasswordPopUp
        handleEmailChange={handleEmailChange}
        toggle={toggle}
        isActive={isActive}
        errMsg={errMsg}
        userEmail={userEmail}
        resetPassword={resetPassword}
        abortReset={this.abortReset.bind(this)}
        toggleResetInfo={this.toggleResetInfo.bind(this)}
      />
    );

    if (isReseted) return (
      <ResetInfoPopUp
        toggle={toggle}
        isActive={isActive}
        abortReset={this.abortReset.bind(this)}
      />
    );

    return (
      <LogInFormPopUp
        handlePasswordChange={handlePasswordChange}
        handleUserLoginChange={handleUserLoginChange}
        logIn={logIn}
        userPassword={userPassword}
        userLogin={userLogin}
        errMsg={errMsg}
        switchActive={switchActive}
        toggle={toggle}
        isActive={isActive}
        toggleReset={this.toggleReset.bind(this)}
      />
    );
  }
}

export default LogInPopUpController;
