import React, { Component } from 'react';
import { RouteComponentProps } from '@reach/router';
import { IVacancyFilter, IVacancyCategories } from '../../common/types';
import closeIcon from '../../assets/icons/close-icon.png';
import setRequestUrl from '../../common/utilits/setRequestUrl';
import setRequestParams from '../../common/utilits/setRequestParams';
import sendRequest from '../../common/utilits/sendRequest';
import '../../assets/styles/products/filter.scss';

interface IComponentProps {
    location: RouteComponentProps['location'],
    sendFilterParams: (filterParams: Array<string>) => any,
    setIsFilterOpen: () => void,
    isFilterOpen: boolean,
    token: string | undefined
}

interface IComponentState {
    payload: IVacancyFilter,
    vacancyCategories: IVacancyCategories
}

class ProductsFilter extends Component<IComponentProps, IComponentState> {
    static names: { [key: string]: string } = {
        category: 'Категория',
        experience: 'Опыт работы',
        work: 'Характер работы',
        employment: 'Занятость',
        schedule: 'График работы'
    }
    readonly state: IComponentState = {
        payload: {
            filters: {
                category: [],
                experience: [],
                work: [],
                employment: [],
                schedule: [],
            },
            city: '',
            priceMin: 0,
            priceMax: 0
        },
        vacancyCategories: {
            category: [],
            experience: [],
            work: [],
            employment: [],
            schedule: [],
        }
    };

    handleCheckbox(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const value: string = event.currentTarget.value;
        const fieldName: string = event.currentTarget.name;

        this.setState({
            payload: {
                ...this.state.payload,
                filters: {
                    ...this.state.payload.filters,
                    [fieldName]: value ? [value] : []
                }
            }
        })
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const value: string = event.currentTarget.value;
        const fieldName: string = event.currentTarget.name;
        this.setState({ payload: { ...this.state.payload, [fieldName]: value } });
    }

    async getVacanciesCategory() {
        const argMap: { [index: string]: any; } = {
            category: 'filter[category]',
            experience: 'filter[experience]',
            work: 'filter[work]',
            employment: 'filter[employment]',
            schedule: 'filter[schedule]'
        };
        const requestURL = setRequestUrl('wp-json/api/v1/vacancy/', 'list');
        const requestParams = setRequestParams('OPTIONS');
        const content = await sendRequest(requestURL, requestParams);
        if (content.errMsg) {
            console.log('failed to fetch vacancies options')
            return;
        }
        let filters: IVacancyCategories = {
            category: [],
            experience: [],
            work: [],
            employment: [],
            schedule: [],
        };
        for (var key in argMap) {
            filters[key] = content.endpoints[0].args[argMap[key]].enum
        }
        this.setState({ vacancyCategories: filters })
    }

    createFilterCheckboxSection(fieldNames: string[], heading: string, name: string) {
        const options = fieldNames.map((item) => {
            return <option value={item} key={item}>{item}</option>
        });
        options.unshift(<option value="" key={0}></option>)
        const categoriesBlock =
            <div className="categories vacancy" key = {name}>
                <h4>{heading}</h4>
                <select
                    className="filter-item"
                    onChange={this.handleCheckbox.bind(this)}
                    name={name}
                //@ts-ignore
                // value={this.state.payload.filters[name]}
                >
                    {options}
                </select>
            </div>

        return categoriesBlock;
    }

    setParamsPrefix() {
        this.props.setIsFilterOpen();
        window.scrollTo(0, 0);
        const { sendFilterParams } = this.props;
        const validatedState = {
            category: this.state.payload.filters.category,
            experience: this.state.payload.filters.experience,
            work: this.state.payload.filters.work,
            employment: this.state.payload.filters.employment,
            schedule: this.state.payload.filters.schedule,
            priceMin: this.state.payload.priceMax !== 0 && this.state.payload.priceMin === 0 ? '1' : this.state.payload.priceMin,
            priceMax: this.state.payload.priceMin !== 0 && this.state.payload.priceMax === 0 ? '100000000000000000' : this.state.payload.priceMax,
        }
        const prefixedState = {
            category: `&filter[category]=${validatedState.category}`,
            experience: `&filter[experience]=${validatedState.experience}`,
            work: `&filter[work]=${validatedState.work}`,
            employment: `&filter[employment]=${validatedState.employment}`,
            schedule: `&filter[schedule]=${validatedState.schedule}`,
            priceMin: `&filter[price][from]=${validatedState.priceMin}`,
            priceMax: `&filter[price][to]=${validatedState.priceMax}`,
        }
        const searchParams: any[any] = [];
        Object.keys(validatedState).forEach(key => {
            //@ts-ignore
            if (validatedState[key].length > 0) {
                //@ts-ignore
                searchParams.push(prefixedState[key]);
            }
        })
        sendFilterParams(searchParams);
    }

    componentDidMount() {
        this.getVacanciesCategory()
    }

    componentWillReceiveProps() {
        this.getVacanciesCategory()
    }

    static checkVisibilityForLocation(location: RouteComponentProps['location']) {
        if (location) {
            if (location.pathname.split('/').length === 3) return true;
            else return false;
        }
        return false;
    }

    render() {
        const { location, setIsFilterOpen, isFilterOpen } = this.props;
        const isVisible = ProductsFilter.checkVisibilityForLocation(location);
        const headers = Object.keys(this.state.vacancyCategories).map(key => (
            this.createFilterCheckboxSection(this.state.vacancyCategories[key], ProductsFilter.names[key], key)
        ));
        return (
            <form
                className="filter"
                style={{
                    display: isVisible ? undefined : 'none',
                    left: isFilterOpen ? '0' : undefined,
                }}
            >
                <h3>Фильтр</h3>
                <img
                    className="close-button"
                    src={closeIcon}
                    alt="close filter"
                    onClick={setIsFilterOpen}
                />
                <div>

                    {headers}

                    <label>Зарплата</label>
                    <div className="input-horizontal-block" >
                        <input
                            className="filter-item left"
                            type="text"
                            name="priceMin"
                            placeholder="От"
                            value={this.state.payload.priceMin === 0 ? '' : this.state.payload.priceMin}
                            onChange={this.handleChange.bind(this)}
                        />
                        <input
                            className="filter-item right"
                            type="text"
                            name="priceMax"
                            placeholder="До"
                            value={this.state.payload.priceMax === 0 ? '' : this.state.payload.priceMax}
                            onChange={this.handleChange.bind(this)}
                        />
                    </div>

                    <button
                        type="button"
                        className="action-button"
                        onClick={this.setParamsPrefix.bind(this)}
                    >
                        Показать результаты
                    </button>
                </div>
            </form>
        )
    }
};

export default ProductsFilter;
