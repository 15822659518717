import React from 'react';
import noPhoto from '../../../assets/img/nophoto-profile.png';
import { IUser } from '../../../common/types';

interface IPendingItem {
  isUserAdmin: boolean,
  employee: IUser | any,
  acceptPendingEmployee: (pendingEmployeeId: number) => void,
  removeEmployee: (employeeId: number) => void
}

const PendingItem: React.FC<IPendingItem> = ({ employee: {
  phone, id, user_avatar, user_details: { first_name, last_name }
}, acceptPendingEmployee, removeEmployee, isUserAdmin }) => {
  return (
    <div className="item">
      <div className="item-card">
        <div className="image-container">
          <img src={user_avatar ? user_avatar : noPhoto} alt="avatar"/>
        </div>
        <div className="text-container">
          <p>{first_name} {last_name}</p>
          <a href={`tel:+${phone}`}>+{phone}</a>
        </div>
      </div>
      {isUserAdmin ?
        <div className="actions-container">
          <p 
            className="decline"
            onClick={() => removeEmployee(id)}
          >
            Отказать
          </p>
          <p
            className="accept"
            onClick={() => acceptPendingEmployee(id)}
          >
            Принять запрос
          </p>
        </div>
      : null }
    </div>
  )
}

export default PendingItem;
