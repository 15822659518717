import { TPreferredCurrency } from '../types';

type TPrice = {
  usd?: string,
  rub?: string,
  eur?: string,
  byn?: string
}

export default function getPriceToDisplay(displayPrice: TPreferredCurrency, price: TPrice, isOptionalPrice: boolean) {
  if (isOptionalPrice) {
    return "Цена по запросу";
  }
  const reducerCallback = (acc: string, item: string, index: number) => {
    if (++index % 3 === 0) return acc + item + ' ';
    else return acc + item;
  }
  switch (displayPrice) {
    case 'USD':
      if (price.usd) return `${price.usd.split('').reverse().reduce(reducerCallback).split('').reverse().reduce((acc, item) => acc + item)} USD`;
      break;
    case 'RUB':
      if (price.rub) return `${price.rub.split('').reverse().reduce(reducerCallback).split('').reverse().reduce((acc, item) => acc + item)} RUB`;
      break;
    case 'EUR':
      if (price.eur) return `${price.eur.split('').reverse().reduce(reducerCallback).split('').reverse().reduce((acc, item) => acc + item)} EUR`;
      break;
    default:
      if (price.byn) return `${price.byn.split('').reverse().reduce(reducerCallback).split('').reverse().reduce((acc, item) => acc + item)} BYN`;
  }
  return '';
}
