import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import App from './App';
import store from './redux/store';
import './assets/styles/index.scss';
import './assets/styles/phone-unput.css';
require('dotenv').config();

ReactDOM.render(
  <Provider store={store} >
    <App />
  </Provider>,
document.getElementById('root'));

serviceWorker.unregister();
