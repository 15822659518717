import React from 'react';
import { Link } from '@reach/router';

interface IComponentProps {
  fromCompanyList?: boolean
}


const Deleted: React.FC<IComponentProps> = ({ fromCompanyList }) => (
  <div className="deleted">
    <p>Объявление успешно удалено</p>
    <Link to={fromCompanyList ? "../../" : "../"}>Вернуться к списку объявлений</Link>
  </div>
);

export default Deleted;
