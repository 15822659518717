import {
  GET_USER,
  EXIT_USER,
  UPDATE_USER_DETAILS,
  GET_FILTER_PARAMS,
  UPDATE_USER_PREFERRED_CURRENCY,
  ALERT_NEW_USER,
  ALERT_PHONE_VERIFIED,
} from "./consts";
import {
  IUser,
  IUserDetails,
  IAdvertFilter,
  TPreferredCurrency,
  INewUserAlert,
  IPhoneVerifiedAlert,
  DisabledPhoneRoute,
} from "../../common/types";

export function getUser(user: IUser) {
  return { type: GET_USER, user };
}

export function exitUser() {
  return { type: EXIT_USER, user: null };
}

export function updateUser(user_details: IUserDetails) {
  return { type: UPDATE_USER_DETAILS, user_details };
}

export function getFilterParams(filterParams: IAdvertFilter) {
  return { type: GET_FILTER_PARAMS, filterParams };
}

export function updateUserPreferredCurrency(
  preferredCurrency: TPreferredCurrency
) {
  return { type: UPDATE_USER_PREFERRED_CURRENCY, preferredCurrency };
}

export function alertNewUser(showAlert: boolean): INewUserAlert {
  return { type: ALERT_NEW_USER, showAlert };
}

export function alertPhoneVerified(
  routeType?: DisabledPhoneRoute
): IPhoneVerifiedAlert {
  return { type: ALERT_PHONE_VERIFIED, routeType };
}
