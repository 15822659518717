import React, { useState } from 'react'
import { RouteComponentProps } from '@reach/router';

const ChangeEmailForm: React.FC<RouteComponentProps> = () => {
  const [newEmail, setNewEmail] = useState('');
  //const [errMsg, setErrMsg] = useState('');
  //const [isEmailSuccessfullyUpdated, setIsEmailSuccessfullyUpdated] = useState(false);

  const reg = /^[A-Z0-9._%+-]+@[A-Z0-9-]+\.[A-Z]{2,4}$/i;
  const isNewEmailValid = reg.test(newEmail);

  return (
    <form className="change-user-data-container">
      <h3>Изменение адреса электронной почты</h3>
      <p>Для изменения адреса электронной почты введите новый адрес.
        На этот адрес мы вышлем сообщение со ссылкой, пройдя по которой вы подтвердите,
        что новый адрес принадлежит именно вам.
        При этом старый адрес больше не будет нами использоваться.
      </p>

      <div className="data-block">
        <div className="input-container">
          <label htmlFor="newEmail">Новый e-mail</label>
          <input
            className={ isNewEmailValid || newEmail === '' ? "person-input" : "person-input invalid" }
            type="email"
            value={newEmail}
            name="newEmail"
            onChange={(event) => setNewEmail(event.currentTarget.value)}
          />
          { isNewEmailValid || newEmail === '' ? null : <p className="form-invalid-indicator">E-mail неверный!</p> }
        </div>
        <button
          type="button"
          className="action-button profile"
          onClick={() => 0}
        >
          Изменить
        </button>
      </div>
      

      {/* {isEmailSuccessfullyUpdated ? <p style={{ color: 'green', margin: '0.5rem 0'}}>Сообщение успешно отправлено на почту!</p> : null} */}
      {/* <p style={{ color: 'red', margin: '0.5rem 0'}}>{errMsg}</p> */}
    </form>
  );
}

export default ChangeEmailForm;
