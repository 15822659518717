export default async function getExchangeRates() {
  const state = {
    usdCode: 145,
    eurCode: 292,
    rubCode: 298,
    apiUrl: `${process.env.REACT_APP_API_URL}wp-json/api/v1/rates`
  }

  const response = await fetch(state.apiUrl);
  let content;
  try {
    content = await response.json();
    content = {
      usd: JSON.parse(content.usd),
      eur: JSON.parse(content.eur),
      rub: JSON.parse(content.rub)
    };
  } catch (error) {

  }

  if (!content)
    return { usd: 2.5426, eur: 3.0073, rub: 0.034546 }
  else
    return {
      usd: content.usd.Cur_OfficialRate,
      eur: content.eur.Cur_OfficialRate,
      rub: content.rub.Cur_OfficialRate / 100
    };
}
