import React, { useRef } from 'react';

interface IResetPopUpProps {
  handleEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  errMsg: string | undefined,
  userEmail: string,
  resetPassword: () => any,
  abortReset: () => void,
  toggleResetInfo: () => void,
  toggle: (isActive:boolean) => void,
  isActive: boolean
}

const ResetPasswordPopUp:React.FC<IResetPopUpProps> = (props) => {
  const modalRef = useRef(null);
  const {
    handleEmailChange,
    resetPassword,
    errMsg,
    userEmail,
    abortReset,
    toggleResetInfo,
    toggle,
    isActive
  } = props;
  const handleDisable = (event: React.MouseEvent<HTMLDivElement>) => {
    if (modalRef.current === event.target) toggle(!isActive);
  }
  return (
    <div className="modal login" onClick={handleDisable} ref={modalRef}>
      <form 
        className="pop-up reset"
      >
        <h2>Восстановление пароля</h2>
        <div className="content-wrapper">
          <div style={{ padding: '1rem', textAlign: 'left' }}>
            <input
              className="auth-input"
              type="email"
              value={userEmail}
              placeholder="Введите e-mail"
              name="email"
              onChange={handleEmailChange}
              required
            />
          
            {errMsg ? <p style={{color: 'red'}}>{errMsg}</p> : <></>}
            <div className="buttons-container reset">
              <button 
                type="button"
                className="action-button"
                onClick={ async () => { await resetPassword(); toggleResetInfo(); }}
              >
                Восстановить
              </button>
              <button 
                type="button"
                onClick={abortReset}
                className="action-button secondary"
                style={{ margin: '0' }}
              >
                Отмена
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
};

export default ResetPasswordPopUp;
