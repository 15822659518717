import React, {ChangeEvent, useState} from 'react';
import Loading from '../../common/loading';
import ImageItem from './ImageItem';
import getConditionState from '../../common/utilits/getConditionState';
import setCalculatedPrice from '../../common/utilits/setCalculatedPrice';
import photoPicture from '../../assets/img/icon-photo.png';
import {IAdvertPayload, IAdvertCategories, IExchangeRates, IStoreState} from '../../common/types';

interface IImageItem {
  id: number,
  data: string,
  url: string,
  main: boolean
}

interface IComponentProps {
  location: {
    title: string,
    button: string
  },
  payload: IAdvertPayload,
  advertCategories: IAdvertCategories,
  exchangeRates: IExchangeRates,
  inputImagesRef: any,
  handlers: {
    handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void,
    clearShortItem: (event: React.ChangeEvent<HTMLSelectElement>) => void,
    handleLocationChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    handlePriceChange: (event: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>) => void,
    handleImagesClick: () => void,
    handleImagesChange: () => void,
    handleCategoryChange: (event: React.ChangeEvent<HTMLSelectElement>) => void,
    handleRadioChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    handleImportFromMascus: (id: string) => void
  },
  setMainImage: (event: React.MouseEvent<HTMLDivElement>) => void,
  deleteImage: (event: React.MouseEvent<HTMLDivElement>) => void,
  sendAdvert: (validatedPayload: IAdvertPayload) => void,
  user: IStoreState['user'],
  isEdit?: boolean
}

const AdvertForm: React.FC<IComponentProps> = (props) => {
  const [validity, setValidity] = useState({
    isVendorValid: true,
    isModelValid: true,
    isVendorProposedValid: true,
    isModelProposedValid: true,
    isCategoryValid: true,
    isSnValid: true,
    isPriceValid: true,
    isYearValid: true,
    isHoursValid: true,
    isFullValid: true
  });

  const [snTemplate, setSnTemplate] = useState('');
  const [vendorCheckbox, setVendorCheckbox] = useState(false);

  const {
    location,
    payload,
    advertCategories,
    inputImagesRef,
    handlers,
    setMainImage,
    deleteImage,
    sendAdvert,
    exchangeRates,
    isEdit
  } = props;

  const priceReg = /^\d{1,}$/;

  const checkValidity = () => {
    if (payload.category === '' || payload.vendor === '' || payload.model === ''
      || payload.sn === '' || !priceReg.test(payload.price.value) || payload.year === ''
      || payload.hours === '' || payload.full === ''
    ) {
      setValidity({
        isVendorValid: payload.vendor !== '',
        isModelValid: payload.model !== '',
        isVendorProposedValid: payload.proposed_vendor !== '',
        isModelProposedValid: payload.proposed_model !== '',
        isCategoryValid: payload.category !== '',
        isSnValid: payload.sn !== '',
        isPriceValid: priceReg.test(payload.price.value),
        isYearValid: payload.year !== '',
        isHoursValid: payload.hours !== '',
        isFullValid: payload.full !== ''
      });
      window.scrollTo(0, 0);
    } else {
      const validatedPayload = {
        ...payload,
        price: {
          ...payload.price,
          byn: setCalculatedPrice(payload.price.currency, payload.price.value, exchangeRates)['byn']
        },
        title: payload.vendor + ' ' + payload.model,
        short: payload.price.exchange && payload.short !== '' ? payload.short : 'Ожидаю ваших предложений',
      };
      sendAdvert(validatedPayload);
    }
  }

  if (advertCategories.category[0].name === '')
    return (<Loading isActive={advertCategories.category[0].name === ''}/>)

  const categories = advertCategories.category.map((item: any) => (
    <option value={item.name} key={item.name}>{item.name}</option>
  ));

  const vendors = advertCategories.vendor.map((item: any) => (
    <option value={item.name} key={item.name}>{item.name}</option>
  ));

  const choosenVendorIndex = advertCategories.vendor.findIndex((item: any) => item.name === payload.vendor);

  const models = choosenVendorIndex !== -1 ? advertCategories.vendor[choosenVendorIndex].models.map((item: any) => (
    <option value={item.name} key={item.name}>{item.name}</option>
  )) : undefined;

  const changeVendorChecked = (e: ChangeEvent<HTMLInputElement>) => {
    payload.proposed_vendor = '';
    payload.proposed_model = '';
    setVendorCheckbox(e.currentTarget.checked)
    if (vendorCheckbox === false) {
      validity.isVendorValid = true;
      validity.isModelValid = true;
      payload.vendor = 'Другой производитель';
      payload.model = 'Другая модель'
    }
  }

  const imageCallback = (item: IImageItem, index: number) => (
    <ImageItem
      key={item.id ? item.id : item.data.slice(-15 - index)}
      id={index}
      imageItem={{src: item.url ? item.url : item.data, main: item.main}}
      setMainImage={setMainImage}
      deleteImage={deleteImage}
    />
  );
  const images = payload.images.length > 0
    ? payload.images.map(imageCallback)
    : null;

  const isImagesOverflow = payload.images.length > 14 ? true : false;
  const conditionObject = getConditionState(payload.condition);
  const conditionText = conditionObject === null ? '' : conditionObject.text;
  const isAdmin = props.user && props.user.roles && props.user.roles.includes("administrator");

  return (
    <React.Fragment>
      {(isAdmin && isEdit !== true) ? <div>
        <input onChange={event => setSnTemplate(event.target.value)} className="product-input"
               placeholder="Серийный номер" style={{marginRight: 10, width: "auto"}}/>
        <button className="action-button" type="button" style={{width: "auto", padding: "1vh 1vh"}}
                onClick={async () => {
                  handlers.handleImportFromMascus(snTemplate);
                }}>Найти на mascus
        </button>
      </div> : null}
      <form className="create-product-container">
        <h2 className="page-title">{location.title}</h2>
        <div className="payload-container">

          <div className="input-container">
            <label htmlFor="category">Тип техники*</label>
            <select
              name="category"
              className={validity.isCategoryValid ? "product-input" : "product-input invalid"}
              value={payload.category}
              onChange={handlers.handleCategoryChange}
              onBlur={() => setValidity({...validity, isCategoryValid: payload.category !== ''})}
            >
              <option value="">Выбрать</option>
              {categories}
            </select>
            {validity.isCategoryValid ? null :
              <p className="form-invalid-indicator">Необходимо указать тип техники!</p>}
          </div>

          <div className="input-container">
            <label htmlFor="vendor">Производитель*</label>
            {!vendorCheckbox
              ?
              <><select
                className={validity.isVendorValid ? "product-input" : "product-input invalid"}
                value={payload.vendor}
                name="vendor"
                onChange={handlers.handleChange}
                onBlur={() => setValidity({...validity, isVendorValid: payload.vendor !== ''})}
              >
                <option value="">Выбрать</option>
                {vendors}
              </select>
                {validity.isVendorValid ? null :
                  <p className="form-invalid-indicator">Необходимо указать производителя!</p>}
              </>
              : <>
                <input
                  className={validity.isVendorProposedValid ? "product-input" : "product-input invalid"}
                  type="text"
                  name="proposed_vendor"
                  value={payload.proposed_vendor}
                  onChange={handlers.handleChange}
                  onBlur={() => setValidity({...validity, isModelValid: payload.proposed_vendor !== ''})}
                />
              </>}
            <div className="vendorCheckbox">
              <label className="checkbox-container">
                <input
                  type="checkbox"
                  className="checkbox"
                  name='vendorCheckbox'
                  checked={vendorCheckbox}
                  onChange={changeVendorChecked}/>
                <p>Указать своё</p>
              </label>
            </div>
          </div>

          <div className="input-container">
            <label htmlFor="model">Модель*</label>
            {!vendorCheckbox
              ?
              <>
                <select
                  className={validity.isModelValid ? "product-input" : "product-input invalid"}
                  value={payload.model}
                  name="model"
                  onChange={handlers.handleChange}
                  onBlur={() => setValidity({...validity, isModelValid: payload.model !== ''})}
                >
                  <option value="">Выбрать</option>
                  {models}
                </select>
              </>
              : <>
                <input
                  className={validity.isModelProposedValid ? "product-input" : "product-input invalid"}
                  type="text"
                  name="proposed_model"
                  value={payload.proposed_model}
                  onChange={handlers.handleChange}
                  onBlur={() => setValidity({...validity, isModelValid: payload.proposed_model !== ''})}
                />
              </>}
            {validity.isModelValid ? null : <p className="form-invalid-indicator">Необходимо указать модель!</p>}
          </div>

          <div className="input-container">
            <label htmlFor="sn">Серийный номер*</label>
            <input
              className={validity.isSnValid ? "product-input" : "product-input invalid"}
              type="text"
              value={payload.sn}
              name="sn"
              onChange={handlers.handleChange}
              onBlur={() => setValidity({...validity, isSnValid: payload.sn !== ''})}
            />
            {validity.isSnValid ? null : <p className="form-invalid-indicator">Необходимо указать серийный номер!</p>}
          </div>

          <div className="input-container">
            <label htmlFor="price">Цена*</label>
            <div className="price-container">
              <div className="price-form">
                <input
                  type="text"
                  className={validity.isPriceValid ? "product-input left" : "product-input invalid left"}
                  value={payload.price.value}
                  name="value"
                  onChange={handlers.handlePriceChange}
                  onBlur={() => setValidity({...validity, isPriceValid: priceReg.test(payload.price.value)})}
                />
                <select
                  className="product-input right"
                  name="currency"
                  value={payload.price.currency}
                  onChange={handlers.handlePriceChange}
                >
                  <option value="BYN">BYN</option>
                  <option value="RUB">RUB</option>
                  <option value="USD">USD</option>
                  <option value="EUR">EUR</option>
                </select>
              </div>
              <label className="checkbox-container" htmlFor='include_tax'>
                <input
                  name='include_tax'
                  type="checkbox"
                  value={`${payload.price.include_tax}`}
                  checked={payload.price.include_tax}
                  className="checkbox"
                  onChange={handlers.handlePriceChange}
                />
                <p>Цена с учетом НДС</p>
              </label>
              {!isAdmin || <label className="checkbox-container" htmlFor='optional_price'>
                <input
                  name='optional_price'
                  type="checkbox"
                  value={`${payload.price.optional_price || false}`}
                  checked={payload.price.optional_price || false}
                  className="checkbox"
                  onChange={handlers.handlePriceChange}
                />
                <p>Цена по запросу</p>
              </label>}

            </div>
            {validity.isPriceValid ? null :
              <p className="form-invalid-indicator">Необходимо указать корректную цену (прим.: 90000)!</p>}
          </div>

          <div className="input-container exchange">
            <label htmlFor="exchange">Обмен</label>
            <div className="exchange-container">
              <select
                name="exchange"
                value={`${payload.price.exchange}`}
                className="product-input"
                onChange={(event) => {
                  if (event.currentTarget.value === "false") handlers.clearShortItem(event);
                  else {
                    //@ts-ignore
                    handlers.handlePriceChange(event);
                  }
                }
                }
              >
                <option value="false">Без обмена</option>
                <option value="true">Возможен обмен</option>
              </select>
              {payload.price.exchange ?
                <input
                  type="text"
                  className="product-input bottom"
                  value={payload.short}
                  name="short"
                  placeholder="Варианты обмена"
                  onChange={handlers.handleChange}
                />
                : null}
            </div>
          </div>

        </div>
        <div className="payload-container">

          <div className="input-container">
            <label htmlFor="year">Год выпуска*</label>
            <input
              className={validity.isYearValid ? "product-input" : "product-input invalid"}
              type="text"
              value={payload.year}
              name="year"
              onChange={handlers.handleChange}
              onBlur={() => setValidity({...validity, isYearValid: payload.year !== ''})}
            />
            {validity.isYearValid ? null : <p className="form-invalid-indicator">Необходимо указать год выпуска!</p>}
          </div>

          <div className="input-container">
            <label htmlFor="hours">Моточасы, ч*</label>
            <input
              className={validity.isHoursValid ? "product-input" : "product-input invalid"}
              type="text"
              value={payload.hours}
              placeholder="Введите количество"
              onChange={handlers.handleChange}
              onBlur={() => setValidity({...validity, isHoursValid: payload.hours !== ''})}
              name="hours"
            />
            {validity.isHoursValid ? null :
              <p className="form-invalid-indicator">Необходимо указать количество моточасов!</p>}
          </div>

          <div className="input-container condition">
            <label htmlFor="condition">Состояние*</label>
            <input
              type="range"
              list="conditions"
              value={payload.condition}
              name="condition"
              onChange={handlers.handleChange}
              min={1}
              max={5}
            />
            <datalist id="conditions">
              <option value="1"/>
              <option value="2"/>
              <option value="3"/>
              <option value="4"/>
              <option value="5"/>
            </datalist>
            <p>{conditionText}</p>
          </div>

          <div className="input-container mobile-condition">
            <label htmlFor="condition">Состояние*</label>
            <select
              name="condition"
              value={`${payload.condition}`}
              className="product-input"
              onChange={handlers.handleChange}
            >
              <option value="1" label="Ужасное"/>
              <option value="2" label="Плохое"/>
              <option value="3" label="Среднее"/>
              <option value="4" label="Хорошее"/>
              <option value="5" label="Отличное"/>
            </select>
          </div>

          <div className="input-container textarea">
            <label htmlFor="full">Описание*</label>
            <textarea
              className={validity.isFullValid ? "product-input" : "product-input invalid"}
              name="full"
              value={payload.full}
              onChange={handlers.handleChange}
              onBlur={() => setValidity({...validity, isFullValid: payload.full !== ''})}
              rows={10}
              cols={45}
            ></textarea>
            {validity.isFullValid ? null : <p className="form-invalid-indicator">Необходимо описание!</p>}
          </div>
        </div>

        <div className="payload-container photos">
          <h2>Фотографии</h2>

          <div className="images-input-container">
            <div className="image-wrapper">
              <img src={photoPicture} alt=""/>
            </div>

            <div
              className="action-button"
              onClick={!isImagesOverflow ? handlers.handleImagesClick : () => (null)}
              onChange={handlers.handleImagesChange}
            >
              <p>{!isImagesOverflow ? 'Добавить фотографию' : 'Достигнут лимит фотографий'}</p>
              <input
                type="file"
                accept=".jpg, .jpeg, .png"
                style={{display: 'none'}}
                ref={inputImagesRef}
              />
            </div>

            <div className="images-container">
              {images}
            </div>
          </div>
        </div>

        <div className="payload-container">

          <h2>Дополнительная информация</h2>

          <div className="input-container">
            <label htmlFor="price">Местоположение</label>
            <div className="location-container">
              <input
                className="product-input location-item"
                type="text"
                value={payload.location.country}
                placeholder="Страна"
                name="country"
                onChange={handlers.handleLocationChange}
              />

              <input
                className="product-input location-item"
                type="text"
                value={payload.location.city}
                placeholder="Город"
                name="city"
                onChange={handlers.handleLocationChange}
              />
            </div>
          </div>
        </div>
        {props.user.company && !isEdit ?
          <div className="payload-container">
            <div className="input-container">
              <label htmlFor="fromCompany">Публикация</label>
              <div className="price-container">
                <label className="radio">
                  <input
                    type="radio"
                    name="fromCompany"
                    value='true'
                    onChange={handlers.handleRadioChange}
                  />
                  <span>От имени компании</span>
                </label>
                <label className="radio">
                  <input
                    type="radio"
                    name="fromCompany"
                    value='false'
                    defaultChecked
                    onChange={handlers.handleRadioChange}
                  />
                  <span>От своего лица</span>
                </label>
              </div>
            </div>
          </div>
          :
          null
        }

        <div className="submit-container">
          <button
            type="button"
            className="action-button"
            onClick={checkValidity}
          >
            {location.button}
          </button>
        </div>
      </form>
    </React.Fragment>
  );
}

export default AdvertForm;
