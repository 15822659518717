import React from 'react';
import noLogo from '../../../assets/img/nophoto-profile.png';
import { IVacancyItem } from '../../../common/types';

interface IComponentProps {
  company: IVacancyItem['author']['company'],
  user: IVacancyItem['author']['user']
}

const VacancyLeftColumn: React.FC<IComponentProps> = ({
  company, user
}) => {
  return (
    <div className="vacancy-left-column">
      <div className="logo-container">
        <img src={company.company_logo || noLogo} alt="logo"/>
      </div>
      <h3>{company.urName}</h3>
      <div className="contact-blocks">
        <div className="block">
          <h3>Контактное лицо</h3>
          <p>{user.user_details.first_name} {user.user_details.last_name}</p>
        </div>
        <div className="block">
          <h3>Телефон</h3>
          <a 
            href={`tel:${user.contacts ? user.contacts.phone : company.contacts.phone}`}
            className="value"
          >
            {user.contacts ? `+${user.contacts.phone}` : company.contacts.phone}
          </a>
        </div>
        {company.contacts.email ?
          <div className="block">
            <h3>Электронная почта</h3>
            <a 
              href={`mailto:${company.contacts.email}`}
              className="value mail-link"
            >
              {company.contacts.email}
            </a>
          </div>
        : null}
      </div>
      <div className="credentials">
        <p>{company.urName}</p>
        <p>УНП: {company.unn}</p>
        <p>{company.location.country}, {company.location.city}, {company.location.address}</p>
      </div>
    </div>
  )
}

export default VacancyLeftColumn;
