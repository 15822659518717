import React from 'react';
import { RouteComponentProps } from '@reach/router';

interface IUserInfo {
  userName: string,
  userSurname: string,
  userCity: string,
  userCountry: string
};

interface IComponentProps extends RouteComponentProps {
  userInfo: IUserInfo,
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  updatePersonalInformation: () => void
}

const UserPersonalInformation:React.FC<IComponentProps> = ({userInfo, handleChange, updatePersonalInformation}) => {
  return (
    <form className="person-data-container">
      <h3>Персональные данные</h3>
      <div className="payload-wrapper">

        <div className="payload-container">
          <label htmlFor="userName">Имя</label>
          <input
            className="person-input"
            type="text"
            value={userInfo.userName}
            placeholder="Имя"
            name="userName"
            onChange={handleChange}
          />
        </div>

        <div className="payload-container">
          <label htmlFor="userSurname">Фамилия</label>
          <input
            className="person-input"
            type="text"
            value={userInfo.userSurname}
            placeholder="Фамилия"
            name="userSurname"
            onChange={handleChange}
          />
        </div>

        <div className="payload-container">
          <label htmlFor="userCountry">Местоположение</label>
          <div className="payload-container">
            <input
              className="person-input"
              type="country"
              placeholder="Страна"
              value={userInfo.userCountry}
              name="userCountry"
              onChange={handleChange}
            />

            <input
              name="userCity"
              type="city"
              className="person-input"
              placeholder="Город"
              value={userInfo.userCity}
              onChange={handleChange}
              list="cities"
            />
            <datalist id="cities">
              <option value="Минск" />
              <option value="Витебск" />
              <option value="Гомель" />
              <option value="Могилёв" />
              <option value="Гродно" />
              <option value="Брест" />
            </datalist>
          </div>
        </div>

        <div className="button-container">
          <button 
            type="button"
            onClick={() => updatePersonalInformation()}
            className="action-button profile"
          >
            Сохранить изменения
          </button>
        </div>  
        
      </div>
    </form>
  );
}

export default UserPersonalInformation;
