import React, { Component } from 'react';
import { RouteComponentProps } from '@reach/router';
import VacancyForm from '../../components/vacancies/VacancyForm';
import Loading from '../../common/loading';
import Posted from '../../components/vacancies/utils/VacancyPostedMessage';
import getExchangeRates from '../../common/utilits/getExchangeRates';
import setRequestUrl from '../../common/utilits/setRequestUrl';
import setRequestParams from '../../common/utilits/setRequestParams';
import sendRequest from '../../common/utilits/sendRequest';
import { IExchangeRates } from '../../common/types';

interface IComponentProps extends RouteComponentProps {
  token: string
}

interface IComponentState {
  isPosted: boolean,
  isLoading: boolean,
  isError: boolean,
  errMsg: string,
  exchangeRates: IExchangeRates
}

class CreateVacancy extends Component<IComponentProps, IComponentState> {

  readonly state: IComponentState = {
    isPosted: false,
    isLoading: false,
    isError: false,
    errMsg: '',
    exchangeRates: {
      usd: 2,
      eur: 2.3,
      rub: 0.039
    },
  }

  async createVacancy(composedData:object) {
    const { token } = this.props;

    const requestURL = setRequestUrl('wp-json/api/v1/vacancy/', 'create');
    const requestParams = setRequestParams('POST', token, composedData);
    
    this.setState({ isLoading: true });
    
    const content = await sendRequest(requestURL, requestParams);
    if (content.errMsg) {
      this.setState({
        errMsg: content.errMsg,
        isError: true,
        isPosted: false,
        isLoading: false
      })
    } else {
      this.setState({
        isPosted: true,
        isLoading: false
      })
    }
  }

  componentDidMount() {
    getExchangeRates().then(exchangeRates => this.setState({ exchangeRates }));
  }

  render() {
    const { isLoading, isPosted, isError, errMsg } = this.state;

    if(isLoading)
      return (<Loading isActive={isLoading} />)

    if(isPosted)
      return (<Posted />)

    if(isError)
      return (<div>{errMsg}</div>)

    return (
      <VacancyForm
        createVacancy={this.createVacancy.bind(this)}
        exchangeRates={this.state.exchangeRates}
      />
    )
  }
}

export default CreateVacancy;
