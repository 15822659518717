import React from 'react';
import { Link } from '@reach/router';

const AdvUpdatedMessage: React.FC= () => (
  <div className="updated">
    <p>Изменения успешно опубликованы</p>
    <Link to="../../">Вернуться к списку объявлений</Link>
  </div>
);

export default AdvUpdatedMessage;
