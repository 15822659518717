import React, { Component } from 'react';
import { RouteComponentProps } from '@reach/router';
import Modal from 'react-modal';
import UserRegistrationInformation from '../../components/dashboard/profile/UserRegistrationInformation';
import ProfilePhotoComponent from '../../components/dashboard/profile/ProfilePhotoComponent';
import UserPersonalInformation from '../../components/dashboard/profile/UserPersonalInformation';
import ConfirmEmailBanner from '../../components/dashboard/profile/ConfirmEmailBanner';
import setRequestUrl from '../../common/utilits/setRequestUrl';
import setRequestParams from '../../common/utilits/setRequestParams';
import acceptImage from '../../assets/img/accept-round.png';
import { IStoreState, IUserDetails } from '../../common/types';
import ConfirmPhoneNumberBanner from '../../components/dashboard/profile/ConfirmPhoneNumberBanner';

interface IComponentProps extends RouteComponentProps {
  user: IStoreState['user'],
  updateUser: (userDetails: IUserDetails) => any
}

interface IComponentState {
  userName: string,
  userSurname: string,
  userCountry: string,
  userCity: string,
  userEmail: string,
  user_avatar: string | boolean,
  isModalOpen: boolean
};

class MyProfile extends Component<IComponentProps, IComponentState> {
  readonly state: IComponentState = {
    userName: this.props.user.user_details.first_name || '',
    userSurname: this.props.user.user_details.last_name || '',
    userEmail: this.props.user.email || '',
    userCity: this.props.user.user_details.city || '',
    userCountry: this.props.user.user_details.country || '',
    user_avatar: this.props.user.user_avatar || false,
    isModalOpen: false
  };

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value: string = event.currentTarget.value;
    const fieldName: string = event.currentTarget.name;
    event.currentTarget.style.borderColor = value.length > 0 ? 'green' : 'red';
    //@ts-ignore
    this.setState({
      [fieldName]: value,
    });
  };

  sendRequest(requestURL: string, requestParams: object, callback: (payload: IUserDetails | any) => any) {
    fetch(requestURL, requestParams)
      .then(response => response.json())
      .then(content => callback(content))
      .then(() => this.setState({ isModalOpen: true }));
  }

  updatePersonalInformation() {
    const payload = {
      user_details: {
        first_name: this.state.userName,
        last_name: this.state.userSurname,
        city: this.state.userCity,
        country: this.state.userCountry
      }
    };
    const { updateUser, user: { id, token } } = this.props;
    const requestURL = setRequestUrl('wp-json/api/v1/user/', 'update', `id=${id}`);
    const requestParams = setRequestParams('POST', token, payload);
    this.sendRequest(requestURL, requestParams, updateUser);
  }

  uploadPhoto(formData: FormData) {
    const { updateUser, user: { id, token } } = this.props;
    const requestURL = setRequestUrl('wp-json/api/v1/user/', 'update', `id=${id}`);
    const requestParams = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: formData
    };
    this.sendRequest(requestURL, requestParams, updateUser);
  }

  render() {
    const { user } = this.props;
    const profilePhotoComponentProps = {
      uploadPhoto: this.uploadPhoto.bind(this),
      avatar: this.state.user_avatar,
    }

    const personalInformationFormProps = {
      userInfo: {
        userName: this.state.userName,
        userSurname: this.state.userSurname,
        userCity: this.state.userCity,
        userCountry: this.state.userCountry
      },
      handleChange: this.handleChange.bind(this),
      updatePersonalInformation: this.updatePersonalInformation.bind(this)
    }

    const registrationInformationProps = {
      userEmail: this.state.userEmail,
      userPhoneNumber: user.phone,
      emailVerified: user.email_verified,
      phoneVerified: user.phone_verified,
      token: user.token
    }

    return (
      <div className="profile-container">
        { user.phone_verified ? null : <ConfirmPhoneNumberBanner />}
        { user.email_verified ? null : <ConfirmEmailBanner token={user.token} showBanner={true} emailVerified={user.email_verified} />}
        <ProfilePhotoComponent {...profilePhotoComponentProps} />
        <UserRegistrationInformation {...registrationInformationProps} />
        <UserPersonalInformation {...personalInformationFormProps} />
        <Modal
          onAfterOpen={() => setTimeout(() => this.setState({ isModalOpen: false }), 2000)}
          isOpen={this.state.isModalOpen}
          overlayClassName="ReactModal__Overlay--notification"
          className="ReactModal__Content--notification"
        >
          <div className="content">
            <p style={{ color: 'green', margin: '0.5rem 0' }}>Личные данные успешно обновлены!</p>
            <img src={acceptImage} alt="accepted" />
          </div>
        </Modal>
      </div>
    );
  }
}

export default MyProfile;
