import React from 'react';
import { Link } from '@reach/router';
import addressIcon from '../../assets/icons/address-icon.png';
import phoneIcon from '../../assets/icons/phone-icon.png';
import emailIcon from '../../assets/icons/email-icon.png';

import '../../assets/styles/footer.scss';

interface IFooterProps {
  logo: string,
}

const Footer: React.FC<IFooterProps> = ({ logo }) => {
  return (
    <footer className="footer" itemScope itemType="http://schema.org/Organization">
      <div className="copyright">
        <Link to="/posts/1" className="logo-container">
          <img src={logo} alt="logo" />
        </Link>
        <p>Copyright © Udarnik.by 2021</p>
      </div>

      <div className="sub-footer">
        <ul className="nav">
          <Link to="posts/1"> Техника </Link>
          <Link to="companies/1"> Компании </Link>
          <Link to="vacancies/1"> Вакансии </Link>
        </ul>
      </div>

      <div className="contacts">
        <span itemProp="name">ОДО “Ударник”</span>
        <div className="address">
          <div className="image-address">
            <img src={addressIcon} alt="adress icon" />
          </div>
          <p itemProp="address" itemScope itemType="http://schema.org/PostalAddress">Республика <span itemProp="addressCountry">Беларусь</span> <span itemProp="postalCode">210009</span>, <span itemProp="addressLocality">г. Витебск</span>, <span itemProp="streetAddress">пр-т.Фрунзе, 17а, 2</span></p>
        </div>
        <div className="phone-contacts">
          <div className="image-address">
            <img src={phoneIcon} alt="phone icon" />
          </div>
          <ul>
            <li>
              <a itemProp="telephone" href="tel:+3750212663583">+375 0212 67-32-33</a>
            </li>
            <li>
              <a itemProp="telephone" href="tel:+3750212663583">+375 0212 66-35-83</a>
            </li>
            <li>
              <a itemProp="telephone" href="tel:+3750212663583">+375 0212 66-93-18</a>
            </li>
          </ul>
        </div>
      </div>

      <div className="support">
        Служба поддержки
                    <div className="suppor-mail">
          <img src={emailIcon} alt="email icon" />
          <a itemProp="email" href="mailto:Support@vitudarnik.by">Support@vitudarnik.by</a>
        </div>
        <div className="suppor-phone">
          <img src={phoneIcon} alt="phone icon" />
          <a itemProp="telephone" href="tel:+375292321100">+375 29 232-11-00</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
