import React, { useState } from 'react';
import { Link } from '@reach/router';
import ModalConfirm from './products/utils/ModalConfirm';

export type TControlText = {
    deleteTxt : string,
    deleteBtn: string,
    draftTxt: string,
    draftBtn: string
}

interface IControlProps {
    id: number,
    post_status: string | undefined,
    text: TControlText,
    onDelete: (id: number) => void,
    onPublish: (id: number) => void,
    onDraft: (id: number) => void
  }
  
  const Control:React.FC<IControlProps> = (props) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const {post_status, id, onDelete, onPublish, onDraft, text} = props
    if (!post_status || post_status === "pending")
      return null;
  
    if (post_status === "draft")
      return(
        <div className="controls-wrap">
          <div className="controls">
          <Link className="blue" onClick={() => onPublish(id)} to="">Восстановить</Link><br/>
          <Link className="red" onClick={() => setIsOpen(true)} to="">Удалить</Link>
          <ModalConfirm
            isOpen={isOpen}
            close={() => setIsOpen(false)}
            onConfirm={() => {setIsOpen(false);onDelete(id)}}
            text={text.deleteTxt}
            btnText={text.deleteBtn}
          />
          </div>
        </div>
      );
    else return(
      <div className="controls-wrap">
        <div className="controls">
        <Link className="blue" to={`${id}/edit`}>Изменить</Link><br/>
        <Link className="red" onClick={() => setIsOpen(true)} to="">Скрыть</Link>
        <ModalConfirm
            isOpen={isOpen}
            close={() => setIsOpen(false)}
            onConfirm={() => {setIsOpen(false);onDraft(id)}}
            text={text.draftTxt}
            btnText={text.draftBtn}
          />
        </div>
      </div>
    );
  }

  export default Control;