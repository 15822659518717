import React from 'react';
import { ICompanyItem } from '../../../common/types';

interface IComponentProps {
  work_days: ICompanyItem['work_days']
}

const WorkDaysDisplayBlock: React.FC<IComponentProps> = ({
  work_days
}) => (
  <div className="work-days">
    {work_days.monday
      ? <p><span className="work-day-name">Понедлельник</span>{work_days.monday}</p>
      : <p><span className="work-day-name holiday">Понедлельник</span><span className="holiday">Выходной</span></p>
    }
    {work_days.tuesday
      ? <p><span className="work-day-name">Вторник</span>{work_days.tuesday}</p>
      : <p><span className="work-day-name holiday">Вторник</span><span className="holiday">Выходной</span></p>
    }
    {work_days.wednesday
      ? <p><span className="work-day-name">Среда</span>{work_days.wednesday}</p>
      : <p><span className="work-day-name holiday">Среда</span><span className="holiday">Выходной</span></p>
    }
    {work_days.thursday
      ? <p><span className="work-day-name">Четверг</span>{work_days.thursday}</p>
      : <p><span className="work-day-name holiday">Четверг</span><span className="holiday">Выходной</span></p>
    }
    {work_days.friday
      ? <p><span className="work-day-name">Пятница</span>{work_days.friday}</p>
      : <p><span className="work-day-name holiday">Пятница</span><span className="holiday">Выходной</span></p>
    }
    {work_days.saturday
      ? <p><span className="work-day-name">Суббота</span>{work_days.saturday}</p>
      : <p><span className="work-day-name holiday">Суббота</span><span className="holiday">Выходной</span></p>
    }
    {work_days.sunday
      ? <p><span className="work-day-name">Воскресенье</span>{work_days.sunday}</p>
      : <p><span className="work-day-name holiday">Воскресенье</span><span className="holiday">Выходной</span></p>
    }
  </div>
)

export default WorkDaysDisplayBlock;
