import React from 'react';
import { Link } from '@reach/router';
import noPhoto from '../../assets/img/nophoto-profile.png';
import { ICompanyItem } from '../../common/types';

interface IComponentProps {
  company: ICompanyItem
}

const VacancyItem: React.FC<IComponentProps> = ({ company: {
  id, title, contacts, location, urName, unn, company_logo
}}) => {
  return (
    <Link to={`./${id}/company-posts/1`} className="product">
      <div className="product-image">
        <img src={company_logo ? company_logo : noPhoto} alt="company logo"/>
      </div>
      <div className="product-text-content">
        <div className="product-header">
          <div className="labels">
            <h3> {title} </h3>
          </div>
        </div>
        <h4>{location.country},г. {location.city}, {location.address}</h4>
        <p className="company-contacts">{contacts.phone}</p>
        <p className="company-contacts">{contacts.email}</p>
        <p className="company-contacts">{contacts.site}</p>
        <div className="product-footer">
          <div className="state-container">
            <div className="ur-state">
              <p>{urName}</p>
            </div>
            <div className="ur-state">
              <p>УНП: {unn}</p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
} 

export default VacancyItem;
