import React, { Component, Fragment } from 'react';
import { RouteComponentProps, Router, Link } from '@reach/router';
import ProductsList from '../../containers/ProductsList';
import CompanyProductList from '../../components/company/company-detail/CompanyProductList';
import ProductDetail from '../products/ProductDetail';
import EditAdvert from './EditAdvert';
import setRequestUrl from '../../common/utilits/setRequestUrl';
import setRequestParams from '../../common/utilits/setRequestParams';
import sendRequest from '../../common/utilits/sendRequest';
import { IAdvertItem, IStoreState } from '../../common/types';
import { CreateOffer } from './CreateOffer';

interface IComponentState {
  isLoaded: boolean,
  advertList: Array<IAdvertItem>,
  sortedBy: 'date' | 'hours' | 'year' | 'price',
  order: 'DESC' | 'ASC',
  page: number,
  perPage: number,
  totalPages: number,
}

interface IComponentProps extends RouteComponentProps {
  user: IStoreState['user'],
}

class MyAds extends Component<IComponentProps, IComponentState> {

  readonly state: IComponentState = {
    isLoaded: false,
    advertList: [],
    sortedBy: 'date',
    order: 'DESC',
    page: 1,
    perPage: 10,
    totalPages: 1
  };

  async getPosts(pageId: number = 1) {
    const { user: { token, id } } = this.props;
    const { perPage, sortedBy, order } = this.state;

    const requestURL = setRequestUrl('wp-json/api/v1/post/', 'list', `size=${perPage}&page=${pageId}&userId=${id}&orderby=${sortedBy}&order=${order}`);
    const requestParams = setRequestParams('GET', token);

    this.setState({ isLoaded: false });

    const content = await sendRequest(requestURL, requestParams);
    this.setState({
      advertList: content.data,
      page: content.page,
      perPage: content.perPage,
      totalPages: content.totalPages,
      isLoaded: true
    })
  }

  getPage(pageNumber: number) {
    this.getPosts(pageNumber);
  }

  async expired() {
    await this.getPosts(this.state.page);
  }

  render() {
    const { user, location } = this.props;
    const pathname = location ? location.pathname : '';
    const isAuthorized = user ? true : false;
    const isSwitchVisible = pathname.split('/').length < 6;
    const hasCompany = !!user.company;
    if (!hasCompany)
      return (
        <Router className="products-wrapper">
          <ProductsList
            path='/:page'
            page=':page'
            user={user}
            setParentState={this.setState.bind(this)}
            getPosts={this.getPosts.bind(this)}
            advertList={this.state.advertList}
            totalPages={this.state.totalPages}
            getPage={this.getPage.bind(this)}
            isLoaded={this.state.isLoaded}
            expired={this.expired.bind(this)}
          />
          <ProductDetail
            path='/:page/:productId'
            productId=':productId'
            isAuthorized={isAuthorized}
            user={user}
          />
          <EditAdvert
            path='/:page/:productId/edit'
            productId=':productId'
            user={user}
          />
          <CreateOffer path='/:page/:productId/create-offer' productId=':productId' user={user} />
        </Router>
      )
    return (
      <Fragment>
        <ul className="company-dashboard-wrapper__nav ads" style={{ display: isSwitchVisible ? undefined : 'none' }}>
          <li className={pathname.includes('dashboard/ads/self') ? "active" : undefined}>
            <Link to="self/1">Личные объявления</Link>
          </li>
          <li className={pathname.includes('dashboard/ads/company-posts') ? "active" : undefined}>
            <Link to="company-posts/1">Объявления компании</Link>
          </li>
        </ul>
        <Router className="products-wrapper">
          <ProductsList
            path='self/:page'
            page=':page'
            user={user}
            setParentState={this.setState.bind(this)}
            getPosts={this.getPosts.bind(this)}
            advertList={this.state.advertList}
            totalPages={this.state.totalPages}
            getPage={this.getPage.bind(this)}
            isLoaded={this.state.isLoaded}
            expired={this.expired.bind(this)}
          />
          <CompanyProductList
            path="company-posts/:productId"
            productId=":productId"
            companyId={`${user.company}`}
          />
          <ProductDetail
            path=':type/:page/:productId'
            productId=':productId'
            isAuthorized={isAuthorized}
            user={user}
          />
          <EditAdvert
            path=':type/:page/:productId/edit'
            productId=':productId'
            user={user}
          />
          <CreateOffer path='/:page/:productId/create-offer' productId=':productId' user={user} />
        </Router>
      </Fragment>
    );
  }
}

export default MyAds;
