import React, { Component } from 'react';
import { RouteComponentProps, Router, Redirect, Location } from '@reach/router';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import Modal from 'react-modal';
import ResetPass from './pages/ResetPass';
import ConfirmEmail from './pages/ConfirmEmail';
import NotFound from './common/NotFound';
import ProductsPage from './pages/products/ProductsPage'
import Dashboard from './pages/dashboard/Dashboard';
import VacanciesPage from './pages/vacancies/VacanciesPage';
import CompaniesPage from './pages/company/CompaniesPage';
import Header from './containers/Header';
import logo from './assets/img/udarnic.png';
import { DisabledPhoneRoute, IStoreState, IUser } from './common/types';
import { getUser, alertPhoneVerified } from './redux/actions/actions';
import { ym } from './common/utilits/yandex-metrix';
import Footer from './components/footer/Footer';
import { AlertNewUSer } from 'components/alerts/NewUserAlert';
import { DisableByPhoneAlert } from 'components/alerts/DisableByPhoneAlert';
import store from 'redux/store';

interface IComponentProps extends RouteComponentProps {
  user: IStoreState['user'],
  getUser: (user: IUser) => any
  showNewUserAlert: IStoreState["alertNewUser"],
  alertPhoneRouteType?: DisabledPhoneRoute
}

Modal.setAppElement('#root');

class App extends Component<IComponentProps> {

  componentDidMount() {
    store.dispatch(alertPhoneVerified());
  }

  render() {
    const { user, showNewUserAlert, alertPhoneRouteType } = this.props;
    const isAuthorized = user ? true : false;
    return (
      <div className="app">
        <Helmet
          script={[{
            type: 'text/javascript',
            innerHTML: ym()
          }]} >
          <title>Ударник | Техника</title>
        </Helmet>
        <Location>
          {({ location }) => (
            <Header logo={logo} location={location} />
          )}
        </Location>
        <Router className="main" primary={false}>
          <ProductsPage path="/posts/*" />
          <VacanciesPage path="/vacancies/*" />
          <CompaniesPage path="/companies/*" />
          {isAuthorized ?
            <Dashboard path="/dashboard/*" />
            : null}
          {isAuthorized ?
            null
            : <ResetPass path="/resetpass/" />
          }
          <ConfirmEmail path="/confirmEmail/" user={user} getUser={this.props.getUser} />
          <Redirect from="/" to="/posts/1" noThrow />
          <NotFound default />
        </Router>
        <Footer logo={logo} />

        <AlertNewUSer showAlert={showNewUserAlert} phone={user ? user.phone : ""} />
        <DisableByPhoneAlert routeType={alertPhoneRouteType} isPhoneVerified={user ? user.phone_verified : false} phone={user ? user.phone : ""} />
      </div>
    );
  }
}

const mapStateToProps = (state: IStoreState) => ({
  user: state.user,
  showNewUserAlert: state.alertNewUser,
  alertPhoneRouteType: state.alertPhoneVerified
});

const mapDispatchToProps = {
  getUser
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
