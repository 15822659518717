import React from 'react';
import { Link } from '@reach/router';

const CancelSuccessfuly: React.FC = () => (
  <div className="deleted">
    <p>Ваша заявка в комнанию отменена!</p>
    <Link to="../">Вернуться в личный кабинет</Link>
  </div>
);

export default CancelSuccessfuly;
