import React from "react";
import { Link, navigate } from "@reach/router";
import unApproveIcon from "../../../assets/img/urgent-message.svg";
import approveIcon from "../../../assets/img/checkmark.svg";
import ReactTooltip from "react-tooltip";
import ConfirmEmailBanner from "./ConfirmEmailBanner";

interface IComponentProps {
  userEmail: string;
  userPhoneNumber: string;
  emailVerified: boolean;
  phoneVerified: boolean,
  token: string
}

const UserRegistrationInformation: React.FC<IComponentProps> = ({
  userEmail,
  userPhoneNumber,
  emailVerified,
  phoneVerified,
  token
}) => {
  const click = () => {
    if (!phoneVerified) {
      navigate("/dashboard/validate-phone-number")
    }
  };
  return (
    <div className="registration-data-container">
      <h3>Регистрационные данные</h3>

      <div className="data-block">

        <label htmlFor="phoneNumber">Телефон</label>
        <img
          src={phoneVerified ? approveIcon : unApproveIcon}
          className={phoneVerified ? "verified-status" : "invalid-verified-status"}
          data-tip=''
          data-for='sms-verified-status'
          alt="Статус телефона"
          onClick={click}>
        </img>
        <ReactTooltip id="sms-verified-status" type='dark' effect='solid'>{!phoneVerified ? "Отправить SMS с кодом подтверждения" : "Телефон подтверждён"}</ReactTooltip>

        <Link to="change-phone-number">+{userPhoneNumber}</Link>
      </div>

      <div className="data-block">
        <label htmlFor="email">E-mail</label>
        <ConfirmEmailBanner token={token} emailVerified={emailVerified} showBanner={false} />
        <Link to="change-email">{userEmail}</Link>
      </div>

      <div className="data-block">
        <label htmlFor="password">Пароль</label>
        <span className="verified-status" ></span>
        <Link to="change-pass">Изменить пароль</Link>
      </div>
    </div>
  );
};

export default UserRegistrationInformation;
