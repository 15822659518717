import React, { Component, createRef } from 'react';
import noPhoto from '../../../assets/img/nophoto-profile.png';

interface IComponentState {
  image: any
}

interface IComponentProps {
  uploadPhoto: (image: any) => void, 
  avatar: string | boolean
}

class ProfilePhotoComponent extends Component<IComponentProps, IComponentState> {
  private inputImageRef = createRef<HTMLInputElement>();

  readonly state:IComponentState = {
    image: this.props.avatar
  }

  handleImagesClick() {
    if (this.inputImageRef && this.inputImageRef.current) {
      this.inputImageRef.current.click();
    }
  }

  handleImagesChange() {
    if (this.inputImageRef.current && this.inputImageRef.current.files) {
      const file = this.inputImageRef.current.files[0];
      const formData = new FormData();
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64Encoded = reader.result;
        this.setState({ image: base64Encoded });
      }
      
      formData.append("userAvatar", file);
     
      if (file) {
        reader.readAsDataURL(file);
        this.props.uploadPhoto(formData);
      }
    }
  }
  
  render() {
    const { image } = this.state;
    return (
      <div className="user-photo-section">
        <div className="photo-section__header">
          <h3>Фотография профиля</h3>
          <p>Фото повышает доверие к вам</p>
        </div>
        <div className="photo-section__main">
          <div className="avatar-container">
            <img src={image && image !== '' ? image : noPhoto} alt="avatar"/>
          </div>
          <div className="action-container">
            <p>Фото должно быть в формате JPG или PNG и занимать не больше 10 МБ.</p>
            <div
              className="action-button profile light"
              onClick={this.handleImagesClick.bind(this)}
              onChange={this.handleImagesChange.bind(this)}
            >
              <p>Загрузить фотографию</p>
              <input
                type="file"
                accept=".jpg, .jpeg, .png"
                style={{ display: 'none' }}
                ref={this.inputImageRef}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ProfilePhotoComponent;
