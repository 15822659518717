import React, { Component } from 'react';
import { RouteComponentProps, Router, Redirect } from '@reach/router';
import CompanyHeader from '../../components/company/dashboard/CompanyHeader';
import CompanyNavDashboard from '../../components/company/dashboard/CompanyNav';
import CompanyInformation from '../../components/company/dashboard/CompanyInformation';
import CompanyEmployees from '../../components/company/dashboard/CompanyEmployees';
import { ICompanyItem } from '../../common/types';

interface IComponentProps extends RouteComponentProps {
  isUserAdmin: boolean,
  companyData?: ICompanyItem,
  acceptPendingEmployee: (pendingEmployeeId: number) => void,
  removeEmployee: (employeeId: number) => void,
  userExit: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

class CompanyDashboardPage extends Component<IComponentProps> {
  render() {
    const { companyData, acceptPendingEmployee, removeEmployee, isUserAdmin, userExit } = this.props;

    if (!companyData) return null;

    return (
      <div className="company-dashboard-wrapper">
        <CompanyHeader 
          company_logo={companyData.company_logo}
          title={companyData.title}
          desctiption={companyData.description}
        />
        <CompanyNavDashboard pathname={this.props.location ? this.props.location.pathname : ''} />
        <Router primary={false}>
          <CompanyInformation path="info" companyData={companyData} userExit={userExit} isUserAdmin={isUserAdmin} />
          <CompanyEmployees path="staff"
            adminId={companyData.admin}
            isUserAdmin={isUserAdmin}
            staff={companyData.users.users}
            pending={companyData.users.pending}
            acceptPendingEmployee={acceptPendingEmployee}
            removeEmployee={removeEmployee}
          />
          <Redirect from="/" to="/dashboard/company/info" noThrow/>
        </Router>
      </div>
    );
  }
}

export default CompanyDashboardPage;
