import React, { Component } from "react";
import { RouteComponentProps, Redirect } from "@reach/router";
import ActionItemNoCompany from "../../components/company/ActionItemNoCompany";
import employeeImage from "../../assets/img/employee.png";
import staffImage from "../../assets/img/employees.png";
import { DisabledPhoneRoute } from "common/types";

interface IActionItemProps {
  buttonText: string;
  descriptionText: string;
  linkTo: string;
  illustration: any;
  disableRouteType?: DisabledPhoneRoute;
}

interface IComponentProps extends RouteComponentProps {
  isUserJoinedCompany: boolean;
}

interface IComponentState {
  createCompanyPayload: IActionItemProps;
  joinCompanyPayload: IActionItemProps;
}

class NoCompanyPage extends Component<IComponentProps, IComponentState> {
  readonly state: IComponentState = {
    createCompanyPayload: {
      buttonText: "Зарегистрировать",
      descriptionText:
        "Если вашей компании нет в списке зарегистрированных, то вы можете зарегистрировать ее самостоятельно.",
      linkTo: "company-registration",
      illustration: staffImage,
      disableRouteType: DisabledPhoneRoute.CreateCompany
    },
    joinCompanyPayload: {
      buttonText: "Стать сотрудником",
      descriptionText:
        "Если вы являетесь сотрудником зарегистрированной компании, выберите свою из списка и публикуйте объявления от ее имени.",
      linkTo: "company-join",
      illustration: employeeImage,
    },
  };

  render() {
    const { isUserJoinedCompany } = this.props;
    const { joinCompanyPayload, createCompanyPayload } = this.state;

    return (
      <div className="company-wrapper">
        <ActionItemNoCompany {...joinCompanyPayload} />
        <ActionItemNoCompany {...createCompanyPayload} />
        {isUserJoinedCompany ? (
          <Redirect from="/" to="dashboard/company" noThrow />
        ) : null}
      </div>
    );
  }
}

export default NoCompanyPage;
