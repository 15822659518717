import React from 'react';
import { Link, RouteComponentProps } from '@reach/router';
import Breadcrumbs from './Breadcrumbs';
import arrowImage from '../../assets/icons/next-page-icon.png'

interface IComponentProps extends RouteComponentProps {
  pageId: number,
  totalPages: number,
}

const Pagination: React.FC<IComponentProps> = ({ pageId, totalPages }) => (
  <div className="pagination">
    <Link
      to={`../${pageId-1}`}
      style={{ visibility: pageId > 1 ? 'visible' : 'hidden'}}
      onClick={() => {window.scrollTo({ top: 0 })}}
    >
      <img src={arrowImage} alt="prev page" style={{ transform: 'scale(-1, 1)' }} />
      Назад
    </Link>
    <Breadcrumbs totalPages={totalPages} pageId={pageId} />
    <Link
      to={`../${pageId+1}`}
      style={{ visibility: pageId === totalPages ? 'hidden' : 'visible'}}
      onClick={() => {window.scrollTo({ top: 0 })}}
    >
      Вперед
      <img src={arrowImage} alt="next page"/>
    </Link>
  </div>
);

export default Pagination;
