import React from 'react';

interface IComponentProps {
    deleteRequestInCompany: () => void,
    cancelRequest: () => void,

}

const Canceling: React.FC<IComponentProps> = ({ cancelRequest, deleteRequestInCompany }) => (
    <div className="canceling">
        <p><b>Вы уверены, что хотите удалить свою заявку?</b> После удаления вы сможете выбрать другую компанию или зарегистрировать свою </p>
        <div className="button-container">
            <button
                type="button"
                className="action-button cancel"
                onClick={() => cancelRequest()}
            >
                Отмена
      </button>
            <button
                type="button"
                className="action-button secondary"
                onClick={deleteRequestInCompany}
            >
                Удалить заявку
      </button>
        </div>
    </div>
);

export default Canceling;
