import React, { useState } from 'react';
import { RouteComponentProps, WindowLocation, navigate } from '@reach/router';
import setRequestUrl from '../common/utilits/setRequestUrl';
import setRequestParams from '../common/utilits/setRequestParams';
import sendRequset from '../common/utilits//sendRequest';
import NotFound from '../common/NotFound';

interface ISearchParams {
  key: string | null,
  email: string | null
}

const getSearchParams = (location: WindowLocation | undefined): ISearchParams => {
  if (location) {
    const url = new URL(location.href);
    const searchParams = {
      key: url.searchParams.get('key'),
      email: url.searchParams.get('email')
    }
    return searchParams;
  }
  return { key: null, email: null };
}

const ResetedMessage: React.FC = () => (
  <div className="reset-pass-wrapper">
    <h3>Пароль успешно изменён.</h3>
    <button type="button" className="action-button" onClick={() => navigate('/posts/1')}>На главную</button>
  </div>
);
 
const ResetPass: React.FC<RouteComponentProps> = (props) => {
  const [userPassword, setUserPassword] = useState({ password: '', isValid: false });
  const [repeatedPassword, setRepeatedPassword] = useState({ password: '', isValid: false });
  const [errMsg, setErrMsg] = useState('');
  const [isReseted, setIsReseted] = useState(false);
  const { location } = props;
  const searchParams = getSearchParams(location);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const password: string = event.currentTarget.value;
    const fieldName: string = event.currentTarget.name;

    if (fieldName === 'userPassword') {
      const isValid = password.length > 4;
      event.currentTarget.style.borderColor = isValid ? 'green' : 'red';
      setUserPassword({ password, isValid });
    } else if (fieldName === 'repeatedPassword') {
      const isValid = password === userPassword.password;
      event.currentTarget.style.borderColor = isValid ? 'green' : 'red';
      setRepeatedPassword({ password, isValid });
    }

    setErrMsg('');
  };

  const changePassword = async () => {
    if (!userPassword.isValid) {
      setErrMsg('Пароль слишком короткий');
      return;
    }
    if (!repeatedPassword.isValid) {
      setErrMsg('Пароли не совпадают');
      return;
    }
    const payload = { ...searchParams, password: userPassword.password };
    const requestURL = setRequestUrl('wp-json/api/v1/user/', 'reset');
    const requestParams = setRequestParams('POST', undefined, payload);

    const content = await sendRequset(requestURL, requestParams);
    if (content === null) {
      setIsReseted(true);
      return;
    }
    if (content.errMsg) {
      setErrMsg(content.errMsg);
      return;
    }
  }

  if(searchParams.key === null || searchParams.email === null || searchParams === null)
    return (<NotFound />);

  if (isReseted)
    return (<ResetedMessage />);

  return (
    <div className="reset-pass-wrapper">
      <h2>Восстановление пароля</h2>
      <form>

        <input
          className="auth-input"
          type="password"
          value={userPassword.password}
          placeholder="Новый пароль"
          name="userPassword"
          onChange={handlePasswordChange}
          required
        />

        <input
          className="auth-input"
          type="password"
          value={repeatedPassword.password}
          placeholder="Повторите пароль"
          name="repeatedPassword"
          onChange={handlePasswordChange}
          required
        />
        
        {errMsg !== '' ? <p style={{ color: 'red' }}>{errMsg}</p> : null}
        <button type="button" className="action-button" onClick={changePassword}>Изменить пароль</button>
      </form>
    </div>
  );
}

export default ResetPass;
