import React, { Component } from 'react';
import sortArrowDown from '../../assets/img/sort-arrow-down.png';

interface IComponentProps {
  sortedBy: 'date' | 'price',
  order: 'DESC' | 'ASC',
  setSortParams: (sortedBy: IComponentProps['sortedBy'], order: IComponentProps['order']) => void
}

class ProductsSort extends Component<IComponentProps, {}> {
  handleClick(event: React.MouseEvent<HTMLDivElement>, sortedBy: IComponentProps['sortedBy'] ) {
    const { setSortParams, order } = this.props;
    if (event.currentTarget.classList.value.includes('active')) { 
      if (order === 'DESC') setSortParams(sortedBy, 'ASC');
      else setSortParams(sortedBy, 'DESC');
    }
    else setSortParams(sortedBy, 'DESC');
  }

  handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const [
      sortedBy,
      order
    ]:any = event.currentTarget.value.split(' ');
    this.props.setSortParams(sortedBy, order);
  }

  render() {
    const { sortedBy, order } = this.props;
    return (
      <div className="sort-panel without-currency">
        <MobileSortPanel 
          value={`${sortedBy} ${order}`}
          handleChange={this.handleChange.bind(this)}
        />
        <div className="sort-collection">
          <p>Сортировать по:</p>
          <div 
            role="button"
            className={ 
              sortedBy !== 'date' ? "sort-button"
              : order === 'ASC' ? "sort-button active asc" : "sort-button active"
            } 
            onClick={(event) => this.handleClick.call(this, event, 'date')}
          >
            <p>дате добавления</p>
            <img src={sortArrowDown} alt="sort orientation"/>
          </div>
          <div 
            role="button"
            className={ 
              sortedBy !== 'price' ? "sort-button"
              : order === 'ASC' ? "sort-button active asc" : "sort-button active"
            } 
            onClick={(event) => this.handleClick.call(this, event, 'price')}
          >
            <p>зарплате</p>
            <img src={sortArrowDown} alt="sort orientation"/>
          </div>
        </div>
      </div>
    )
  }
}

interface IMobileSortPanel {
  value: string,
  handleChange: (event: React.ChangeEvent<HTMLSelectElement>) => void,
}

const MobileSortPanel: React.FC<IMobileSortPanel> = ({ handleChange, value }) => (
  <div className="mobile-sort-item">
    <select 
      name="sort"
      value={value}
      className="filter-item"
      onChange={handleChange}
    >
      <option value="date DESC">По дате: сначала новые</option>
      <option value="date ASC">По дате: сначала старые</option>
      <option value="price DESC">По зарплате: сначала больше</option>
      <option value="price ASC">По зарплате: сначала меньше</option>
    </select>
  </div>
);

export default ProductsSort;
