import { JWT_AUTH_INVALID_TOKEN } from '../consts';
import { navigate } from '@reach/router';
import { exitUser } from '../../redux/actions/actions';
import store from '../../redux/store';

/**
 * Send request.
 * @constructor
 * @param {string} requestURL - Request URL.
 * @param {object} requestParams - Request Params.
 */
export default async function sendRequest(requestURL:string, requestParams:object) {
  const response = await fetch(requestURL, requestParams);
  const data = await response.json();
  if (data && data.code === JWT_AUTH_INVALID_TOKEN) {
    handleInvalidToken();
    return { errMsg: data.code };
  }
  if (response.ok) return data;
  else if (data) return { errMsg: data.message };
}

const handleInvalidToken = () => {
  navigate('/posts/1');
  store.dispatch(exitUser());
};
