import React from 'react';
import { IWorkDays, IWorkTime } from '../../../common/types';

interface IComponentProps {
  workDays: IWorkDays,
  workTime: IWorkTime,
  setWorkTime: any
}

const WorkTimeCollection: React.FC<IComponentProps> = ({ workDays, workTime, setWorkTime }) => {
  const timeOptions = [
    <option key="6:00" value="6:00">6:00</option>,
    <option key="6:30" value="6:30">6:30</option>,
    <option key="7:00" value="7:00">7:00</option>,
    <option key="7:30" value="7:30">7:30</option>,
    <option key="8:00" value="8:00">8:00</option>,
    <option key="8:30" value="8:30">8:30</option>,
    <option key="9:00" value="9:00">9:00</option>,
    <option key="9:30" value="9:30">9:30</option>,
    <option key="10:00" value="10:00">10:00</option>,
    <option key="10:30" value="10:30">10:30</option>,
    <option key="11:00" value="11:00">11:00</option>,
    <option key="11:30" value="11:30">11:30</option>,
    <option key="12:00" value="12:00">12:00</option>,
    <option key="12:30" value="12:30">12:30</option>,
    <option key="13:00" value="13:00">13:00</option>,
    <option key="13:30" value="13:30">13:30</option>,
    <option key="14:00" value="14:00">14:00</option>,
    <option key="14:30" value="14:30">14:30</option>,
    <option key="15:00" value="15:00">15:00</option>,
    <option key="15:30" value="15:30">15:30</option>,
    <option key="16:00" value="16:00">16:00</option>,
    <option key="16:30" value="16:30">16:30</option>,
    <option key="17:00" value="17:00">17:00</option>,
    <option key="17:30" value="17:30">17:30</option>,
    <option key="18:00" value="18:00">18:00</option>,
    <option key="18:30" value="18:30">18:30</option>,
    <option key="19:00" value="19:00">19:00</option>,
    <option key="19:30" value="19:30">19:30</option>,
    <option key="20:00" value="20:00">20:00</option>,
    <option key="20:30" value="20:30">20:30</option>,
    <option key="21:00" value="21:00">21:00</option>,
    <option key="21:30" value="21:30">21:30</option>,
    <option key="22:00" value="22:00">22:00</option>,
    <option key="22:30" value="22:30">22:30</option>,
    <option key="23:00" value="23:00">23:00</option>,
  ];

  return (
    <div className="work-time-container column">
      { workDays.monday ? 
      <div className="input-container">
        <label htmlFor="monday">Понедельник</label>
        <div className="work-time-container">
          <select
            className="product-input"
            value={workTime.monday.from}
            onChange={(event) => setWorkTime({ ...workTime, monday: { ...workTime.monday, from: event.currentTarget.value} })}
          >
            {timeOptions}
          </select>
          <p>до</p>
          <select
            className="product-input"
            value={workTime.monday.to}
            onChange={(event) => setWorkTime({ ...workTime, monday: { ...workTime.monday, to: event.currentTarget.value} })}
          >
            {timeOptions}
          </select>
        </div>
      </div>
      : null }
      { workDays.tuesday ? 
      <div className="input-container">
        <label htmlFor="tuesday">Вторник</label>
        <div className="work-time-container">
          <select
            className="product-input"
            value={workTime.tuesday.from}
            onChange={(event) => setWorkTime({ ...workTime, tuesday: { ...workTime.tuesday, from: event.currentTarget.value} })}
          >
            {timeOptions}
          </select>
          <p>до</p>
          <select
            className="product-input"
            value={workTime.tuesday.to}
            onChange={(event) => setWorkTime({ ...workTime, tuesday: { ...workTime.tuesday, to: event.currentTarget.value} })}
          >
            {timeOptions}
          </select>
        </div>
      </div>
      : null }
      { workDays.wednesday ? 
      <div className="input-container">
        <label htmlFor="wednesday">Среда</label>
        <div className="work-time-container">
          <select
            className="product-input"
            value={workTime.wednesday.from}
            onChange={(event) => setWorkTime({ ...workTime, wednesday: { ...workTime.wednesday, from: event.currentTarget.value} })}
          >
            {timeOptions}
          </select>
          <p>до</p>
          <select
            className="product-input"
            value={workTime.wednesday.to}
            onChange={(event) => setWorkTime({ ...workTime, wednesday: { ...workTime.wednesday, to: event.currentTarget.value} })}
          >
            {timeOptions}
          </select>
        </div>
      </div>
      : null }
      { workDays.thursday ? 
      <div className="input-container">
        <label htmlFor="thursday">Четверг</label>
        <div className="work-time-container">
          <select
            className="product-input"
            value={workTime.thursday.from}
            onChange={(event) => setWorkTime({ ...workTime, thursday: { ...workTime.thursday, from: event.currentTarget.value} })}
          >
            {timeOptions}
          </select>
          <p>до</p>
          <select
            className="product-input"
            value={workTime.thursday.to}
            onChange={(event) => setWorkTime({ ...workTime, thursday: { ...workTime.thursday, to: event.currentTarget.value} })}
          >
            {timeOptions}
          </select>
        </div>
      </div>
      : null }
      { workDays.friday ? 
      <div className="input-container">
        <label htmlFor="friday">Пятница</label>
        <div className="work-time-container">
          <select
            className="product-input"
            value={workTime.friday.from}
            onChange={(event) => setWorkTime({ ...workTime, friday: { ...workTime.friday, from: event.currentTarget.value} })}
          >
            {timeOptions}
          </select>
          <p>до</p>
          <select
            className="product-input"
            value={workTime.friday.to}
            onChange={(event) => setWorkTime({ ...workTime, friday: { ...workTime.friday, to: event.currentTarget.value} })}
          >
            {timeOptions}
          </select>
        </div>
      </div>
      : null }
      { workDays.saturday ? 
      <div className="input-container">
        <label htmlFor="saturday">Суббота</label>
        <div className="work-time-container">
          <select
            className="product-input"
            value={workTime.saturday.from}
            onChange={(event) => setWorkTime({ ...workTime, saturday: { ...workTime.saturday, from: event.currentTarget.value} })}
          >
            {timeOptions}
          </select>
          <p>до</p>
          <select
            className="product-input"
            value={workTime.saturday.to}
            onChange={(event) => setWorkTime({ ...workTime, saturday: { ...workTime.saturday, to: event.currentTarget.value} })}
          >
            {timeOptions}
          </select>
        </div>
      </div>
      : null }
      { workDays.sunday ? 
      <div className="input-container">
        <label htmlFor="sunday">Воскресенье</label>
        <div className="work-time-container">
          <select
            className="product-input"
            value={workTime.sunday.from}
            onChange={(event) => setWorkTime({ ...workTime, sunday: { ...workTime.sunday, from: event.currentTarget.value} })}
          >
            {timeOptions}
          </select>
          <p>до</p>
          <select
            className="product-input"
            value={workTime.sunday.to}
            onChange={(event) => setWorkTime({ ...workTime, sunday: { ...workTime.sunday, to: event.currentTarget.value} })}
          >
            {timeOptions}
          </select>
        </div>
      </div>
      : null }
    </div>
  )
};

export default WorkTimeCollection;
