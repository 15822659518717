import { RouteComponentProps } from "@reach/router";
import { IStoreState } from "common/types";
import React from "react";
import { PDFViewer, Font } from '@react-pdf/renderer';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import Modal from 'react-modal';
import setRequestUrl from "common/utilits/setRequestUrl";
import setRequestParams from "common/utilits/setRequestParams";
import sendRequest from "common/utilits/sendRequest";
import formatDate from "common/utilits/formatDate";
import { ISingleAdvert } from "pages/products/ProductDetail";

import udarnicIcon from "assets/img/udarnic.png";
import Loading from "common/loading";

interface IComponentProps extends RouteComponentProps {
  productId: string;
  user: IStoreState['user'],
}

interface IComponentState {
  isOpenPreview: boolean;
  advert?: ISingleAdvert;
  isLoaded: boolean;
  adminInfo: IAdminInfo;
}

interface IAdminInfo {
  lastName: string;
  firstName: string;
  email: string;
  position: string;
  phone: string;
}

export class CreateOffer extends React.Component<IComponentProps, IComponentState>{
  constructor(props: IComponentProps) {
    super(props);
    this.state = {
      isOpenPreview: false,
      isLoaded: false,
      adminInfo: {
        lastName: this.props.user.user_details.last_name,
        firstName: this.props.user.user_details.first_name,
        email: this.props.user.email,
        position: "",
        phone: `+${this.props.user.phone}`
      }
    };


    Font.register({
      family: "Roboto",
      src:
        "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"
    });
  }

  async getAdvert() {
    const requestURL = setRequestUrl('wp-json/api/v1/post/', 'single', `id=${this.props.productId}`);
    const requestParams = setRequestParams('GET', this.props.user.token);
    const result = await sendRequest(requestURL, requestParams);

    this.setState({ advert: result, isLoaded: true });
  }

  componentDidMount() {
    this.getAdvert();
  }

  updateFullDescription(full: string) {
    if (this.state.advert) {
      this.setState({ advert: { ...this.state.advert, full } })
    }
  }

  updatePrice(value: string) {
    if (this.state.advert) {
      this.setState({ advert: { ...this.state.advert, price: { ...this.state.advert.price, value } } })
    }
  }

  updatePriceCurrency(currency: string) {
    if (this.state.advert) {
      this.setState({ advert: { ...this.state.advert, price: { ...this.state.advert.price, currency } } })
    }
  }

  updatePriceTax(include_tax: boolean) {
    console.log(include_tax);
    if (this.state.advert) {
      this.setState({ advert: { ...this.state.advert, price: { ...this.state.advert.price, include_tax } } })
    }
  }

  updateUserInfo(value: string, tag: keyof IAdminInfo) {
    if (this.state.advert) {
      this.setState({ adminInfo: { ...this.state.adminInfo, [tag]: value } })
    }
  }

  render() {
    if (!this.state.isLoaded)
      return (<Loading isActive={!this.state.isLoaded} />)
    return (
      <React.Fragment>
        <div className="create-product-container">
          <button className="action-button link preview-btn preview-btn-mr " onClick={() => this.setState({ isOpenPreview: true })}>Предпросмотр</button>
          <form className="create-product-container">
            <div className="payload-container" style={{ border: "none" }}>

              <div className="input-container">
                <label htmlFor="price">Цена</label>
                <div className="price-container">
                  <div className="price-form">
                    <input
                      type="text"
                      className="product-input left"
                      value={this.state.advert ? this.state.advert.price.value : 0}
                      name="value"
                      onChange={(event) => { this.updatePrice(event.currentTarget.value) }}
                    />
                    <select
                      className="product-input right"
                      name="currency"
                      value={this.state.advert ? this.state.advert.price.currency : "BYN"}
                      onChange={(event) => { this.updatePriceCurrency(event.currentTarget.value) }}
                    >
                      <option value="BYN">BYN</option>
                      <option value="RUB">RUB</option>
                      <option value="USD">USD</option>
                      <option value="EUR">EUR</option>
                    </select>
                  </div>
                </div>
              </div>

              <label className="checkbox-container" htmlFor='include_tax'>
                <input
                  name='include_tax'
                  type="checkbox"
                  value={`${this.state.advert ? this.state.advert.price.include_tax : false}`}
                  checked={this.state.advert ? this.state.advert.price.include_tax : false}
                  className="checkbox"
                  onChange={(event) => { this.updatePriceTax(event.currentTarget.checked) }}
                />
                <p>Цена с учетом НДС</p>
              </label>

              <div className="input-container">
                <label htmlFor="last-name">Фамилия</label>
                <input
                  className={"product-input"}
                  type="text"
                  value={this.state.adminInfo.lastName}
                  name="last-name"
                  onChange={(event) => { this.updateUserInfo(event.currentTarget.value, "lastName") }}
                />
              </div>

              <div className="input-container">
                <label htmlFor="first-name">Имя</label>
                <input
                  className={"product-input"}
                  type="text"
                  value={this.state.adminInfo.firstName}
                  name="first-name"
                  onChange={(event) => { this.updateUserInfo(event.currentTarget.value, "firstName") }}

                />
              </div>

              <div className="input-container">
                <label htmlFor="position">Должность</label>
                <input
                  className={"product-input"}
                  type="text"
                  value={this.state.adminInfo.position}
                  name="position"
                  onChange={(event) => { this.updateUserInfo(event.currentTarget.value, "position") }}

                />
              </div>

              <div className="input-container">
                <label htmlFor="phone">Номер телефона</label>
                <input
                  className={"product-input"}
                  type="text"
                  value={this.state.adminInfo.phone}
                  name="phone"
                  onChange={(event) => { this.updateUserInfo(event.currentTarget.value, "phone") }}
                />
              </div>

              <div className="input-container">
                <label htmlFor="email">E-mail</label>
                <input
                  className={"product-input"}
                  type="text"
                  value={this.state.adminInfo.email}
                  name="email"
                  onChange={(event) => { this.updateUserInfo(event.currentTarget.value, "email") }}
                />
              </div>

              <div className="input-container textarea">
                <label htmlFor="full">Описание</label>
                <div className="price-container">
                  <textarea
                    className="product-input"
                    name="full"
                    value={this.state.advert ? this.state.advert.full : ""}
                    onChange={(event) => { this.updateFullDescription(event.currentTarget.value) }}
                    rows={10}
                    style={{ maxWidth: "100%" }}

                  ></textarea>
                </div>

              </div>
            </div>
          </form>
        </div>


        <Modal
          isOpen={this.state.isOpenPreview}
          className="preview-modal"
          overlayClassName="modal-confirm-overlay"
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          onRequestClose={() => { this.setState({ isOpenPreview: false }) }}
        >
          <PdfPreview advert={this.state.advert} adminInfo={this.state.adminInfo} />
        </Modal>
      </React.Fragment>);
  }
}

interface IPreviewProps {
  advert: ISingleAdvert | undefined;
  adminInfo: IAdminInfo;
}

const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    fontWeight: "normal",
    padding: 10,
    fontSize: 14
  },

  secondaryHeader: {
    fontSize: 11,
    color: "#737373",
    alignSelf: "flex-end"
  },

  titleStyles: {
    color: "#737373",
  },
  footer: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "gray"
  }
});

export const PdfPreview: React.FC<IPreviewProps> = ({ advert, adminInfo }) => {
  let [model, vendor] = ["", ""];

  if (advert && advert.model_vendor.length === 2) {
    model = advert.model_vendor[0];
    vendor = advert.model_vendor[1];
  }

  let parsedImages: { id: number, url: string }[][] = [];

  if (advert && advert.images.length > 0) {
    let tempArray: { id: number, url: string }[] = [];
    let tempIndex = 0;
    for (let index = 0; index < advert.images.length; index++) {
      if (tempIndex === 2) {
        parsedImages.push(tempArray);
        tempIndex = 0;
        tempArray = [];
      }
      tempArray.push(advert.images[index])
      tempIndex++;
    }

    if (tempArray.length > 0) {
      parsedImages.push(tempArray);
    }
  }

  return advert !== undefined ? (
    <PDFViewer>
      <Document>
        <Page size="A4" style={styles.page} >
          <View style={{ flexDirection: "row", display: "flex", borderBottom: 1, padding: 5 }}>
            <Text style={{ width: "70%", fontSize: 18 }}>{advert.category} {advert.title}</Text>
            <Text style={{ width: "20%", ...styles.secondaryHeader }}>{advert.published ? formatDate(advert.published) : ""}</Text>
            <Text style={{ width: "10%", ...styles.secondaryHeader }}>№{advert.id}</Text>
          </View>
          <View style={{ borderBottom: 1, padding: 5, flexDirection: "row", display: "flex" }}>
            <View style={{ flexDirection: "column", width: "25%" }}>
              <Text style={{ ...styles.titleStyles }}>Цена {advert.price.include_tax ? '(С НДС)' : "(Без НДС)"} </Text>
            </View>
            <View style={{ flexDirection: "column", width: "25%" }}>
              <Text style={{}}>{advert.price.value} {advert.price.currency} </Text>
              <Text>{advert.location.country || ""} , {advert.location.city || ""}</Text>
            </View>
            <View style={{ flexDirection: "row", alignSelf: "center", marginLeft: "auto" }} >
              <Image src={udarnicIcon} style={{ height: 50, width: 50, }}></Image>
              <Text style={{ paddingLeft: 20, alignContent: "center", marginBottom: "auto", marginTop: "auto" }}>{adminInfo.phone}</Text>
            </View>
          </View>

          <View style={{ borderBottom: 1, padding: 5, flexDirection: "row", display: "flex" }}>
            <View style={{ flexDirection: "column", width: "25%" }}>
              <Text style={{ ...styles.titleStyles }}>Контакты</Text>
            </View>

            <View style={{ flexDirection: "column", width: "75%" }}>
              <Text style={{}}>{`${adminInfo.lastName} ${adminInfo.firstName}, ${adminInfo.position ? `${adminInfo.position}, ` : ""}${adminInfo.email}`}</Text>
            </View>
          </View>

          <View style={{ borderBottom: 1, padding: 5, flexDirection: "row", display: "flex" }}>

            <View style={{ flexDirection: "column", width: "25%" }}>
              <Text style={{ ...styles.titleStyles }}>Тип</Text>
              <Text style={{ ...styles.titleStyles }}>Производитель</Text>
              <Text style={{ ...styles.titleStyles }}>Модель</Text>
            </View>
            <View style={{ flexDirection: "column", width: "25%" }}>
              <Text style={{}}>{advert.category || "-"}</Text>
              <Text style={{}}>{vendor || "-"}</Text>
              <Text style={{}}>{model || "-"}</Text>
            </View>
            <View style={{ flexDirection: "column", width: "25%" }}>
              <Text style={{ ...styles.titleStyles }}>Серийный номер</Text>
              <Text style={{ ...styles.titleStyles }}>Год выпуска</Text>
              <Text style={{ ...styles.titleStyles }}>Моточасы</Text>
              <Text style={{ ...styles.titleStyles }}>Общее состояние</Text>
            </View>
            <View style={{ flexDirection: "column", width: "25%" }}>
              <Text style={{}}>{advert.sn || "-"}</Text>
              <Text style={{}}>{advert.year || "-"}</Text>
              <Text style={{}}>{advert.hours || "-"}</Text>
              <Text style={{}}>{advert.condition || "-"}/5</Text>
            </View>
          </View>
          <View style={{ borderBottom: 1, padding: 5, }}>
            <Text style={{ paddingBottom: 10 }}>Описание</Text>
            <Text style={{ ...styles.titleStyles }}>{advert.full}</Text>
          </View>

          {
            parsedImages.map((data, index) => {
              const images = data.map((image, imageIndex) => { return <Image style={{ width: "50%", paddingLeft: imageIndex === 1 ? 5 : 0, paddingRight: imageIndex === 0 ? 5 : 0 }} key={image.id} src={image.url}></Image> });
              return (<View key={index} style={{ flexDirection: "row", display: "flex", paddingTop: 10 }} >{images}</View>)
            })
          }
          <Text style={{ ...styles.footer }} fixed>udarnik.by</Text>
        </Page>
      </Document>
    </PDFViewer >
  ) : null
}
