import React from 'react';
import { RouteComponentProps, Link } from '@reach/router';

const NotFound: React.FC<RouteComponentProps> = () => {
  return (
    <div className="not-found">
      <p className="text-404">404</p>
      <p>Ошибка, страница не найдена</p>
      <Link to="/" className="action-button text-404-link">Вернуться на главную</Link>
    </div>
  );
}

export default NotFound;
