
import React, { useState, useRef } from 'react';
//@ts-ignore
import PhoneInput from 'react-phone-number-input/max';
import metadata from '../../../../metadata.custom.json';
import closeButtonIcon from '../../../../assets/icons/close-icon.png';

interface IComponentProps {
  payload: {
    userName: string,
    userSurname: string,
    userEmail: string,
    userPassword: string,
    userPhoneNumber: string
  },
  validity: {
    isUserNameValid: boolean,
    isUserSurnameValid: boolean,
    isUserEmailValid: boolean,
    isUserPhoneNumberValid: boolean,
    isUserPasswordValid: boolean,
    isRepeatedPasswordValid: boolean
  },
  errMsg: string | undefined,
  handleNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  handleSurnameChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  handlePhoneNumberChange: (value: string) => void,
  handlePasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  handleRepeatedPasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  repeatedPassword: string,
  handleEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  toggle: (isActive: boolean) => void,
  switchActive: (isActive: boolean) => void,
  isActive: boolean,
  sendData: () => void
}

const RegistrationFormPopUp:React.FC<IComponentProps> = (props) => {
  const [validityError, setValdityError] = useState(false);
  const modalRef = useRef(null);
  const {
    payload: {
      userName,
      userSurname,
      userEmail,
      userPassword,
      userPhoneNumber
    },
    errMsg,
    handleNameChange,
    handleSurnameChange,
    handlePhoneNumberChange,
    handlePasswordChange,
    handleRepeatedPasswordChange,
    handleEmailChange,
    switchActive,
    toggle,
    repeatedPassword,
    isActive,
    sendData
  } = props;
  const changeHandler = (event:React.ChangeEvent<HTMLInputElement>, handler:(event: React.ChangeEvent<HTMLInputElement>) => void) => {
    setValdityError(false);
    handler(event);
  };
  const handleDisable = (event: React.MouseEvent<HTMLDivElement>) => {
    if (modalRef.current === event.target) toggle(!isActive);
  }
  const isUserPhoneNumberValid = !!userPhoneNumber;
  return (
    <div className="modal registration" onClick={handleDisable} ref={modalRef}>
      <form 
        className="pop-up"
      >
        <div className="switch-container">
          <button
            type="button"
            className="switch-button"
            onClick={() => switchActive(isActive)}
          >
            Вход
          </button>
          <button
            type="button"
            className="switch-button"
            style={{ color: isActive ? '#212529' : '', borderBottom: isActive ? '1px solid #212529' : '' }}
          >
            Регистрация
          </button>
          <div
            className="close-button"
            onClick={() => toggle(!isActive)}
          >
            <img src={closeButtonIcon} alt="close"/>
          </div>
        </div>
        <div className="content-wrapper">
          <div className="input-container">
            <label htmlFor="userName">Имя</label>
            <input
              className="auth-input"
              type="text"
              value={userName}
              name="userName"
              onChange={(event) => changeHandler(event, handleNameChange)}
            />
          </div>

          <div className="input-container">
            <label htmlFor="userSurname">Фамилия</label>
            <input
              className="auth-input"
              type="text"
              value={userSurname}
              name="userSurname"
              onChange={(event) => changeHandler(event, handleSurnameChange)}
            />
          </div>

          <div className="input-container">
            <label htmlFor="userEmail">E-mail</label>
            <input
              className="auth-input"
              type="email"
              value={userEmail}
              name="userEmail"
              onChange={(event) => changeHandler(event, handleEmailChange)}
            />
          </div>

          <div className="input-container">
            <label htmlFor="userPhoneNumber">Номер телефона</label>
            <PhoneInput 
              value={userPhoneNumber}
              style={{ border: isUserPhoneNumberValid ? '1px solid green' : undefined }}
              metadata={metadata}
              onChange={(value: any) => handlePhoneNumberChange(value)}
            />
          </div>

          <div className="input-container">
            <label htmlFor="userPassword">Пароль</label>
            <input
              className="auth-input"
              type="password"
              value={userPassword}
              name="userPassword"
              onChange={(event) => changeHandler(event, handlePasswordChange)}
            />
          </div>

          <div className="input-container">
            <label htmlFor="repeatedPassword">Повторите пароль</label>
            <input
              className="auth-input"
              type="password"
              value={repeatedPassword}
              name="repeatedPassword"
              onChange={(event) => changeHandler(event, handleRepeatedPasswordChange)}
            />
          </div>

          {validityError ? <p style={{color: 'red', margin: '0.3rem 0'}}>Поля заполнены с ошибками</p> : null}
          {errMsg ? <p style={{color: 'red'}}>{errMsg}</p> : null}
          <button type="button" onClick={() => sendData()} className="action-button">Зарегистрироваться</button>
        </div>
      </form>
    </div>
  )
};

export default RegistrationFormPopUp;
